import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SupidoRewardIco from "../assets/images/supido-reward.png";
import EyeIco from "../assets/images/eyeIcon.svg";
import HeartIco from "../assets/images/heartIcon.svg";
import ClipboardIco from "../assets/images/clipboardIcon.svg";
import ChatIco from "../assets/images/chatIcon.svg";
import UserIco from "../assets/images/userIcon.svg";
import DBNoWalletIco from "../assets/images/db-no-wallet.svg";
import UserIcoW from "../assets/images/userIconWhite.svg";
import HeartIcoW from "../assets/images/heartIconWhite.svg";
// import EyeIcoW from "../assets/images/eyeIconWhite.svg";
import ChatIcoW from "../assets/images/chatIconWhite.svg";
import RepeatIcoW from "../assets/images/repeatIconWhite.svg";
import ClipboardIcoW from "../assets/images/clipboardIconWhite.svg";

import {
  useCreateTask,
  useGetQuestByProject,
  useGetUserTasks,
} from "../utils/quest.api";
import FollowModal from "../components/modals/FollowModal";
import LikeModal from "../components/modals/LikeModal";
import TweetModal from "../components/modals/TweetModal";
import ReplyModal from "../components/modals/ReplyModal";
import RetweetModal from "../components/modals/RetweetModal";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import copyIcon from "../assets/images/copy-icon.svg";
import { useLocation } from "react-router-dom";
import QuoteModal from "../components/modals/QuoteModal";

const actionIcons: any = {
  Follow: UserIco,
  Tweet: ClipboardIco,
  Like: HeartIco,
  Reply: ChatIco,
  Retweet: EyeIco,
  Quote: ClipboardIco,

  // Retweet: RpeatIco,
};

// const actionText: any = {
//   Follow: "Follow Account",
//   Tweet: "Post Tweet",
//   Like: "Like a Post",
//   Retweet: "Retweet",
//   Reply: "Raiding Comments",
//   Quote: "Quote Tweet",
// };

function Quests() {
  const location = useLocation();
  const isCompletedTab = location.state;
  const projectId = process.env.REACT_APP_PROJECT_ID || "";
  const { data: token } = useQuery(["token"], () =>
    localStorage.getItem("token")
  );
  const { connected } = useWallet();

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalData, setModalData] = useState("");
  const [toShow, setToShow] = useState(20);

  const createTaskMutation = useCreateTask();

  const { data: userData } = useGetLoggedInUser();
  const { data, isLoading, refetch } = useGetQuestByProject({
    projectId: projectId,
    limit: toShow,
    offset: 0,
  });
  const { data: userTasks, isLoading: tasksLoading } = useGetUserTasks({
    limit: toShow,
    offset: 0,
  });
  const [activeTab, setActiveTab] = useState(
    isCompletedTab ? "Completed" : "Active"
  );

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  const getTaskIco = (action: string) => {
    switch (action) {
      case "Like":
        return HeartIcoW;
      case "Follow":
        return UserIcoW;
      case "Tweet":
        return ClipboardIcoW;
      case "Retweet":
        return RepeatIcoW;
      case "Reply":
        return ChatIcoW;
      case "Quote":
        return ClipboardIcoW;
      default:
        return "";
    }
  };

  const toggleModal = (modalName: any) => {
    setIsCreateOpen(!isCreateOpen);
    setModalName(modalName);
  };

  useEffect(() => {
    if (toShow !== 20) {
      refetch();
    }
  }, [toShow, refetch]);

  return (
    <div className="bg-[#070707] min-h-screen">
      {/* <!-- follow Us Area --> */}
      {modalName === "Follow" && (
        <FollowModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}
      {/* <!-- Tweet Area --> */}
      {modalName === "Tweet" && (
        <TweetModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}
      {modalName === "Quote" && (
        <QuoteModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}
      {/* <!-- Like Tweet Area --> */}
      {modalName === "Like" && (
        <LikeModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}
      {/* <!-- Reply Tweet Area --> */}
      {modalName === "Reply" && (
        <ReplyModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}
      {/* <!-- Retweet Tweet Area --> */}

      {modalName === "Retweet" && (
        <RetweetModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          createTask={createTaskMutation}
        />
      )}

      {/* <!-- Header Area --> */}
      <Header />
      {/* <!-- Quests Area --> */}
      <section className="dashboard-area py-[32px] font-[Barlow] font-[300] text-[14px] pt-[102px]">
        <div className="container">
          <div className="grid grid-cols-6 mb-8  sm:grid-cols-12 sm:gap-6">
            <div className="col-span-8 mb-3  md:col-span-7 lg:col-span-8 sm:mb-0">
              <div className="flex gap-[12px] md:gap-[16px] items-center">
                <img
                  className="object-cover w-10 h-10 sm:w-20 sm:h-20 rounded-xl"
                  src={SupidoRewardIco}
                  alt=""
                />
                <div>
                  <h4 className="text-[24px] text-[#F1F1F1] font-bold text-nowrap">
                    SUPIDO Rewards
                  </h4>
                  <p className=" text-[#D3D3D3] text-sm md:text-[16px] font-[300]">
                    Immerse in SUPIDO, complete quests like "Moonlight Maven"
                    and earn $SUPIDO rewards for engaging in timed-specific
                    tasks.
                  </p>

                  {/* <!-- <p className="text-base text-[#D3D3D3] font-[300] truncate w-64 lg:hidden">
               Immerse in SUPIDO, complete quests like "Moonlight Maven" and
               earn $SUPIDO rewards for engaging in time-specific tasks.
             </p> --> */}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center col-span-12 gap-4 mt-1 sm:flex-col sm:gap-2 sm:items-end sm:col-span-4 md:col-span-5 lg:col-span-4">
              {token && userData?.telegramId && connected && (
                <div
                  className="cursor-pointer popup-with-zoom-anim inline-flex items-center gap-2 border border-[#036e8f] bg-[#061a20] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] p-[10px] md:px-4  md:py-2 transition"
                  onClick={() => {
                    navigator?.clipboard
                      ?.writeText(
                        `${window?.location?.origin}?invitation=${
                          userData?.myReferralCode || ""
                        }`
                      )
                      .then(() => {
                        toast.success("Invitation link copied!");
                      });
                  }}
                >
                  <img src={copyIcon} alt="" />{" "}
                  <span className="hidden sm:block">Invite Friends</span>{" "}
                </div>
              )}
              <p className=" text-[#D3D3D3] text-xs md:text-[16px] font-[300]">
                Earn up to 10% of the credits your friends earn.
              </p>
            </div>
          </div>
          {data?.isBoosterActive && (
            <div className="flex justify-center my-12">
              <hr className=" border-[#212121] w-full" />
              <button className="mt-[-18px] absolute flex py-3 px-4 flex-col justify-center items-center gap-[10px] rounded-lg bg-[#F3AC14] text-[#FFF] text-[14px] leading-6 font-bold h-[34px]">
                Point Boosters Active
              </button>
            </div>
          )}
          {/* <> */}
          <div className="flex justify-center my-[40px]">
            <button
              className={`text-lg px-4 py-2 ${
                activeTab === "Active" ? "border-b-2 " : ""
              }`}
              onClick={() => handleTabClick("Active")}
            >
              Active
            </button>
            <button
              className={`text-lg px-4 py-2 ${
                activeTab === "Completed" ? "border-b-2 " : ""
              }`}
              onClick={() => handleTabClick("Completed")}
            >
              Completed
            </button>
          </div>

          {/* <hr className="mb-[40px] border-[#212121]" /> */}
          {/* </> */}

          {token && userData?.telegramId && connected ? (
            <>
              {activeTab === "Active" ? (
                <>
                  {isLoading ? (
                    <div className="flex justify-center mt-5">
                      <FadeLoader
                        color="#00C2FF"
                        loading={isLoading}
                        height={15}
                        width={5}
                      />
                    </div>
                  ) : data?.length === 0 ? (
                    <p className="text-base text-[#D3D3D3] text-sm md:text-[16px] font-[300] text-center">
                      There isn't any{" "}
                      <span className="text-[#00C2FF]">$SUPIDO</span> quests to
                      show.
                    </p>
                  ) : (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px]">
                        {data?.questDocumentsReturn
                          ?.filter((dat: any) => dat?.isCompleted === false)
                          ?.map((dat: any) => {
                            const ActionIcon = actionIcons[dat?.action];
                            // const ActionText = actionText[dat?.action];
                            return (
                              <div className=" py-[20px] px-[16px] border border-[#062639] bg-[#FFFFFF05] rounded-[12px]">
                                <div
                                // className="cursor-pointer"
                                >
                                  <div className="p-[12px] rounded-[8px] bg-[#00C2FF1A] w-fit">
                                    <img src={ActionIcon} width="24px" alt="" />
                                  </div>
                                  <h1 className="text-[#00C2FF] text-[24px] font-[500] mb-[32px] mt-[8px]">
                                    {dat?.title}
                                  </h1>
                                  {/* <h2 className="text-white font-[700] text-[20px] mb-[8px]">
                                    {dat?.title}
                                  </h2> */}
                                </div>
                                <div className="flex gap-[12px] mt-[32px] ">
                                  <div className="popup-with-zoom-anim popup-with-zoom-anim inline-flex items-center gap-2 border border-[#036e8f] bg-[#061a20] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition">
                                    x{dat?.reward} Credits
                                  </div>
                                  <div className="popup-with-zoom-anim popup-with-zoom-anim inline-flex items-center gap-2 border border-[#036e8f] bg-[#061a20] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition">
                                    {dat?.type}
                                  </div>
                                </div>
                                <button
                                  onClick={() => {
                                    toggleModal(dat?.action);
                                    setModalData(dat);
                                  }}
                                  className="w-full bg-[#00C2FF] mt-[24px] py-[14px] rounded-[42px] text-white font-[700]"
                                >
                                  Go
                                </button>
                              </div>
                            );
                          })}
                      </div>
                      {data?.length > 20 && (
                        <div
                          onClick={() => {
                            setToShow(toShow + 20);
                          }}
                          className="db-tag cursor-pointer mt-10 mx-auto text-[#ABBBC2] text-[10px] md:text-[14px] font-[400] w-[111px] h-[38px] flex justify-center items-center"
                        >
                          Load More
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className=" pb-[32px] pt-[32px] px-[16px]">
                    <h1 className="font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[34px] md:tracking-[0.6px]">
                      Tasks
                    </h1>
                    {tasksLoading ? (
                      <div className="flex justify-center mt-5">
                        <FadeLoader
                          color="#00C2FF"
                          loading={tasksLoading}
                          height={15}
                          width={5}
                        />
                      </div>
                    ) : userTasks?.length > 0 ? (
                      <div className="tasks-connected">
                        <div className="flex flex-col gap-[16px] md:gap-[32px] my-[16px] md:my-[32px] uppercase">
                          {userTasks?.map((task: any, idx: number) => (
                            <div
                              key={idx}
                              className="task-bg text-[10px] md:text-[20px] lg:text-[28px] flex w-full justify-between px-[12px] lg:px-[32px] py-[16px] md:py-[24px] gap-[20px]"
                            >
                              <div className="flex gap-[4px] md:gap-[12px] items-center">
                                <div className="bg-[#212121] p-[2px] md:p-[6px] rounded-[2px] md:rounded">
                                  <img
                                    className="min-w-[12px] w-[12px] md:w-[32px] md:min-w-[32px]"
                                    src={getTaskIco(task?.quest?.action)}
                                    alt=""
                                  />
                                </div>
                                <p className="font-[600] lg:font-[700]">
                                  {task?.quest?.title}
                                </p>
                              </div>
                              <div className="flex gap-[22px] lg:gap-[64px] items-center whitespace-nowrap">
                                <p>{task?.quest?.reward} POINTS</p>
                                <div
                                  className="cursor-pointer db-tag py-[6px] md:py-[13px] px-[10px] md:px-[32px] text-[#ABBBC2] text-[10px] md:text-[16px] md:tracking-[0.96px]"
                                  // onClick={() => navigate("/quests")}
                                >
                                  Completed
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* {userTasks?.length > 14 && (
                          <div
                            className="cursor-pointer db-tag mx-auto text-[#ABBBC2] text-[10px] md:text-[14px] font-[400] w-[111px] h-[38px] flex justify-center items-center"
                            // onClick={() => {
                            //   setTasksToShow(tasksToShow + 10);
                            // }}
                          >
                            Load More
                          </div>
                        )} */}
                      </div>
                    ) : (
                      <div className="flex flex-col items-center tasks-no-connect">
                        <img
                          className="w-[369px] max-w-[90%] my-[37px]"
                          src={DBNoWalletIco}
                          alt=""
                        />
                        <h1 className="text-[20px]">No Tasks Found</h1>

                        {connected ? (
                          <></>
                        ) : (
                          <p className="text-[#ABBBC2] font-[400] mt-[8px] text-center">
                            Connect your wallet account to view tasks to earn
                            points.
                          </p>
                        )}
                        {connected ? (
                          <></>
                        ) : (
                          <a
                            className="popup-with-zoom-anim"
                            href="#connect-wallet"
                          >
                            <button
                              onClick={() => setIsCreateOpen(true)}
                              className="connect-wallet-btn w-[170px] h-[46px] text-[#FFFFFFE5] font-[500] text-[16px] mt-[37px]"
                            >
                              Connect Wallet
                            </button>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <p className="text-base text-[#D3D3D3] text-sm md:text-[16px] font-[300] text-center">
              Please login to see{" "}
              <span className="text-[#00C2FF]">$SUPIDO</span> quests.
            </p>
          )}
        </div>
      </section>
    </div>
  );
}

export default Quests;
