import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useSignupTelegram } from "../utils/auth.api";
import { toast } from "react-toastify";

function TelegramCallback() {
  // get state and code from arguments
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const first_name = searchParams.get("first_name");
  const photo_url = searchParams.get("photo_url") || "";
  const username = searchParams.get("username") || "";

  const { mutateAsync: signupTelegram } = useSignupTelegram();

  useEffect(() => {
    if (id && first_name) {
      signupTelegram({ id, first_name, photo_url, username })
        .then((data) => {
          console.log(data);
          window.location.href = "/";
        })
        .catch((error) => {
          toast.warn(
            error?.message || "Something went wrong. Please try again!"
          );
          console.log(error);
        });
    } else {
      window.location.href = "/";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, first_name, signupTelegram]);

  return (
    <div className="bg-[#070707] min-h-screen">
      <p>Logged in</p>
    </div>
  );
}

export default TelegramCallback;
