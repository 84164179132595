import React from "react";

const ActiveBoosterCard = ({ type, qty }: any) => {
  return (
    <div
      className="flex  pt-[29px] pr-[5.33px] pb-[30px] pl-5 justify-start items-center rounded-xl border-l-4 border-gray-400  w-full"
      style={{
        background:
          type === "Gold"
            ? "rgba(254, 200, 58, 0.10)"
            : type === "Bronze"
            ? "rgba(255, 138, 59, 0.10)"
            : "rgba(204, 214, 221, 0.10)",
        borderColor:
          type === "Gold"
            ? "#FEC83A"
            : type === "Bronze"
            ? "#FF8A3B"
            : "#CCD6DD",
      }}
    >
      <div className="flex flex-col items-start gap-1 3xl:gap-3 w-full">
        <p
          className=" text-[40px] font-bold "
          style={{
            color:
              type === "Gold"
                ? "#FEC83A"
                : type === "Bronze"
                ? "#FF8A3B"
                : "#CCD6DD",
          }}
        >
          {qty}
        </p>
        <p className="text-[#FFF] text-[16px] font-light">{type} Boosters</p>
      </div>
    </div>
  );
};

export default ActiveBoosterCard;
