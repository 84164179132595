import Header from "../components/Header";
import TitleUnderline from "../assets/images/title-underline.svg";
import DoubleArrows from "../assets/images/double-arrows-right.svg";
import ArrowRight from "../assets/images/arrows-right.svg";
import UserIco from "../assets/images/userIcon.svg";
import ClipboardIco from "../assets/images/clipboardIcon.svg";
import EyeIco from "../assets/images/eyeIcon.svg";
import RepeatIco from "../assets/images/repeatIcon.svg";
import ChatIco from "../assets/images/chatIcon.svg";
import HeartIco from "../assets/images/heartIcon.svg";
import RocketIco from "../assets/images/rocketIcon.svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetLoggedInUser } from "../utils/auth.api";
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import PointsModal from "../components/modals/PointsModal";

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const myParam: any = searchParams.get("popup");
  const myParamRef: any = searchParams.get("invitation");
  const { data: userData } = useGetLoggedInUser();
  const [isCreateOpen] = useState(false);
  const navigate = useNavigate();
  const [isCreateOpenPoints, setIsCreateOpenPoints] = useState(false);

  const toggleModal = () => setIsCreateOpenPoints(!isCreateOpenPoints);
  const dataWallet = useWallet();
  // const { mutateAsync: verifySignature } = useVerifySignature();

  const { signMessage, connected, disconnect } = dataWallet;

  useEffect(() => {
    if (connected && isCreateOpen) {
      if (signMessage) {
        // const encodedMessage = new TextEncoder().encode(
        //   "Hello, World from Supido!"
        // );
        try {
          // signMessage(encodedMessage)
          //   .then((res: any) => {
          //     console.log(bs58.encode(res));
          //     verifySignature({
          //       signature: bs58.encode(res),
          //       address: publicKey?.toString() || "",
          //     })
          //       .then((res: any) => {
          //         console.log(res);
          //         toast.success("Signature verified!");
          //         setIsCreateOpen(false);
          //         // toast.success("SSSSSSSSSSSSSSSSSS");
          //         // setWalletConnected(true);
          //         // setIsCreateOpenPoints(false);
          //       })
          //       .catch((err: any) => {
          //         console.log(err);
          //         disconnect();
          //         toast.error(err?.message);
          //         setIsCreateOpen(false);
          //       });
          //   })
          //   .catch((err: any) => {
          //     console.log(err);
          //     disconnect();
          //     toast.error(err?.message);
          //   });
        } catch (err: any) {
          console.log(err);
          disconnect();
          toast.error(err?.message);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  console.log(userData?.id);
  useEffect(() => {
    if (userData?.id && !connected && userData?.telegramId) {
      setIsCreateOpenPoints(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (typeof myParam == "string" && myParam === "true") {
      setIsCreateOpenPoints(true);
      setSearchParams({
        popup: "false",
        invitation: myParamRef,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myParam]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("token")]);

  return (
    <div className="">
      <PointsModal
        isCreateOpenPoints={isCreateOpenPoints}
        toggleModal={toggleModal}
        myParam={myParam}
        setIsCreateOpenPoints={setIsCreateOpenPoints}
        dataWallet={dataWallet}
        isCreateO={isCreateOpen}
      />

      <div className="h-[100vh] bg-[#070707] 992:overflow-hidden  ">
        {/* <!-- Header Area --> */}
        <Header howItWorks={true} />

        {/* <!-- Hero Area --> */}
        <section className="hero-area bg-[#070707] py-[20px] transform origin-top pt-[120px]">
          <div className="container ">
            <h3 className="how-it-works-title">How Supīdo Works</h3>
            <div className="grid gap-6 md:grid-cols-3 mb-9 ">
              <div className="md:col-span-1 lg:col-span-1 how-it-works-card">
                <div className="how-work-box h-full flex flex-col hover:border-[#00C2FF] transition p-6 md:px-[20px] md:pb-[20px] md:pt-[40px]">
                  <p className="text-base text-[#00C2FF] work-card-number">
                    01
                  </p>
                  <div className="mb-10 work-top md:mb-4">
                    <div className="mb-2">
                      <h4 className="text-xl work-card-title">
                        One time tasks
                      </h4>
                      <img
                        src={TitleUnderline}
                        alt=""
                        width="189.89px"
                        className="title-underline"
                      />
                    </div>
                    <p className="text-base text-gray-400">
                      Tasks that are able to be completed by anyone. These tasks
                      can only be done once per account and usually gives higher
                      credits.
                    </p>
                  </div>
                  <div className="work-bottom">
                    <ul className="text-base text-gray-400 fa-ul">
                      <div className="mb-4">
                        <li className="inline mb-4 ">
                          <span className="inline-block align-middle fa-li">
                            <img src={UserIco} alt="user icon" />
                          </span>
                          <span className="ml-1 align-middle">
                            Following the official Supido
                            <span className="inline-block ml-1 mr-1 text-gray-0">
                              (
                              <span className="text-[#00C2FF] inline-block">
                                @supidocoin
                              </span>
                              )
                            </span>
                            twitter(X) account
                          </span>
                        </li>
                      </div>
                      <div className="mb-4">
                        <li className="inline mb-4 ">
                          <span className="inline-block align-middle fa-li">
                            <img src={ClipboardIco} alt="clipboard" />
                          </span>
                          <span className="inline-block ml-1 align-middle">
                            Post the initiation tweet
                          </span>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="md:col-span-1 lg:col-span-1 how-it-works-card">
                <div className="how-work-box h-full flex flex-col hover:border-[#00C2FF] transition p-6 md:px-[20px] md:pb-[3 px] md:pt-[40px]">
                  <p className="text-base work-card-number">02</p>
                  <div className="mb-10 work-top md:mb-4">
                    <div className="mb-2">
                      <h4 className="text-xl work-card-title">
                        Infinite tasks
                      </h4>
                      <img
                        src={TitleUnderline}
                        alt="underline"
                        width="159.92px"
                        className="title-underline"
                      />
                    </div>
                    <p className="text-base text-gray-400">
                      They are tasks that can be done whenever and however you
                      want, giving you the opportunity to earn your rewards.
                    </p>
                  </div>
                  <div className="work-bottom">
                    <ul className="text-base text-gray-400 fa-ul">
                      <div className="mb-4">
                        <li className="inline mb-4">
                          <span className="inline-block align-middle fa-li">
                            <img src={HeartIco} alt="heart" />
                          </span>
                          <span className="ml-1 align-middle">
                            Liking any post with
                            <span className="text-[#00C2FF] ml-1 inline-block">
                              $SUPIDO
                            </span>{" "}
                            in it.
                          </span>
                        </li>
                      </div>
                      <div className="mb-4">
                        <li className="inline mb-4 ">
                          <span className="inline-block align-middle fa-li">
                            <img src={ChatIco} alt="chat" />
                          </span>
                          <span className="ml-1 align-middle ">
                            Raiding comments of posts with
                            <span className="text-[#00C2FF] ml-1 inline-block">
                              $SUPIDO
                            </span>
                            .
                          </span>
                        </li>
                      </div>
                      <div className="mb-4">
                        <li className="inline mb-4 ">
                          <span className="inline-block align-middle fa-li">
                            <img src={RepeatIco} alt="repeat" />
                          </span>
                          <span className="ml-1 align-middle ">
                            Retweeting any posts with
                            <span className="text-[#00C2FF] ml-1 inline-block">
                              $SUPIDO
                            </span>{" "}
                            in it.
                          </span>
                        </li>
                      </div>
                      <div className="mb-4">
                        <li className="inline mb-4 ">
                          <span className="inline-block align-middle fa-li">
                            <img src={EyeIco} alt="eye" />
                          </span>
                          <span className="ml-1 align-middle">
                            Interacting with any post from the official
                            <span className="text-[#00C2FF] ml-1 inline-block">
                              SUPIDO
                            </span>{" "}
                            twitter account.
                          </span>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="md:col-span-1 lg:col-span-1 how-it-works-card">
                <div className="how-work-box h-full flex flex-col hover:border-[#00C2FF] transition p-6 md:px-[20px] md:pb-[20px] md:pt-[40px]">
                  <p className="text-base work-card-number">03</p>
                  <div className="mb-10 work-top md:mb-4">
                    <div className="mb-2">
                      <h4 className="work-card-title">Timed tasks</h4>
                      <img
                        src={TitleUnderline}
                        alt="underline"
                        width="150.94px"
                        className="title-underline"
                      />
                    </div>
                    <p className="text-gray-400">
                      Tasks are released periodically with specific time limit
                      to reward active members partaking in each task. Reap the
                      rewards of increased points for the provided timed quests
                      once posted on the dashboard.
                    </p>
                  </div>
                  <div className="flex-col h-full coming-soon">
                    <img
                      src={RocketIco}
                      width="80px"
                      className="block mx-auto mb-6"
                      alt="rocket"
                    />
                    <p className="text-center text-gray-0 coming-soon-text">
                      COMING SOON
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="easy-text">It’s as easy as 1,2,3</h2>
            {/* <!-- <div className="how-it-works-steps-wraper"> --> */}
            <div className="how-it-works-steps mt-[-8px]">
              <div className="step">
                <p className="text-[#00C2FF]">01</p>
                <p className="text-[#4D4D4D]">|</p>
                <p className="text-gray-0">Do Tasks</p>
              </div>
              <div className="flex justify-center step-divider">
                <img src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">02</p>
                <p className="text-[#4D4D4D]">|</p>
                <p className="text-gray-0">Earn Credits</p>
              </div>
              <div className="flex justify-center step-divider">
                <img src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">03</p>
                <p className="text-[#4D4D4D]">|</p>
                <p className="text-gray-0">Calculate Token Allocation</p>
              </div>
              <div className="flex justify-center step-divider">
                <img src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">04</p>
                <p className="text-[#4D4D4D]">|</p>
                <p className="text-gray-0">
                  Claim <span className="text-[#00C2FF]">$Supido</span>
                </p>
              </div>
            </div>
            <div className="how-it-works-steps-mobile">
              <div className="step">
                <p className="text-[#00C2FF] text-center">01</p>
                <p className="text-[#4D4D4D] text-xl">━</p>
                <p className="text-center text-gray-0">Do Tasks</p>
              </div>
              <div className="flex justify-center step-divider">
                <img width="29px" src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">02</p>
                <p className="text-[#4D4D4D] text-xl">━</p>
                <p className="text-gray-0">Earn Credits</p>
              </div>
              <div className="flex justify-center step-divider">
                <img width="29px" src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">03</p>
                <p className="text-[#4D4D4D] text-xl">━</p>
                <p className="text-gray-0">Calculate Token</p>
              </div>
              <div className="flex justify-center step-divider">
                <img width="29px" src={DoubleArrows} alt="double arrows" />
              </div>
              <div className="step">
                <p className="text-[#00C2FF]">04</p>
                <p className="text-[#4D4D4D] text-xl">━</p>
                <p className="text-gray-0">
                  Claim <span className="text-[#00C2FF]">$Supido</span>
                </p>
              </div>
            </div>
            {/* <!-- </div> --> */}

            <div
              className="text-center mt-[-8px] "
              onClick={() => {
                if (userData?.id && userData?.telegramId && connected) {
                  navigate("/dashboard");
                } else setIsCreateOpenPoints(true);
              }}
            >
              <div className=" cursor-pointer inline-flex items-center gap-2 bg-[#00C2FF] border border-[#00C2FF] rounded-full px-12  transition get-started-btn">
                Get Started
                <img src={ArrowRight} alt="arrow right" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
