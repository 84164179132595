import React, { Fragment, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Dialog, Transition } from "@headlessui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { SIGNING_SECRET, encrypt } from "../../utils/utils";
// import bs58 from "bs58";
import { toast } from "react-toastify";
import { useVerifySignature } from "../../utils/auth.api";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const WalletConnectModal = ({
  isCreateOpen,
  setIsCreateOpen,
  dataWallet,
  setWalletConnected,
}: any) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { connected, disconnect, publicKey } = useWallet();
  const { mutateAsync: verifySignature } = useVerifySignature();
  console.log(isCreateOpen);

  useEffect(() => {
    if (connected && isCreateOpen) {
      setIsCreateOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  useEffect(() => {
    if (connected && isCreateOpen) {
      const key = SIGNING_SECRET;
      const encryptedMessage = encrypt(publicKey?.toString() || "", key || "");
      console.log("Encrypted Message: ", encryptedMessage);
      verifySignature({
        signature: encryptedMessage,
        address: publicKey?.toString() || "",
      })
        .then((res: any) => {
          console.log(res);
          toast.success("Signature verified!");
          setIsCreateOpen(false);
          // toast.success("SSSSSSSSSSSSSSSSSS");
          // setWalletConnected(true);
          // setIsCreateOpenPoints(false);
        })
        .catch((err: any) => {
          console.log(err);
          disconnect();
          localStorage.clear();
          navigate("/");
          queryClient.removeQueries(["getLoggedInUser"]);
          toast.error(err?.message);
          setIsCreateOpen(false);
        });
      //     if (signMessage) {
      //       const encodedMessage = new TextEncoder().encode(
      //         "Hello, World from Supido!"
      //       );
      //       try {
      //         signMessage(encodedMessage)
      //           .then((res: any) => {
      //             console.log(bs58.encode(res));
      //             verifySignature({
      //               signature: bs58.encode(res),
      //               address: publicKey?.toString() || "",
      //             })
      //               .then((res: any) => {
      //                 console.log(res);
      //                 toast.success("Signature verified!");
      //                 setIsCreateOpen(false);
      //                 // toast.success("SSSSSSSSSSSSSSSSSS");
      //                 // setWalletConnected(true);
      //                 // setIsCreateOpenPoints(false);
      //               })
      //               .catch((err: any) => {
      //                 console.log(err);
      //                 disconnect();
      //                 toast.error(err?.message);
      //                 setIsCreateOpen(false);
      //               });
      //           })
      //           .catch((err: any) => {
      //             console.log(err);
      //             disconnect();
      //             toast.error(err?.message);
      //           });
      //       } catch (err: any) {
      //         console.log(err);
      //         disconnect();
      //         toast.error(err?.message);
      //       }
      //     }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <Transition.Root show={isCreateOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
        onClose={() => setIsCreateOpen(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 backdrop-blur-2xl" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative w-full max-w-md ">
            <button
              className="absolute top-0 right-0 focus:outline-none w-[35px] md:w-[44px] h-[44px]"
              onClick={() => setIsCreateOpen(false)}
            >
              <p className="text-[24px] text-white opacity-[0.65]">x</p>
            </button>
            <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
              <h1 className="text-xl text-[#F1F1F1] font-bold mb-6">
                Connect Wallet
              </h1>
              <p className="text-sm text-[#A6A6A6] mb-8">
                Connect your Wallet.
              </p>
              {/* <!-- Feild --> */}
              <div className="flex flex-col items-center gap-4 mb-2 sm:flex-row-reverse sm:justify-between">
                <WalletMultiButton
                  className="w-[100%] grow"
                  onClick={() => {
                    if (dataWallet?.connected === true) {
                      setWalletConnected(true);
                    }
                    // localStorage.setItem("walletConnected", "true");
                    // setIsCreateOpen(false);
                  }}
                />
                <button
                  onClick={() => setIsCreateOpen(false)}
                  className="popup-modal-dismiss inline-flex w-full sm:w-[50%] items-center justify-center gap-2 border border-[#4D4D4D] hover:border-[#00C2FF] rounded-full text-sm text-[#D3D3D3] hover:text-[#00C2FF] px-4 py-[0.8rem] transition"
                >
                  Cancel
                </button>
                {/* <WalletDisconnectButton /> */}
              </div>
              {/* <WalletDisconnectButton className="w-[100%] mt-2" /> */}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default WalletConnectModal;
