import React, { Fragment } from "react";

import TwitterIco from "../../assets/images/twitter.svg";

import { Dialog, Transition } from "@headlessui/react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const TweetModal = ({
  isCreateOpen,
  toggleModal,
  data,
  createTask,
  isPreview,
}: any) => {
  return (
    <Transition.Root show={isCreateOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center backdrop-filter backdrop-blur-[2px]"
        onClose={() => toggleModal("Tweet")}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 backdrop-blur-2xl" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative w-[96%] max-w-md ">
            <button
              className="absolute top-0 right-0 focus:outline-none w-[44px] h-[44px]"
              onClick={() => toggleModal("Tweet")}
            >
              <p className="text-[24px] text-white opacity-[0.65]">x</p>
            </button>
            <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
              <h1 className="text-xl text-[#F1F1F1] font-bold mb-6">Tweet</h1>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    await createTask.mutateAsync({
                      questId: data?.id,
                      linkToYourTweet: "",
                    });
                    toast.success("Task completed successfully!!!");

                    toggleModal("Tweet");
                  } catch (error: any) {
                    toast.error(error?.message);
                  }
                }}
              >
                {/* <!-- Upload --> */}
                {/* <div className="flex flex-wrap items-center justify-between gap-4 mb-6"> */}
                {/* <div className="flex items-center gap-6">
                    <div className="w-16 h-16 flex items-center justify-center rounded-xl bg-[#00C2FF] text-white text-3xl font-bold">
                      {data?.handle[0]?.toUpperCase()}
                    </div>
                    <div>
                      <h4 className="text-xl text-[#F1F1F1] font-bold mb-1">
                        {data?.handle}
                      </h4>
                      <p className="text-base text-gray-200">@{data?.handle}</p>
                    </div>
                  </div> */}
                {/* {following && <div
                    className="cursor-pointer inline-flex items-center gap-2 border border-[#F1F1F1] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition"
                    onClick={async () => {
                      try {
                        const res = await followUserMutation.mutateAsync(
                          data?.handle
                        );
                        setFollowing(res?.data?.following);
                        toast.success("Followed Successfully!");
                      } catch (error: any) {
                        toast.error(error?.message);
                      }
                    }}
                  >
                    <img src={FollowersIco} alt="" />
                    {following ? "Following" : ""}
                  </div>} */}
                {/* </div> */}
                {/* <!-- Feild --> */}
                {!isPreview && (
                  <>
                    <div
                      className="cursor-pointer flex items-center justify-center gap-2 text-base text-gray-200 border border-[#F1F1F1] hover:border-[#00C2FF] rounded-full py-2 px-4 transition"
                      onClick={() => {
                        // Construct the URL for Twitter's tweet composer
                        const baseUrl = "https://twitter.com/intent/tweet";
                        const words = data?.words || []; // Assuming data.words is an array of words
                        const defaultTweet = data?.defaultTweet || "";
                        const tweetTextArray = [...words, defaultTweet];
                        const tweetText = tweetTextArray
                          ?.map((word) => {
                            if (word.startsWith("@")) {
                              return encodeURIComponent(word);
                            } else if (word.startsWith("#")) {
                              return encodeURIComponent(word);
                            } else {
                              return encodeURIComponent(word);
                            }
                          })
                          ?.join(" ");

                        // Construct the tweet URL
                        const tweetUrl = `${baseUrl}?text=${tweetText}`;

                        // Open the tweet composer URL in a new tab
                        window.open(tweetUrl, "_blank");
                      }}
                    >
                      <img
                        className="svg-white-color"
                        src={TwitterIco}
                        alt=""
                      />{" "}
                      Tweet about this
                    </div>
                    <p className="text-base text-[#D3D3D3] my-4">
                      Be sure to include these words in your tweet.
                    </p>
                  </>
                )}

                <p className="text-base text-[#D3D3D3] my-4">
                  Words to Include
                </p>
                <ul className="flex flex-wrap items-center gap-4 mb-6">
                  {data?.words?.map((word: any, index: number) => {
                    return (
                      <li key={index}>
                        <div className="cursor-pointer inline-flex items-center gap-2 bg-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition">
                          {word}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {/* <h4 className="text-xl text-[#00C2FF] mb-4">
                  Link to your tweet
                </h4> */}

                {/* {data?.defaultTweet && (
                  <div className="mb-2">
                    <h4 className="text-lg text-[#F1F1F1] font-bold ">
                      Default Tweet
                    </h4>
                    <p className="text-base text-gray-200">
                      {data?.defaultTweet}
                    </p>
                  </div>
                )} */}
                {/* <!-- Feild --> */}
                <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                  How to complete this task:
                </h1>

                <div className="text-[#A6A6A6] text-[14px] mt-2 mb-6">
                  <p>
                    1. Click on the post icon in the tweet preview and write
                    text to post.
                  </p>
                  <p>2. Click on the button below to verify the task.</p>
                </div>
                {/* <!-- Feild --> */}
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    disabled={createTask?.isLoading}
                    onClick={() => toggleModal("Tweet")}
                    className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-[#4D4D4D] hover:border-[#00C2FF] rounded-full text-sm text-[#D3D3D3] hover:text-[#00C2FF] px-5 py-4 transition"
                  >
                    Cancel
                  </button>
                  {!isPreview && (
                    <button
                      disabled={createTask?.isLoading}
                      type="submit"
                      className="inline-flex w-full items-center justify-center gap-2 bg-[#00C2FF] hover:border-[#00C2FF] border-[#D3D3D3] rounded-full text-sm text-[#FFFFFF] px-5 py-4 transition"
                    >
                      {createTask?.isLoading ? (
                        <ClipLoader color="#FFFFFF" size={20} />
                      ) : (
                        "Verify Task"
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default TweetModal;
