import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import LoveIco from "../../assets/images/love.svg";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { useLikeTweet } from "../../utils/quest.api";

const LikeModal = ({
  isCreateOpen,
  toggleModal,
  data,
  createTask,
  isPreview,
}: any) => {
  const likeTweetMutation = useLikeTweet();

  const tweetId = ExtractTweetIdFromUrl(data?.postUrl);
  return (
    <>
      <Transition.Root show={isCreateOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 flex justify-center  items-center overflow-y-auto  pb-[5px]  backdrop-filter backdrop-blur-[2px]"
          onClose={() => {}}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative rounded-2xl  overflow-y-auto no-scrollbar w-[96%] max-w-md ">
              <button
                className="absolute top-0 right-0 focus:outline-none w-[44px] h-[44px]"
                onClick={() => toggleModal("Like")}
              >
                <p className="text-[24px] text-white opacity-[0.65]">x</p>
              </button>
              <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
                <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                  Like Tweet
                </h1>
                {!isPreview && (
                  <p className="text-sm text-[#D3D3D3] mb-6">
                    Engage with our tweet! Like for instant rewards and become a
                    part of the $SUPIDO community buzz.
                  </p>
                )}
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    try {
                      await createTask.mutateAsync({
                        questId: data?.id,
                        linkToYourTweet: "",
                      });
                      toggleModal("Like");
                      toast.success("Task completed successfully!!!");
                    } catch (error: any) {
                      toast.error(error?.message);
                    }
                    // toggleHowTo();
                  }}
                >
                  {data?.tweetId && !isPreview && (
                    <div className="w-[310px] mx-auto">
                      <TwitterTweetEmbed
                        tweetId={data?.tweetId}
                        placeholder={<ClipLoader size={40} color="#FFFFFF" />}
                        options={{ height: 400 }}
                      />
                      <p
                        className="mt-[12px] text-center text-[#00C2FF] cursor-pointer"
                        onClick={() => {
                          window.open(
                            `https://twitter.com/i/web/status/${data?.tweetId}`
                          );
                          // setIsHowToOpen(true);
                          // toggleModal("Like");
                          // window.open(
                          //   `https://twitter.com/i/web/status/${data?.tweetId}`
                          // );
                        }}
                      >
                        Open Tweet on X
                      </p>
                    </div>
                  )}
                  {data?.postUrl && isPreview && (
                    <div className="w-[310px] mx-auto">
                      <TwitterTweetEmbed
                        tweetId={tweetId}
                        placeholder={<ClipLoader size={40} color="#FFFFFF" />}
                        options={{ height: 400 }}
                      />
                    </div>
                  )}

                  {!isPreview && (
                    <div className="flex items-center justify-between gap-4 mb-6">
                      <p className="text-base text-[#D3D3D3]">
                        Like this tweet
                      </p>
                      <div
                        onClick={async () => {
                          try {
                            await likeTweetMutation.mutateAsync(data?.tweetId);
                            toast.success("Tweet Liked Successfully!!!");
                          } catch (error: any) {
                            toast.error(error?.message);
                          }
                        }}
                        className="cursor-pointer inline-flex items-center gap-2 border border-[#F1F1F1] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition"
                      >
                        <img src={LoveIco} alt="love icon" />
                        Like
                      </div>
                    </div>
                  )}

                  {/* </a> */}
                  {/* <!-- Feild --> */}
                  <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                    How to complete this task:
                  </h1>

                  <div className="text-[#A6A6A6] text-[14px] mt-2 mb-6">
                    <p>
                      1. Click on the heart icon in the tweet preview to like
                      the tweet.
                    </p>
                    <p>2. Click on the button below to verify the task.</p>
                  </div>

                  {/* <!-- Feild --> */}
                  <div className="flex items-center gap-4">
                    <button
                      type="button"
                      disabled={createTask?.isLoading}
                      onClick={() => toggleModal("Like")}
                      className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-[#4D4D4D] hover:border-[#00C2FF] rounded-full text-sm text-[#D3D3D3] hover:text-[#00C2FF] px-5 py-4 transition"
                    >
                      Cancel
                    </button>
                    {!isPreview && (
                      <button
                        type="submit"
                        disabled={createTask?.isLoading}
                        className="inline-flex w-full items-center justify-center gap-2 bg-[#00C2FF] hover:border-[#00C2FF] border-[#D3D3D3] rounded-full text-sm text-[#FFFFFF] px-5 py-4 transition"
                      >
                        {createTask?.isLoading ? (
                          <ClipLoader color="#FFFFFF" size={20} />
                        ) : (
                          "Verify Task"
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LikeModal;

const ExtractTweetIdFromUrl = (tweetUrl: string): any => {
  tweetUrl = tweetUrl?.trim();
  // remove any trailing slash
  if (tweetUrl?.endsWith("/")) {
    tweetUrl = tweetUrl?.slice(0, -1);
  }
  // remove any arguments
  if (tweetUrl?.includes("?")) {
    tweetUrl = tweetUrl?.split("?")[0];
  }
  const tweetId = tweetUrl?.split("/")?.pop();
  return tweetId;
};
