import React from "react";
import Logo from "../assets/images/Logo.png";
import Twitter from "../assets/images/twitter.svg";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  return (
    <>
      {/* <!-- Footer Area --> */}
      <footer className="footer-area py-10 md:py-14 bg-[#0D0D0D] hidden md:block ">
        <div className="container ">
          <div className="grid md:grid-cols-12 gap-12">
            <div className="md:col-span-6">
              <Link
                to="/"
                className="cursor-pointer inline-block mb-4 ml-[-16px]"
              >
                <img className="h-[50px]" src={Logo} alt="" />
              </Link>
              <p className="text-xs mb-4 text-[#F1F1F1] text-nowrap">
                Future of social interaction. Get paid for every tweet.
              </p>
              <p className="text-xs text-[#A6A6A6]">
                All rights reserverd by SUPIDO - 2024
              </p>
            </div>
            <div className="md:col-span-6  flex justify-start flex-col items-end ">
              <ul className="text-[#FCFCFC] flex flex-wrap items-center md:justify-end gap-4 gap-x-6 mb-4 ">
                <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/" ? "text-[#00C2FF]" : ""
                    } `}
                    to="/"
                  >
                    How it works
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/about-us" ? "text-[#00C2FF]" : ""
                    } `}
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li> */}

                <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/referrals"
                        ? "text-[#00C2FF]"
                        : ""
                    } `}
                    to="/referrals"
                  >
                    Referrals
                  </Link>
                </li>
              </ul>
              <ul className="flex flex-wrap items-center md:justify-end gap-6 w-[210px] ">
                <li className="  flex justify-center w-full">
                  <div
                    className="cursor-pointer flex items-center justify-center w-10 h-10 hover:bg-[#00C2FF]/20 border border-gray-600 rounded-full transition"
                    onClick={() =>
                      window?.open(
                        "https://x.com/supidocoin?s=21&t=9xMeZQLNw6ldBZld-1lCYO"
                      )
                    }
                  >
                    <img src={Twitter} alt="twitter" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer-area py-10 md:py-14 bg-[#0D0D0D] md:hidden">
        <div className="container">
          <div className="flex flex-col gap-[28px] items-center">
            <div>
              <ul className="text-[#FCFCFC] flex flex-wrap items-center md:justify-end gap-4 gap-x-6 mb-4 flex flex-col">
                <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/" ? "text-[#00C2FF]" : ""
                    } `}
                    to="/"
                  >
                    How it works
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/about-us" ? "text-[#00C2FF]" : ""
                    }`}
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`cursor-pointer text-sm hover:text-[#00C2FF] transition ${
                      location?.pathname === "/referrals"
                        ? "text-[#00C2FF]"
                        : ""
                    } `}
                    to="/referrals"
                  >
                    Referrals
                  </Link>
                </li>
              </ul>
              <ul className="flex flex-wrap items-center justify-center gap-6 mt-[24px]">
                <li>
                  <div
                    className="cursor-pointer flex items-center justify-center w-10 h-10 hover:bg-[#00C2FF]/20 border border-gray-600 rounded-full transition"
                    onClick={() => window?.open("https://twitter.com/?lang=en")}
                  >
                    <img src={Twitter} alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center">
              <Link
                to="/"
                className="cursor-pointer inline-block mb-4 ml-[-16px]"
              >
                <img className="h-[50px]" src={Logo} alt="" />
              </Link>
              <p className="text-xs mb-4 text-[#F1F1F1] text-nowrap">
                Future of social interaction. Get paid for every tweet.
              </p>
              <p className="text-xs text-[#A6A6A6]">
                All rights reserverd by SUPIDO - 2024
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
