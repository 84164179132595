import React from "react";
import { Link } from "react-router-dom";
import CreateProject from "../components/CreateProject";
import Logo from "../assets/images/Logo.png";
import ProfileImage from "../assets/images/profile-img.jpg";
import ViewprojectIco from "../assets/images/view-project.svg";
import MenuIco from "../assets/images/menu.svg";
import SelectArrowIco from "../assets/images/select-arrow.svg";
import TimerCountIco from "../assets/images/timer-count.svg";
import ProjectLogo1 from "../assets/images/project-logo1.svg";
import ProjectLogo2 from "../assets/images/project-logo2.svg";
import ProjectLogo3 from "../assets/images/project-logo3.svg";
import ArrowsIco from "../assets/images/arrows.svg";
import BidIco from "../assets/images/bid-eth.svg";

function DashboardListing() {
  return (
    <div className="bg-gray-800">
      <CreateProject />
      {/* <!-- Connect Wallet Area --> */}
      <div
        id="connect-wallet"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-6">Connect Wallet</h1>
        <p className="text-sm text-gray-400 mb-8">Connect your EVM Wallet.</p>
        {/* <!-- Feild --> */}
        <div className="flex items-center gap-4">
          <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
            Cancel
          </button>
          <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border border-gray-400 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-2 py-4 transition">
            Connect EVM Wallet
          </button>
        </div>
      </div>
      {/* <!-- Header Area --> */}
      <header className="header-area py-5 md:py-10 z-50 relative">
        <div className="container">
          <div className="flex items-center justify-between gap-6">
            <Link to="/" className="inline-block">
              <img className="h-[28px] md:h-[50px]" src={Logo} alt="" />
            </Link>
            <nav className="header-navbar hidden md:block">
              <ul className="flex flex-col md:flex-row items-center justify-end gap-6">
                <li className="md:order-3">
                  <Link to="/dashboard">
                    <img
                      className="w-11 h-11 object-cover rounded-full"
                      src={ProfileImage}
                      alt=""
                    />
                  </Link>
                </li>
                <li className="md:order-1">
                  <a
                    className="popup-with-zoom-anim text-sm text-gray-100 hover:text-blue-800 transition"
                    href="#create-project"
                  >
                    Create project
                  </a>
                </li>
                <li className="md:order-2">
                  <Link
                    className="inline-flex items-center gap-2 border border-gray-50 hover:border-blue-800 rounded-full text-sm text-gray-50 px-5 py-2.5 transition"
                    to="/top-project"
                  >
                    <img src={ViewprojectIco} alt="" /> View all projects
                  </Link>
                </li>
              </ul>
            </nav>
            <button className="header-toggler inline-flex md:hidden items-center gap-2 border border-gray-500 rounded-full px-5 py-2.5 transition">
              <img className="h-5" src={MenuIco} alt="" />
            </button>
          </div>
        </div>
      </header>

      {/* <!-- Dashboard Area --> */}
      <section className="dashboard-area py-10 md:pb-36">
        <div className="container">
          <h3 className="text-3xl text-blue-800 mb-8">
            Listing project for Attention Space
          </h3>
          <div className="sm:grid md:grid-cols-12 gap-6 mb-8">
            <div className="md:col-span-7">
              <form action="#" className="mb-6">
                <div className="flex flex-wrap items-center justify-between gap-4 border-b border-gray-500 py-3 mb-4">
                  <p className="text-base text-gray-100">
                    Your project will be listed on
                  </p>
                  <p className="text-xl text-blue-800 font-light">14-01-2024</p>
                </div>
                <label
                  className="block text-base text-gray-100 mb-4"
                  htmlFor="select-1"
                >
                  Project
                </label>
                <div className="form-field relative mb-6">
                  <select
                    id="select-1"
                    className="relative cursor-pointer bg-transparent border border-gray-300 rounded-xl text-base text-gray-200 placeholder:text-gray-200 w-full focus:outline-0 py-4 px-5 pr-10 z-2"
                  >
                    <option value="1">Token Trail Network</option>
                    <option value="1">Token Trail Network 2</option>
                    <option value="1">Token Trail Network 3</option>
                  </select>

                  <span className="h-6 absolute top-0 bottom-0 right-4 border-l border-gray-500 pl-3 py-2 m-auto z-1">
                    <img className="block" src={SelectArrowIco} alt="" />
                  </span>
                </div>
                <label
                  className="flex items-center gap-2 text-base text-gray-100 mb-2"
                  htmlFor="timer-1"
                >
                  Countdown <img src={TimerCountIco} alt="" />
                </label>
                <div id="timer-clocker" className="mb-4"></div>
                <p className="text-base text-blue-800 mb-6">
                  Bid ends on 14-01-2024, 08:00 PM
                </p>
                <label
                  className="block text-base text-gray-100 mb-4"
                  htmlFor="bid-1"
                >
                  Bid
                </label>
                <div className="form-field relative mb-6">
                  <input
                    id="bid-1"
                    placeholder="0.5 ETH"
                    className="relative cursor-pointer bg-transparent border border-gray-300 rounded-xl text-base text-gray-200 placeholder:text-gray-200 w-full focus:outline-0 py-4 px-5 pr-10 z-2"
                  />
                  <span className="h-6 absolute top-0 bottom-0 right-4 border-l border-gray-500 pl-3 py-2 m-auto z-1">
                    <img className="block -mt-1" src={BidIco} alt="" />
                  </span>
                </div>
                <a
                  href="#connect-wallet"
                  className="popup-with-zoom-anim  w-full flex items-center justify-center gap-2 hover:bg-blue-800/20 border border-blue-800 rounded-full text-sm text-blue-800 px-12 py-4 transition"
                >
                  Pay Bid Amount
                </a>
              </form>
            </div>
            <div className="md:col-span-5">
              <div className="w-full overflow-auto border border-gray-500 rounded-xl p-6 mb-6">
                <div id="calendar"></div>
                <div className="flex items-center justify-between mt-6">
                  <p className="text-base text-blue-800">Clear</p>
                  <p className="text-base text-blue-800">Today</p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-base text-blue-800 mb-6">Top Projects</p>

          <div className="grid items-center md:grid-cols-12 gap-6 mb-8">
            <div className="md:col-span-6 lg:col-span-4">
              <div className="project-box relative bg-gray-700 text-center p-6">
                <span className="absolute top-4 right-4 bg-gray-800 rounded-full text-xs text-gray-100 px-4 py-2">
                  Rank 1
                </span>
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo1}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <div className="flex items-center justify-between bg-gray-600 p-3 mb-6">
                  <p className="text-base text-gray-100">Bid Amount</p>
                  <p className="text-base text-gray-100">0.75 ETH</p>
                </div>
                <Link
                  className="inline-flex items-center gap-2 bg-gray-800 hover:bg-blue-800/20 border border-gray-100 rounded-full text-sm text-blue-800 px-12 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <div className="project-box relative bg-gray-700 text-center p-6">
                <span className="absolute top-4 right-4 bg-gray-800 rounded-full text-xs text-gray-100 px-4 py-2">
                  Rank 2
                </span>
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo2}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <div className="flex items-center justify-between bg-gray-600 p-3 mb-6">
                  <p className="text-base text-gray-100">Bid Amount</p>
                  <p className="text-base text-gray-100">0.75 ETH</p>
                </div>
                <Link
                  className="inline-flex items-center gap-2 bg-gray-800 hover:bg-blue-800/20 border border-gray-100 rounded-full text-sm text-blue-800 px-12 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <div className="project-box relative bg-gray-700 text-center p-6">
                <span className="absolute top-4 right-4 bg-gray-800 rounded-full text-xs text-gray-100 px-4 py-2">
                  Rank 3
                </span>
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo3}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <div className="flex items-center justify-between bg-gray-600 p-3 mb-6">
                  <p className="text-base text-gray-100">Bid Amount</p>
                  <p className="text-base text-gray-100">0.75 ETH</p>
                </div>
                <Link
                  className="inline-flex items-center gap-2 bg-gray-800 hover:bg-blue-800/20 border border-gray-100 rounded-full text-sm text-blue-800 px-12 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <p className="text-base text-blue-800 mb-6">Other Projects</p>
          <div className="grid items-center md:grid-cols-12 gap-6 lg:gap-y-16 mb-8">
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo2}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo3}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo1}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo2}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo3}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo1}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo2}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-3">
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectLogo3}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  to="/user-quest"
                >
                  <img src={ArrowsIco} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardListing;
