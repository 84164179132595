import Header from "../components/Header";
import Footer from "../components/Footer";
import BackArrowIco from "../assets/images/backArrowIcon.svg";
import ReferralIco1 from "../assets/images/referral-icon1.png";
import ReferralIco2 from "../assets/images/referral-icon2.png";
import ReferralIco3 from "../assets/images/referral-icon3.png";
import ReferralIco4 from "../assets/images/referral-icon4.png";
import ReferralIco5 from "../assets/images/referral-icon5.png";
import ReferralIco6 from "../assets/images/referral-icon6.png";
import { Link } from "react-router-dom";
import React from "react";
import XIco from "../assets/images/x.svg";
import GlobeIco from "../assets/images/globe.svg";
import TelegramIco from "../assets/images/telegram.svg";
import DbStat from "../assets/images/db-stat-bg.png";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useSpring } from "react-spring";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";

function Referral() {
  const { data: token } = useQuery(["token"], () =>
    localStorage.getItem("token")
  );
  const { data: userData } = useGetLoggedInUser();
  const { connected } = useWallet();
  const config = {
    mass: 5,
    tension: 350,
    friction: 40,
  };

  // React Spring useSpring hook to control carousel rotation
  const [,] = useSpring(() => ({
    from: { rotationX: 0, rotationY: 0, scale: 1 },
    to: async (next: any) => {
      await next({ rotationY: 180, rotationX: 180, scale: 1.5 });
      await next({ rotationY: 360, rotationX: 360, scale: 1 });
    },
    config: config,
  }));

  return (
    <div className="bg-[#070707]">
      {/* <!-- Header Area --> */}
      <Header />

      <div className="container pt-[96px] font-[Barlow]">
        <Link to="/" id="back-link">
          <div className="flex items-center gap-[12px] md:gap-[20px]">
            <img
              src={BackArrowIco}
              alt="back arrow"
              className="inline-block h-[32px] w-[32px] md:h-[50px] md:w-[50px]"
            />
            <h3 className="text-[#FCFCFC] font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[38px]">
              Referral
            </h3>
          </div>
        </Link>
      </div>
      <section
        className="referral-area font-[Barlow] text-[#ABBBC2] font-[300] text-[14px] md:text-[16px]"
        id="referrals"
      >
        <div className="container py-[24px] md:py-[46px] md:pl-[100px]">
          <p className="text-[12px] text-white font-[400] w-full border border-[#06334b] text-center md:text-[20px] py-[9px] bg-[#00C2FF1A] shadow-neon rounded-[3px]">
            Earn up to <span className="font-[700] text-[#00C2FF]">10%</span> of
            the amount your friends earn
          </p>
          {token && connected && userData?.telegramId ? (
            <div className="text-white mt-[80px]">
              <h1 className="text-[20px] md:text-[36px] text-[#FCFCFC] font-[900] md:font-[700] my-[20px] md:my-[40px]">
                Invite Friends
              </h1>
              <div className="db-card w-full mt-[16px] md:mt-[34px] px-[16px] md:px-[24px] pt-[16px] md:pt-[24px] pb-[72px] md:pb-[72px]">
                <div className="flex flex-col md:flex-row gap-[8px] md:gap-[16px] mb-[20px] md:mb-[28px]">
                  <div
                    className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1"
                    style={{
                      backgroundImage: `url(${DbStat})`,
                      backgroundSize: "100% 100%",
                    }}
                  >
                    <h1 className="text-[48px]">
                      {userData?.totalPointsReferral?.toFixed(6)}
                    </h1>
                    <p className="text-[14px] text-[#ABBBC2] font-[400]">
                      Total Credits
                    </p>
                  </div>
                  <div
                    className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1"
                    style={{
                      backgroundImage: `url(${DbStat})`,
                      backgroundSize: "100% 100%",
                    }}
                  >
                    <h1 className="text-[48px]">
                      {userData?.totalReferralInvites}
                    </h1>
                    <p className="text-[14px] text-[#ABBBC2] font-[400]">
                      Total Invited
                    </p>
                  </div>
                </div>
                <p className="text-[16px] md:text-[20px] capitalize mb-[8px] md:mb-[12px]">
                  Invite friends for extra credits
                </p>
                <p className="text-[14px] md:text-[16px] font-[400] mb-[16px]">
                  On inviting friends, you receive referral rewards in{" "}
                  <span className="text-[#00C2FF] font-[700]">$SUPIDO</span>{" "}
                  tokens from your friend’s earnings
                </p>
                <div className="border border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                  <img className="w-[12px] md:w-[20px]" src={GlobeIco} alt="" />
                  <p className="leading-[22px] text-[32px]">|</p>
                  <p className="text-[12px] md:text-[16px] truncate ...">
                    {`${window?.location?.origin}?invitation=${
                      userData?.myReferralCode || ""
                    }`}
                  </p>
                  <div
                    className="ml-auto cursor-pointer text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                    onClick={() => {
                      navigator?.clipboard
                        ?.writeText(
                          `${window?.location?.origin}?invitation=${
                            userData?.myReferralCode || ""
                          }`
                        )
                        .then(() => {
                          toast.success("Invitation link copied!");
                        });
                    }}
                  >
                    Copy
                  </div>
                </div>
                <div className="border my-6 border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                  <img className="w-[12px] md:w-[20px]" src={XIco} alt="" />
                  <p className="leading-[22px] text-[32px]">|</p>
                  <p className="text-[12px] md:text-[16px] truncate ...">
                    {`https://twitter.com/${userData?.screen_name}`}
                  </p>
                  <div
                    className="cursor-pointer ml-auto text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                    onClick={() => {
                      const url = `https://twitter.com/compose/post`;

                      window.open(url, "_blank");
                    }}
                  >
                    Share
                  </div>
                </div>
                <div className="border border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                  <img
                    className="w-[12px] md:w-[20px]"
                    src={TelegramIco}
                    alt=""
                  />
                  <p className="leading-[22px] text-[32px]">|</p>
                  <p className="text-[12px] md:text-[16px] truncate ...">
                    {`https://telegram.org/user/${userData?.screen_name}`}
                  </p>
                  <div
                    className="cursor-pointer ml-auto text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                    onClick={() => {
                      const url = `https://telegram.org/user/${userData?.screen_name}`;
                      window.open(url, "_blank");
                    }}
                  >
                    Share
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-xl">
              <h5 className="text-[16px] md:text-[24px] mt-[24px] md:mt-[40px] text-[#FCFCFC] font-[700] mb-[20px]">
                How to invite a friend
              </h5>
              <ul className="pl-5 text-base list-disc">
                <li className="my-2">Get a referral link</li>
                <li className="my-2">
                  Invite your friends to register via your referral link
                </li>
                <li className="my-2">
                  Receive referral rewards in
                  <span className="text-[#00C2FF] font-[600]">
                    {" "}
                    $SUPIDO
                  </span>{" "}
                  tokens from your friend’s earnings
                </li>
              </ul>
            </div>
          )}

          <h5 className="text-[20px] md:text-[36px] text-[#FCFCFC] font-[900] md:font-[700] my-[20px] md:my-[40px]">
            Affiliate levels
          </h5>

          <div className="grid grid-cols-12 gap-[12px] md:gap-[24px]">
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco1}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Starter
                  </h4>
                </div>

                <ul className="text-[14px] list-disc pl-5">
                  <li className="my-2">1-4 personal withdrawal</li>
                  <li className="my-2">Commission: 1%</li>
                </ul>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco2}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Bronze
                  </h4>
                </div>
                <ul className="pl-5 text-base list-disc">
                  <li className="my-2">5-19 personal withdrawal</li>
                  <li className="my-2">Commission: 2%</li>
                </ul>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco3}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Silver
                  </h4>
                </div>
                <ul className="pl-5 text-base list-disc">
                  <li className="my-2">20-49 withdrawal</li>
                  <li className="my-2">Commission: 3%</li>
                </ul>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco4}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Gold
                  </h4>
                </div>
                <ul className="pl-5 text-base list-disc">
                  <li className="my-2">50-99 withdrawal</li>
                  <li className="my-2">Commission: 5%</li>
                </ul>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco5}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Platinum
                  </h4>
                </div>
                <ul className="pl-5 text-base list-disc">
                  <li className="my-2">100-249 withdrawal</li>
                  <li className="my-2">Commission: 7%</li>
                </ul>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4">
              <div className="referral-box h-full md:h-[180px] px-[16px] pt-[16px] pb-[24px] bg-[#070b0c] flex justify-between flex-col border border-[#06334b] rounded-[2px] hover:border-[#00C2FF] transition p-6">
                <div className="flex items-center gap-[8px] md:gap-[16px] mb-[28px]">
                  <img
                    className="md:w-[40px] md:h-[32px] h-[24px] w-[30px]"
                    src={ReferralIco6}
                    alt=""
                  />
                  <h4 className="font-[500] text-[20px] md:text-[24px] text-[#00C2FF]">
                    Diamond
                  </h4>
                </div>
                <ul className="pl-5 text-base list-disc">
                  <li className="my-2">250+ Withdrawal</li>
                  <li className="my-2">Commission: 10%</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer Area --> */}
      <Footer />
    </div>
  );
}

export default Referral;
