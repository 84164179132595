import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useDeleteQuest } from "../../utils/quest.api";

const DeleteModal = ({ isCreateOpen, toggleModal, questId }: any) => {
  const deleteQuestMutation = useDeleteQuest();
  console.log(questId);
  return (
    <Transition.Root show={isCreateOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center backdrop-filter backdrop-blur-[2px]"
        onClose={() => toggleModal("Follow")}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50  backdrop-blur-2xl" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative w-[96%] max-w-md ">
            <button
              className="absolute top-0 right-0 focus:outline-none w-[44px] h-[44px]"
              onClick={() => toggleModal()}
            >
              <p className="text-[24px] text-white opacity-[0.65]">x</p>
            </button>
            <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
              <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                Delete Quest
              </h1>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    await deleteQuestMutation.mutateAsync(questId);
                    toast.success("Quest deleted successfully!!!");
                    toggleModal();
                  } catch (error: any) {
                    toast.error(error?.message);
                  }
                }}
              >
                <div className="mb-2">
                  <h4 className="text-md text-[#F1F1F1] font-bold mb-3">
                    Are you sure you want to delete this quest?
                  </h4>
                </div>

                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    disabled={deleteQuestMutation?.isLoading}
                    onClick={() => toggleModal()}
                    className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-[#4D4D4D] hover:border-[#00C2FF] rounded-full text-sm text-[#D3D3D3] hover:text-[#00C2FF] px-3 py-2 transition"
                  >
                    NO
                  </button>
                  <button
                    type="submit"
                    disabled={deleteQuestMutation?.isLoading}
                    className="inline-flex w-full items-center justify-center gap-2 bg-[#00C2FF] hover:border-[#00C2FF] border-[#A6A6A6] rounded-full text-sm text-[#FFFFFF] px-3 py-2 transition"
                  >
                    {deleteQuestMutation?.isLoading ? (
                      <ClipLoader color="#FFFFFF" size={20} />
                    ) : (
                      "YES"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteModal;
