import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useVerifyTwitter } from "../utils/auth.api";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

function Callback() {
  // get state and code from arguments
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const navigate = useNavigate();

  const { mutateAsync: verifyTwitter } = useVerifyTwitter();

  useEffect(() => {
    console.log("state", state);
    console.log("code", code);
    if (state && code) {
      verifyTwitter({ state, code })
        .then((data) => {
          console.log(data);
          const token = data?.token;

          if (token) {
            localStorage.clear();
            localStorage.setItem("token", token?.access_token);
            queryClient.invalidateQueries(["token"]);
            navigate("/");
          }
          // redirect to home page
          window.location.href = "/?popup=true";
        })
        .catch((error) => {
          toast.warn(
            error?.message || "Something went wrong. Please try again!"
          );
          console.log(error);
        });
    } else {
      window.location.href = "/";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state, verifyTwitter]);

  return (
    <div className="bg-[#070707] min-h-screen">
      <p>Logged in</p>
    </div>
  );
}

export default Callback;
