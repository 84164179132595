import React, { useEffect, useState } from "react";
import {
  useLocation,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import Logo from "../assets/images/Logo.png";
import MenuIco from "../assets/images/menuIcon.png";
import closeIco from "../assets/images/close.svg";
import mobileNavBg from "../assets/images/mobile-nav-bg.png";
import ExitIcon from "../assets/images/exit.svg";
import PointsModal from "./modals/PointsModal";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";

function Header({ howItWorks }: any) {
  const { data: token } = useQuery(["token"], () =>
    localStorage.getItem("token")
  );
  const { connected } = useWallet();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setReferralCode] = useState<any>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data: user } = useGetLoggedInUser();
  const queryClient = useQueryClient();
  const toggleMenu = () => {
    setIsMenuOpen((prev: any) => !prev);
  };

  const [isScrolled, setIsScrolled] = useState(
    localStorage.getItem("isScrolled") === "true" // Check if previously scrolled
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
        localStorage.setItem("isScrolled", "true"); // Persist scrolled state in local storage
      } else {
        setIsScrolled(false);
        localStorage.setItem("isScrolled", "false"); // Persist scrolled state in local storage
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (searchParams) {
      const invitationCode = searchParams?.get("invitation");
      if (invitationCode) {
        setReferralCode(invitationCode);
        setIsCreateOpen(false);
      }
    }
  }, [searchParams]);

  return (
    <>
      <PointsModal
        isCreateOpenPoints={isCreateOpen}
        toggleModal={() => setIsCreateOpen(!isCreateOpen)}
      />
      <header
        className={`header-area z-50 fixed top-0 left-0 w-full py-[16px] md:py-[10px] ${
          howItWorks === true ? "pr-[17px]" : "pr-0"
        } ${isScrolled ? "bg-[#000000]" : ""}`}
      >
        <div className="container ">
          <div className="flex items-center justify-between gap-6 ">
            <Link to="/" className="inline-block cursor-pointer">
              <img className="h-[28px] md:h-[50px]" src={Logo} alt="" />
            </Link>
            <nav className="hidden header-navbar md:block w-full">
              <ul className="flex flex-col md:flex-row items-center justify-end gap-4 lg:gap-6 text-[#FCFCFC]">
                <li>
                  <Link
                    className={`cursor-pointer text-[14px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/" ? "text-[#00C2FF]" : ""
                    }`}
                    to="/"
                  >
                    How it works
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`cursor-pointer text-[14px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/about-us" ? "text-[#00C2FF]" : ""
                    }`}
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li> */}

                <li>
                  <Link
                    className={`cursor-pointer text-[14px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/referrals"
                        ? "text-[#00C2FF]"
                        : ""
                    }`}
                    to="/referrals"
                  >
                    Referrals
                  </Link>
                </li>
                {user?.id && user?.telegramId && connected && (
                  <li>
                    <Link
                      className={`cursor-pointer text-[14px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                        location?.pathname === "/quests" ? "text-[#00C2FF]" : ""
                      }`}
                      to="/quests"
                    >
                      Tasks
                    </Link>
                  </li>
                )}
                {user?.isAdmin && token && user?.telegramId && connected && (
                  <li>
                    <Link
                      className=" cursor-pointer text-[14px] font-[600] font-[Inter] hover:text-[#00C2FF] transition"
                      to="/create-quest"
                    >
                      Create Quest
                    </Link>
                  </li>
                )}
                {token && user?.telegramId && connected ? (
                  <div className="flex gap-4 lg:gap-6 items-centers">
                    <div
                      onClick={() => navigate("/dashboard")}
                      className="rounded-[42px] px-[12px] py-[8px] bg-[#00C2FF1A] border border-[#0094FF33] flex gap-[8px] items-center cursor-pointer"
                    >
                      <div className="rounded-full bg-[#B0F6FF] w-[32px] h-[32px] overflow-hidden pt-[2px]">
                        <img src={user?.profilePicUrl} alt="" />
                      </div>
                      <p className="font-[600] m-0 text-[14px] hidden lg:block">
                        {user?.name}
                      </p>
                    </div>
                    <div
                      className="flex gap-[8px] items-center cursor-pointer"
                      onClick={() => {
                        localStorage.clear();
                        queryClient.invalidateQueries(["token"]);
                        queryClient.removeQueries(["getLoggedInUser"]);
                        navigate("/");
                      }}
                    >
                      <img src={ExitIcon} alt="" />
                      <p className="text-[#FF7171] text-[14px]">Logout</p>
                    </div>
                  </div>
                ) : (
                  <li id="login-button">
                    <button
                      className="cursor-pointer signin-btn inline-flex items-center gap-2 hover:bg-[#00C2FF]/20 border border-[#00C2FF] rounded-full text-sm text-[#00C2FF] px-5 py-2.5 transition"
                      onClick={() => setIsCreateOpen(true)}
                    >
                      {/* Sign in using <img src={TwitterIco} alt="" /> */}
                      Connect
                    </button>
                  </li>
                )}
                {/* <!-- <li>
          <a
            className="inline-flex items-center gap-2 hover:bg-[#00C2FF]/20 border border-[#00C2FF] rounded-full text-sm text-[#00C2FF] px-5 py-2.5 transition"
            href="top-project.html"
            >Explore top projects
            <img src="images/arrows-right.svg" alt=""
          /></a>
        </li> --> */}
              </ul>
            </nav>
            <nav
              style={{
                backgroundImage: `url(${mobileNavBg})`,
                backgroundSize: "cover",
                backgroundPosition: "left",
              }}
              className={`md:hidden ${
                isMenuOpen ? "block" : "hidden"
              } z-[40] absolute top-20 flex justify-center py-6  w-[100vw] left-0`}
            >
              <ul className="flex flex-col md:flex-row items-center justify-end gap-6 text-[#FCFCFC] ">
                <li>
                  <Link
                    className={`cursor-pointer text-[16px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/" ? "text-[#00C2FF]" : ""
                    }`}
                    to="/"
                  >
                    How it works
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`cursor-pointer text-[16px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/about-us" ? "text-[#00C2FF]" : ""
                    }`}
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`cursor-pointer text-[16px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                      location?.pathname === "/referrals"
                        ? "text-[#00C2FF]"
                        : ""
                    }`}
                    to="/referrals"
                  >
                    Referrals
                  </Link>
                </li>
                {user?.id && user?.telegramId && connected && (
                  <li>
                    <Link
                      className={`cursor-pointer text-[16px] font-[600] font-[Inter] hover:text-[#00C2FF] transition ${
                        location?.pathname === "/referrals"
                          ? "text-[#00C2FF]"
                          : ""
                      }`}
                      to="/quests"
                    >
                      Tasks
                    </Link>
                  </li>
                )}

                {token && connected && user?.telegramId ? (
                  <div className="flex flex-col justify-center gap-6 cursor-pointer items-centers">
                    <div
                      className="flex gap-[8px] justify-center items-center"
                      onClick={() => {
                        localStorage.clear();
                        queryClient.invalidateQueries(["token"]);
                        queryClient.removeQueries(["getLoggedInUser"]);
                        navigate("/");
                      }}
                    >
                      <img src={ExitIcon} alt="" />
                      <p className="text-[#FF7171] text-[14px]">Logout</p>
                    </div>
                  </div>
                ) : (
                  <li id="login-button">
                    <button
                      className="cursor-pointer signin-btn inline-flex items-center gap-2 hover:bg-[#00C2FF]/20 border border-[#00C2FF] rounded-full text-sm text-[#00C2FF] px-5 py-2.5 transition"
                      onClick={() => {
                        setIsCreateOpen(true);
                        setIsMenuOpen(false);
                      }}
                    >
                      {/* Sign in using <img src={TwitterIco} alt="" /> */}
                      Connect
                    </button>
                  </li>
                )}
              </ul>
            </nav>
            {/* <!-- <button id="login-btn" className="text-white bg-[#00C2FF] p-3 rounded-xl" onclick="signIn()">sign in</button> --> */}
            <div className="flex gap-6 items-center">
              {token && connected && user?.telegramId && (
                <Link to="/dashboard">
                  <div className="md:hidden ml-auto mr-[-8px] rounded-[42px] px-[8px] py-[4px] bg-[#00C2FF1A] border border-[#0094FF33] flex gap-[8px] items-center">
                    <div className="rounded-full bg-[#B0F6FF] w-[24px] h-[24px] overflow-hidden pt-[2px]">
                      <img src={user?.profilePicUrl} alt="" />
                    </div>
                    <p className="font-[600] m-0 text-[14px] text-[#FCFCFC] hidden 375:block">
                      {user?.name}
                    </p>
                  </div>
                </Link>
              )}
              <button
                className="inline-flex items-center gap-2 transition header-toggler md:hidden"
                // onclick="toggleMenuIcon()"
                onClick={toggleMenu}
              >
                <img
                  id="toggleImage"
                  width={isMenuOpen ? "16px" : "24px"}
                  height={isMenuOpen ? "16px" : "24px"}
                  src={isMenuOpen ? closeIco : MenuIco}
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
