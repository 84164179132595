import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import completedPoint from "../../assets/images/pointChecked.png";
import notCompletedPoint from "../../assets/images/point.png";
import phantomImg from "../../assets/images/solana.svg";
import point from "../../assets/images/point.png";
import XIcon from "../../assets/images/xBlueBg.svg";
import Telegram from "../../assets/images/telegramBlue.svg";
import ReferralIcon from "../../assets/images/referralBg.svg";
import closeBtn from "../../assets/images/closeBtn.svg";
import blueLine from "../../assets/images/stepperLine.png";
import { toast } from "react-toastify";
import pointAmount from "../../assets/images/pointAmount.svg";

import {
  useGetLoggedInUser,
  useSignupTelegram,
  useSignupWithReferralCode,
  useSignupWithTwitter,
} from "../../utils/auth.api";
import WalletConnectModal from "./WalletConnectModal";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PointsModal = ({
  isCreateOpenPoints,
  toggleModal,
  myParam,
  dataWallet,
  isCreateO,
  setIsCreateOpenPoints,
}: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myParamRef: any = searchParams.get("invitation");
  const { data: userData } = useGetLoggedInUser();
  const [, setWalletConnected] = useState(false);
  const { publicKey, connected } = useWallet();
  const signupWithTwitterMutation = useSignupWithTwitter();
  const signupWithReferralCodeMutation = useSignupWithReferralCode();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const { mutateAsync: signupTelegram } = useSignupTelegram();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [refCode, setRefCode] = useState(myParamRef || "");
  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.async = true;
      // script.setAttribute("data-telegram-login", "turbo_testtest_bot");
      // script.setAttribute("data-size", "medium");
      script.setAttribute(
        "data-auth-url",
        // " https://735d-2407-d000-d-fed1-8193-1cf4-d0c3-9d27.ngrok-free.app"
        `${window.location.origin}`
      );
      script.setAttribute("data-request-access", "write");

      const existingButton: any = document.querySelector(
        ".telegram-connect-button"
      );
      if (existingButton) {
        existingButton.parentNode.removeChild(existingButton);
      }
      const parentContainer = document.querySelector(
        ".telegram-connect-container"
      );
      if (parentContainer) {
        parentContainer.appendChild(script);
      }
    }, 100);
    // return () => clearTimeout(timeoutId);
  }, [isCreateOpenPoints]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Transition.Root show={isCreateOpenPoints} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[50] flex justify-center items-end overflow-y-auto sm:items-center backdrop-filter backdrop-blur-[2px]"
        onClose={() => {}}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 backdrop-blur-2xl" />

        {windowWidth < 0 ? (
          <Transition.Child
            as={Fragment}
            enter="transition ease-in duration-300 transform"
            enterFrom="translate-y-full"
            enterTo="0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="0"
            leaveTo="translate-y-full"
          >
            <div className="relative w-full sm:w-[96%] max-w-5xl font-[Barlow]">
              <div className="p-4 flex justify-between items-start rounded-t-2xl bg-[#00C2FF] mx-[0%] w-[100%] md:mx-[14%] md:w-[82%]   lg:mx-[11%] lg:w-[87%] text-[#FFF]">
                <div className="flex flex-col leading-6 md:leading-10">
                  <p className="m-0 text-[12px] md:text-base font-normal">
                    Extra Points
                  </p>
                  <p className="m-0 text-[24px] md:text-[32px] font-bold">
                    {0 +
                      (localStorage.getItem("token") ? +30 : 0) +
                      (connected ? +30 : 0) +
                      (userData?.telegramId ? +30 : 0)}
                    /90
                  </p>
                </div>
                <div className="flex items-start p-2">
                  <img
                    className="cursor-pointer"
                    onClick={toggleModal}
                    src={closeBtn}
                    alt="close-icon"
                    width={"36px"}
                    height={"36px"}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="hidden  md:inline-flex px-2 sm:px-[13px] py-[12px] items-center gap-[10px] border-2 border-[#212121] bg-[#1A1A1A] shadow-md h-[330px] sm:h-[262px] mt-12">
                  <div className="flex flex-col justify-center items-center gap-[90px] sm:gap-[50px] text-[12px] md:text-normal">
                    <p
                      className={`flex px-2 md:px-4 justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        localStorage.getItem("token")
                          ? "opacity-100"
                          : "opacity-70"
                      }`}
                    >
                      +30
                    </p>

                    <p
                      className={`flex px-2 md:px-4  justify-center items-center rounded-[20px] md:rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        userData?.telegramId ? "opacity-[1]" : "opacity-[0.7]"
                      }`}
                    >
                      +30
                    </p>
                    <p
                      className={`flex px-2 md:px-4  justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        connected ? "opacity-[1]" : "opacity-[0.7]"
                      }`}
                    >
                      +30
                    </p>
                  </div>
                </div>
                <div className="inline-flex px-2 py-6 md:p-6 flex-col justify-center items-center gap-5 md:rounded-3xl border-2 border-[#212121] bg-[#1A1A1A] shadow-md w-full">
                  <div className="flex items-center gap-2 md:gap-[18px] w-full">
                    <div className="flex flex-col items-center ">
                      {windowWidth < 768 ? (
                        <img
                          src={
                            localStorage.getItem("token")
                              ? pointAmount
                              : notCompletedPoint
                          }
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={
                            localStorage.getItem("token")
                              ? completedPoint
                              : notCompletedPoint
                          }
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}

                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 md:h-[65px]"
                      />
                      {windowWidth < 768 ? (
                        <img
                          src={
                            userData?.telegramId
                              ? pointAmount
                              : notCompletedPoint
                          }
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={
                            userData?.telegramId
                              ? completedPoint
                              : notCompletedPoint
                          }
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}
                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 sm:h-[65px]"
                      />
                      {windowWidth < 768 ? (
                        <img
                          src={connected ? pointAmount : notCompletedPoint}
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={connected ? completedPoint : notCompletedPoint}
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}
                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 sm:h-[65px]"
                      />

                      <img src={refCode ? completedPoint : point} alt="point" />
                    </div>
                    <div className="flex flex-col items-center gap-[18px] w-full">
                      <div className="flex  px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={XIcon}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {windowWidth < 768
                              ? "Connect X"
                              : "Connect Your X Account"}
                          </p>
                        </div>
                        <button
                          disabled={signupWithTwitterMutation.isLoading}
                          onClick={async () => {
                            try {
                              if (userData?.id) {
                                return;
                              }

                              const res =
                                await signupWithTwitterMutation.mutateAsync({
                                  referralCode: "",
                                  redirectUrl: `${window.location.origin}`,
                                });
                              if (res) {
                                window.open(res?.data, "_self");
                              }
                              toggleModal();
                            } catch (error: any) {
                              toast.error(error?.message);
                            }
                          }}
                          className={`w-[103px] sm:w-[191px] py-[1px]  flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 hover:opacity-[1] md:leading-10 text-[12px] md:text-[18px] ${
                            !userData?.id ? "opacity-1" : "opacity-[1]"
                          }`}
                        >
                          {userData?.id ? "Connected" : "Connect X"}
                        </button>
                      </div>
                      <div className="telegram-connect-container flex px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={Telegram}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {windowWidth < 768
                              ? "Connect Telegram"
                              : "Connect Your Telegram Account"}
                          </p>
                        </div>
                        <div>{userData?.id}</div>
                        <button
                          className={`w-[103px] sm:w-[191px] py-[1px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 md:leading-10 hover:opacity-[1] text-[12px] md:text-[18px] ${
                            userData?.telegramId !== ""
                              ? "opacity-[1]"
                              : "opacity-[0.7]"
                          }`}
                          onClick={async () => {
                            // if (localStorage.getItem("token")) {
                            if (userData?.telegramId || !userData?.id) {
                              return;
                            }
                            const windowInstance: any = window;
                            console.log("windowInstance", windowInstance);

                            await windowInstance?.Telegram?.Login?.auth(
                              {
                                bot_id: process.env.REACT_APP_BOT_ID,
                                request_access: true,
                              },
                              (data: any) => {
                                if (!data) {
                                  console.log("object");
                                  // authorization failed
                                }

                                signupTelegram({
                                  id: data?.id,
                                  first_name: data?.first_name,
                                  username: data?.username,
                                  photo_url: data?.photo_url,
                                })
                                  .then((dat: any) => {
                                    console.log(dat);
                                    window.location.href = "/";
                                  })
                                  .catch((error: any) => {
                                    toast.warn(
                                      error?.message ||
                                        "Something went wrong. Please try again!"
                                    );
                                    console.log(error);
                                  });
                              }
                            );

                            // } else {
                            //   return;
                            // }
                          }}
                        >
                          {userData?.id && userData?.telegramId
                            ? "Connected"
                            : windowWidth < 768
                            ? "Connect"
                            : "Connect Telegram"}
                        </button>
                      </div>
                      <div className="flex px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={phantomImg}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {publicKey
                              ? `${publicKey
                                  .toBase58()
                                  .slice(0, 5)}...${publicKey
                                  .toBase58()
                                  .slice(-5)}`
                              : "Connect Solana"}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (
                              userData?.id &&
                              userData?.telegramId &&
                              !connected
                            ) {
                              setIsCreateOpen(true);
                            } else {
                              return;
                            }
                          }}
                          className={`w-[103px] sm:w-[191px] py-[1px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 md:leading-10 text-[12px] md:text-[18px] ${
                            userData?.id && userData?.telegramId && !connected
                              ? "opacity-[1]"
                              : "opacity-[0.7]"
                          } hover:opacity-[1]`}
                        >
                          {connected ? "Connected" : "Connect Wallet"}
                        </button>
                      </div>
                      <div className="flex px-2 md:px-6 py-5 items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-3 md:gap-[17.659px] w-full">
                          <img
                            src={ReferralIcon}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <input
                            type="text"
                            className="focus:outline-0 flex h-10 md:h-[50px] px-4 items-center gap-[10px] rounded-xl border border-[#143549] text-[#FCFCFC] leading-5 w-full bg-transparent placeholder:text-[#FCFCFC] text-[12px] md:text-[18px]  "
                            placeholder="Referral Code"
                            value={refCode}
                            onChange={(e) => setRefCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={signupWithReferralCodeMutation.isLoading}
                    onClick={async () => {
                      if (
                        !connected ||
                        !userData?.id ||
                        !userData?.telegramId
                      ) {
                        return;
                      }
                      try {
                        if (!refCode) {
                          toggleModal();

                          return;
                        }
                        const res =
                          await signupWithReferralCodeMutation.mutateAsync({
                            referralCode: refCode,
                            redirectUrl: `${window.location.origin}`,
                          });
                        if (res) {
                          window.open(res?.data, "_self");
                        }
                        setIsCreateOpenPoints(false);
                      } catch (error: any) {
                        toast.error(error?.message);
                      }
                    }}
                    className={`w-full py-[10px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF]  font-medium leading-5 md:leading-10 text-[18px] ${
                      connected && userData?.id && userData?.telegramId
                        ? "opacity-[1]"
                        : "opacity-[0.7]"
                    }`}
                  >
                    Continue
                  </button>
                </div>
              </div>
              <WalletConnectModal
                isCreateOpen={isCreateOpen}
                setIsCreateOpen={setIsCreateOpen}
                dataWallet={dataWallet}
                setWalletConnected={setWalletConnected}
              />
            </div>
          </Transition.Child>
        ) : (
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative w-full sm:w-[96%] max-w-5xl font-[Barlow]">
              <div className="p-4 flex justify-between items-start rounded-t-2xl bg-[#00C2FF] mx-[0%] w-[100%] md:mx-[14%] md:w-[82%]   lg:mx-[11%] lg:w-[87%] text-[#FFF]">
                <div className="flex flex-col leading-6 md:leading-10">
                  <p className="m-0 text-[12px] md:text-base font-normal">
                    Extra Points
                  </p>
                  <p className="m-0 text-[24px] md:text-[32px] font-bold">
                    {0 +
                      (localStorage.getItem("token") ? +30 : 0) +
                      (connected ? +30 : 0) +
                      (userData?.telegramId ? +30 : 0)}
                    /90
                  </p>
                </div>
                <div className="flex items-start p-2">
                  <img
                    className="cursor-pointer"
                    onClick={toggleModal}
                    src={closeBtn}
                    alt="close-icon"
                    width={"36px"}
                    height={"36px"}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="hidden  md:inline-flex px-2 sm:px-[13px] py-[12px] items-center gap-[10px] border-2 border-[#212121] bg-[#1A1A1A] shadow-md h-[330px] sm:h-[262px] mt-12">
                  <div className="flex flex-col justify-center items-center gap-[90px] sm:gap-[50px] text-[12px] md:text-normal">
                    <p
                      className={`flex px-2 md:px-4 justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        localStorage.getItem("token")
                          ? "opacity-100"
                          : "opacity-70"
                      }`}
                    >
                      +30
                    </p>

                    <p
                      className={`flex px-2 md:px-4  justify-center items-center rounded-[20px] md:rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        userData?.telegramId ? "opacity-[1]" : "opacity-[0.7]"
                      }`}
                    >
                      +30
                    </p>
                    <p
                      className={`flex px-2 md:px-4  justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] leading-10 font-medium ${
                        connected ? "opacity-[1]" : "opacity-[0.7]"
                      }`}
                    >
                      +30
                    </p>
                  </div>
                </div>
                <div className="inline-flex px-2 py-6 md:p-6 flex-col justify-center items-center gap-5 md:rounded-3xl border-2 border-[#212121] bg-[#1A1A1A] shadow-md w-full">
                  <div className="flex items-center gap-2 md:gap-[18px] w-full">
                    <div className="flex flex-col items-center ">
                      {windowWidth < 768 ? (
                        <img
                          src={
                            localStorage.getItem("token")
                              ? pointAmount
                              : notCompletedPoint
                          }
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={
                            localStorage.getItem("token")
                              ? completedPoint
                              : notCompletedPoint
                          }
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}

                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 md:h-[65px]"
                      />
                      {windowWidth < 768 ? (
                        <img
                          src={
                            userData?.telegramId
                              ? pointAmount
                              : notCompletedPoint
                          }
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={
                            userData?.telegramId
                              ? completedPoint
                              : notCompletedPoint
                          }
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}
                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 sm:h-[65px]"
                      />
                      {windowWidth < 768 ? (
                        <img
                          src={connected ? pointAmount : notCompletedPoint}
                          width={"32px"}
                          height={"32px"}
                          alt="point"
                        />
                      ) : (
                        <img
                          src={connected ? completedPoint : notCompletedPoint}
                          alt="point"
                          width={"40px"}
                          height={"40px"}
                        />
                      )}
                      <img
                        src={blueLine}
                        alt="line"
                        className="h-16 sm:h-[65px]"
                      />

                      <img src={refCode ? completedPoint : point} alt="point" />
                    </div>
                    <div className="flex flex-col items-center gap-[18px] w-full">
                      <div className="flex  px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={XIcon}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {windowWidth < 768
                              ? "Connect X"
                              : "Connect Your X Account"}
                          </p>
                        </div>
                        <button
                          disabled={signupWithTwitterMutation.isLoading}
                          onClick={async () => {
                            try {
                              const token = localStorage.getItem("token");
                              if (token) {
                                return;
                              }

                              const res =
                                await signupWithTwitterMutation.mutateAsync({
                                  referralCode: "",
                                  redirectUrl: `${window.location.origin}`,
                                });
                              if (res) {
                                window.open(res?.data, "_self");
                              }
                              toggleModal();
                            } catch (error: any) {
                              toast.error(error?.message);
                            }
                          }}
                          className={`w-[103px] sm:w-[191px] py-[1px]  flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 hover:opacity-[1] md:leading-10 text-[12px] md:text-[18px] ${
                            !userData?.id ? "opacity-1" : "opacity-[1]"
                          }`}
                        >
                          {localStorage.getItem("token")
                            ? "Connected"
                            : "Connect X"}
                        </button>
                      </div>
                      <div className="telegram-connect-container flex px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={Telegram}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {windowWidth < 768
                              ? "Connect Telegram"
                              : "Connect Your Telegram Account"}
                          </p>
                        </div>
                        <button
                          className={`w-[103px] sm:w-[191px] py-[1px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 md:leading-10 hover:opacity-[1] text-[12px] md:text-[18px] ${
                            userData?.id ? "opacity-[1]" : "opacity-[0.7]"
                          }`}
                          onClick={async () => {
                            // if (localStorage.getItem("token")) {
                            if (userData?.telegramId || !userData?.id) {
                              return;
                            }
                            const windowInstance: any = window;
                            console.log("windowInstance", windowInstance);

                            await windowInstance?.Telegram?.Login?.auth(
                              {
                                bot_id: process.env.REACT_APP_BOT_ID,
                                request_access: true,
                              },
                              (data: any) => {
                                if (!data) {
                                  console.log("object");
                                  // authorization failed
                                }

                                signupTelegram({
                                  id: data?.id,
                                  first_name: data?.first_name,
                                  username: data?.username,
                                  photo_url: data?.photo_url,
                                })
                                  .then((dat: any) => {
                                    console.log(dat);
                                    window.location.href = "/";
                                  })
                                  .catch((error: any) => {
                                    toast.warn(
                                      error?.message ||
                                        "Something went wrong. Please try again!"
                                    );
                                    console.log(error);
                                  });
                              }
                            );

                            // } else {
                            //   return;
                            // }
                          }}
                        >
                          {userData?.id && userData?.telegramId
                            ? "Connected"
                            : windowWidth < 768
                            ? "Connect"
                            : "Connect Telegram"}
                        </button>
                      </div>
                      <div className="flex px-2 md:px-6 py-5 gap-2  justify-between md:items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-[9.12px] md:gap-[17.659px]">
                          <img
                            src={phantomImg}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <p className="text-[#FFF] text-[12px] md:text-[18px] leading-8 tracking-wide">
                            {publicKey
                              ? `${publicKey
                                  .toBase58()
                                  .slice(0, 5)}...${publicKey
                                  .toBase58()
                                  .slice(-5)}`
                              : "Connect Solana"}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (userData?.id && !connected) {
                              setIsCreateOpen(true);
                            } else {
                              return;
                            }
                          }}
                          className={`w-[103px] sm:w-[191px] py-[1px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF] font-medium leading-8 md:leading-10 text-[12px] md:text-[18px] ${
                            userData?.id ? "opacity-[1]" : "opacity-[0.7]"
                          } hover:opacity-[1]`}
                        >
                          {connected ? "Connected" : "Connect Wallet"}
                        </button>
                      </div>
                      <div className="flex px-2 md:px-6 py-5 items-center rounded-[12px] border-[0.736px] border-[#143549] bg-[#00c2ff05] w-full">
                        <div className="flex items-center gap-3 md:gap-[17.659px] w-full">
                          <img
                            src={ReferralIcon}
                            alt="phantom-icon"
                            className="w-5 h-5 md:w-10 md:h-10"
                          />
                          <input
                            type="text"
                            className="focus:outline-0 flex h-10 md:h-[50px] px-4 items-center gap-[10px] rounded-xl border border-[#143549] text-[#FCFCFC] leading-5 w-full bg-transparent placeholder:text-[#FCFCFC] text-[12px] md:text-[18px] placeholder:opacity-[0.5] "
                            placeholder="Referral Code"
                            value={refCode}
                            onChange={(e) => setRefCode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={signupWithReferralCodeMutation.isLoading}
                    onClick={async () => {
                      if (
                        !connected ||
                        !userData?.id ||
                        !userData?.telegramId
                      ) {
                        return;
                      }
                      try {
                        if (!refCode) {
                          toggleModal();
                          navigate("/quests");

                          return;
                        }
                        const res =
                          await signupWithReferralCodeMutation.mutateAsync({
                            referralCode: refCode,
                            redirectUrl: `${window.location.origin}`,
                          });
                        if (res) {
                          window.open(res?.data, "_self");
                        }
                        setIsCreateOpenPoints(false);
                        navigate("/quests");
                      } catch (error: any) {
                        toast.error(error?.message);
                      }
                    }}
                    className={`w-full py-[10px] flex justify-center items-center rounded-[60px] border-[1.472px] border-[#00C2FF] bg-[#00C2FF] text-[#FFF]  font-medium leading-5 md:leading-10 text-[22px]  ${
                      connected && userData?.id && userData?.telegramId
                        ? "opacity-[1]"
                        : "opacity-[0.7]"
                    }`}
                  >
                    Continue
                  </button>
                </div>
              </div>
              <WalletConnectModal
                isCreateOpen={isCreateOpen}
                setIsCreateOpen={setIsCreateOpen}
                dataWallet={dataWallet}
                setWalletConnected={setWalletConnected}
              />
            </div>
          </Transition.Child>
        )}
      </Dialog>
    </Transition.Root>
  );
};

export default PointsModal;
