import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CalendarIco from "../assets/images/calendar.svg";
import ProjectIco1 from "../assets/images/project-logo1.svg";
import ProjectIco2 from "../assets/images/project-logo2.svg";
import ProjectIco3 from "../assets/images/project-logo3.svg";
import ArrowIco from "../assets/images/arrows.svg";
import { Link } from "react-router-dom";

function TopProject() {
  return (
    <div className="bg-gray-800">
      {/* <!-- Header Area --> */}
      <Header />

      {/* <!-- Top Project Area --> */}
      <section className="top-project-area bg-cover bg-no-repeat bg-center py-14 md:py-36">
        <div className="container">
          <div className="grid items-center md:grid-cols-12 gap-6 mb-10 md:mb-32">
            <div className="md:col-span-7 lg:col-span-9">
              <div className="max-w-2xl">
                <h4 className="text-4xl text-gray-50 font-bold mb-3">
                  Top projects
                </h4>
                <p className="text-base text-gray-400">
                  Lorem ipsum dolor sit amet consectetur. Vel lobortis interdum
                  nulla sagittis neque est. Interdum lorem cras sem consectetur.
                </p>
              </div>
            </div>
            <div className="md:col-span-5 lg:col-span-3">
              <div className="pointer inline-flex items-center gap-2 bg-gray-600 hover:bg-blue-800/20 rounded-xl text-sm text-gray-50 px-6 py-4 transition">
                25-01-2024
                <span className="border-l border-gray-300 pl-3">
                  <img src={CalendarIco} alt="" />{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-cols-12 gap-6 mb-6">
            <div
              className="sm:col-span-6 md:col-span-4 inactive"
              id="crypto-quest"
            >
              <div className="project-box text-center max-w-64 mx-auto md:mt-32">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectIco1}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  CryptoQuest Hub
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Navigate diverse cryptocurrency quests for rewards and engage
                  in social tasks on this dynamic platform.
                </p>
                <Link
                  className="invisible inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  id="crypto-quest-arrows"
                  to="/user-quest"
                >
                  <img src={ArrowIco} alt="" />
                </Link>
              </div>
            </div>
            <div
              className="sm:col-span-6 md:col-span-4 inactive"
              id="token-trail"
            >
              <div className="project-box text-center max-w-64 mx-auto">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectIco2}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  TokenTrail Network
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Follow the trail of tokens with quests, referrals, and
                  engaging activities on this crypto-centric network.
                </p>
                <Link
                  className="invisible inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  id="token-trail-arrows"
                  to="/user-quest"
                >
                  <img src={ArrowIco} alt="" />
                </Link>
              </div>
            </div>
            <div
              className="sm:col-span-6 md:col-span-4 inactive"
              id="earn-vortex"
            >
              <div className="project-box text-center max-w-64 mx-auto md:mt-32">
                <img
                  className="inline-block object-contain w-14 h-14 mx-auto mb-6"
                  src={ProjectIco3}
                  alt=""
                />
                <h4 className="text-xl text-gray-50 font-bold mb-4">
                  EarnVortex Platform
                </h4>
                <p className="text-base text-gray-400 mb-6">
                  Get pulled into the vortex of earnings with unique tasks,
                  referrals, and projects in the cryptocurrency space.
                </p>
                <Link
                  className="invisible inline-flex items-center gap-2 hover:bg-blue-800/20 border border-gray-400 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                  id="earn-vortex-arrows"
                  to="/user-quest"
                >
                  <img src={ArrowIco} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Footer Area --> */}
      <Footer />
    </div>
  );
}

export default TopProject;
