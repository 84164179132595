import { QueryCache } from "@tanstack/query-core";

const apiURL = "https://stg-api.supido.xyz";
// const apiURL = "http://192.168.18.21:3002";

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: Partial<RequestInit> & { data?: unknown } = {}
) {
  const token = localStorage.getItem("token");
  const config: RequestInit = {
    method: data ? "POST" : "GET",
    body: data instanceof FormData ? data : JSON.stringify(data),
    headers: {
      Authorization: token ? `${token}` : "",
      ...(!data || data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();

      if (response.status === 401) {
        queryCache.clear();
        return await Promise.reject({ message: "Please re-authenticate." });
      }
      if (response.ok) {
        return data;
      } else {
        return await Promise.reject(data);
      }
    });
}

export { client };
