import React from "react";
import CreateProject from "../components/CreateProject";
import Logo from "../assets/images/Logo.png";
import ProfileImgIco1 from "../assets/images/profile-img.jpg";
import ProfileImgIco from "../assets/images/profile-img2.jpg";
import TopProIco from "../assets/images/view-project.svg";
import MenuIco from "../assets/images/menu.svg";
import MessageIco from "../assets/images/message.svg";
import ProfileImage from "../assets/images/profile-img.jpg";
import FollowersIco from "../assets/images/followers.svg";
import DiamondIco from "../assets/images/diamond.svg";
import TwitterIco from "../assets/images/twitter.svg";
import EmailIco from "../assets/images/email.svg";
import TwitterProfilesIco from "../assets/images/twitter-profiles.png";
import LoveIco from "../assets/images/love.svg";
import CommentIco from "../assets/images/comment.svg";
import RetweetIco from "../assets/images/retweet-back.svg";
import LockIco from "../assets/images/lock.svg";
import PlusIco from "../assets/images/plus.svg";
import { Link } from "react-router-dom";

function UserQuest() {
  return (
    <div className="bg-gray-800">
      <CreateProject />
      {/* <!-- follow Us Area --> */}
      <div
        id="followus-form"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-3">Follow Us</h1>
        <p className="text-sm text-gray-400 mb-6">
        Let's trend your project together with SUPIDO
        </p>
        <form action="#">
          {/* <!-- Upload --> */}
          <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-6">
              <img
                className="w-16 h-16 object-cover rounded-xl"
                src={ProfileImgIco1}
                alt=""
              />
              <div>
                <h4 className="text-xl text-gray-50 font-bold mb-1">
                  User Name
                </h4>
                <p className="text-base text-gray-200">@user-name</p>
              </div>
            </div>
            <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={FollowersIco} alt="" />
              Follow
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>

      {/* <!-- Tweet Area --> */}
      <div id="tweet-form" className="madal-dialog zoom-anim-dialog mfp-hide">
        <h1 className="text-xl text-gray-50 font-bold mb-6">Tweet</h1>
        <form action="#">
          {/* <!-- Upload --> */}
          <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-6">
              <img
                className="w-16 h-16 object-cover rounded-xl"
                src={ProfileImage}
                alt=""
              />
              <div>
                <h4 className="text-xl text-gray-50 font-bold mb-1">
                  User Name
                </h4>
                <p className="text-base text-gray-200">@user-name</p>
              </div>
            </div>
            <div className=" pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={FollowersIco} alt="" />
              Follow
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="pointer flex items-center justify-center gap-2 text-base text-gray-200 border border-gray-500 hover:border-blue-800 rounded-full py-2 px-4 transition">
            <img className="svg-white-color" src={TwitterIco} alt="" /> Tweet
            about this
          </div>
          <p className="text-base text-gray-400 my-4">
            Be sure to include these words in your tweet.
          </p>
          <ul className="flex flex-wrap items-center gap-4 mb-6">
            <li>
              <div className="pointer inline-flex items-center gap-2 bg-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
                Word One
              </div>
            </li>
            <li>
              <div className="pointer inline-flex items-center gap-2 bg-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
                Word Two
              </div>
            </li>
          </ul>
          <h4 className="text-xl text-blue-800 mb-4">Link to your tweet</h4>
          <div className="form-field flex items-center gap-4 border border-gray-300 rounded-xl px-5 mb-6">
            <span className="h-6 py-2 m-auto z-1">
              <img className="block -mt-1" src={EmailIco} alt="" />
            </span>
            <input
              id="bid-1"
              placeholder="www.twitter.com/link21873"
              className="relative cursor-pointer bg-transparent text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 py-3 z-2"
            />
          </div>

          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>

      {/* <!-- Like Tweet Area --> */}
      <div
        id="liketweet-form"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-3">Like Tweet</h1>
        <p className="text-sm text-gray-400 mb-6">
          Engage with our tweet! Like for instant rewards and become a part of
          the $SUPIDO community buzz.
        </p>
        <form action="#">
          {/* <!-- Upload --> */}
          <img
            className="max-w-80 w-full mx-auto mb-6"
            src={TwitterProfilesIco}
            alt=""
          />
          <div className="flex items-center justify-between gap-4 mb-6">
            <p className="text-base text-gray-400">Like this tweet</p>
            <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={LoveIco} alt="" />
              Like
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>

      {/* <!-- Reply Tweet Area --> */}
      <div
        id="replytweet-form"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-3">Reply Tweet</h1>
        <p className="text-sm text-gray-400 mb-6">
          Join the conversation! Reply to this tweet with your thoughts on
          $SUPIDO and earn rewards.
        </p>
        <form action="#">
          {/* <!-- Upload --> */}
          <img
            className="max-w-80 w-full mx-auto mb-6"
            src={TwitterProfilesIco}
            alt=""
          />
          <div className="flex items-center justify-between gap-4 mb-6">
            <p className="text-base text-gray-400">Reply this tweet</p>
            <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={CommentIco} alt="" />
              Reply
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>
      {/* <!-- Retweet Tweet Area --> */}
      <div id="retweet-form" className="madal-dialog zoom-anim-dialog mfp-hide">
        <h1 className="text-xl text-gray-50 font-bold mb-3">Retweet</h1>
        <p className="text-sm text-gray-400 mb-6">
          Spread the excitement! Retweet this post with $SUPIDO to unlock
          exclusive rewards and amplify the crypto buzz.
        </p>
        <form action="#">
          {/* <!-- Upload --> */}
          <img
            className="max-w-80 w-full mx-auto mb-6"
            src={TwitterProfilesIco}
            alt=""
          />
          <div className="flex items-center justify-between gap-4 mb-6">
            <p className="text-base text-gray-400">Retweet this tweet</p>
            <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={RetweetIco} alt="" />
              Retweet
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>

      {/* <!-- Retweet replay Tweet Area --> */}
      <div
        id="LikeReplyRetweet-form"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-3">
          Like, Reply & Retweet
        </h1>
        <p className="text-sm text-gray-400 mb-6">
          Description dolor sit amet consectetur. Orci volutpat fusce non
          egestas facilisis mauris. Elementum integer nulla fringilla mattis ac
          quam sit.
        </p>
        <form action="#">
          {/* <!-- Upload --> */}
          <img
            className="max-w-80 w-full mx-auto mb-6"
            src={TwitterProfilesIco}
            alt=""
          />
          <div className="flex items-center justify-between gap-4 mb-4">
            <p className="text-base text-gray-400">Retweet this tweet</p>
            <div className="pointer w-32	inline-flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={LoveIco} alt="" />
              Like
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 mb-4">
            <p className="text-base text-gray-400">Reply this tweet</p>
            <div className=" pointer w-32	inline-flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={CommentIco} alt="" />
              Reply
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 mb-6">
            <p className="text-base text-gray-400">Retweet this tweet</p>
            <div className="pointer w-32	inline-flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
              <img src={RetweetIco} alt="" />
              Retweet
            </div>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 bg-gray-800 rounded-xl py-3 px-5 mb-6">
            <div>
              <p className="text-base text-gray-200">Reward</p>
              <h3 className="text-xl text-gray-100">102 $SUPIDO</h3>
            </div>
            <img src={DiamondIco} alt="tile" />
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-5 py-4 transition">
              Cancel
            </button>
            <button className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border hover:border-blue-800 border-gray-400 rounded-full text-sm hover:text-blue-800 text-gray-400 px-5 py-4 transition">
              Claim Reward
            </button>
          </div>
        </form>
      </div>

      {/* <!-- Header Area --> */}
      <header className="header-area py-5 md:py-10 z-50 relative">
        <div className="container">
          <div className="flex items-center justify-between gap-6">
            <Link to="/" className="inline-block">
              <img className="h-[28px] md:h-[50px]" src={Logo} alt="" />
            </Link>
            <nav className="header-navbar hidden md:block">
              <ul className="flex flex-col md:flex-row items-center justify-end gap-6">
                <li className="md:order-3">
                  <Link to="/dashboard">
                    <img
                      className="w-11 h-11 object-cover rounded-full"
                      src={ProfileImgIco1}
                      alt=""
                    />
                  </Link>
                </li>
                <li className="md:order-1">
                  <div className="pointer popup-with-zoom-anim text-sm text-gray-100 hover:text-blue-800 transition">
                    Create project
                  </div>
                </li>
                <li className="md:order-2">
                  <Link
                    className="inline-flex items-center gap-2 border border-gray-50 hover:border-blue-800 rounded-full text-sm text-gray-50 px-5 py-2.5 transition"
                    to="/top-project"
                  >
                    <img src={TopProIco} alt="" /> View all projects
                  </Link>
                </li>
              </ul>
            </nav>
            <button className="header-toggler inline-flex md:hidden items-center gap-2 border border-gray-500 rounded-full px-5 py-2.5 transition">
              <img className="h-5" src={MenuIco} alt="" />
            </button>
          </div>
        </div>
      </header>

      {/* <!-- Dashboard Area --> */}
      <section className="dashboard-area py-10 md:pb-36">
        <div className="container">
          <div className="grid items-center md:grid-cols-12 gap-6 mb-8 md:mb-14">
            <div className="md:col-span-7 lg:col-span-8">
              <div className="flex gap-6">
                <img
                  className="w-20 h-20 object-cover rounded-xl"
                  src={ProfileImgIco}
                  alt=""
                />
                <div>
                  <h4 className="text-xl text-gray-50 font-bold mb-2">
                    CryptoPulse Rewards
                  </h4>
                  <p className="text-base text-gray-400">
                    Immerse in CryptoPulse, complete quests like "Moonlight
                    Maven" and earn $SUPIDO rewards for engaging in
                    time-specific tasks.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:col-span-5 lg:col-span-4">
              <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
                <img src={MessageIco} alt="" /> Invite Friend
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-12 gap-6 mb-12 md:mb-20">
            <div className="md:col-span-6 lg:col-span-4">
              <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                <h3 className="text-xl text-gray-100 font-bold mb-6">
                  Twitter Trailblazer
                </h3>
                <p className="text-base text-gray-400 mb-10">
                  Blaze a trail on Twitter by engaging with $SUPIDO content and
                  earn rewards.
                </p>
                <ul className="flex flex-wrap items-center gap-2 mb-8">
                  <li>
                    <div className="div popup-with-zoom-anim text-base text-blue-800">
                      Follow
                    </div>
                  </li>
                </ul>
                <ul className="flex flex-wrap items-center gap-2">
                  <li>
                    <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                      10 $SUPIDO
                    </div>
                  </li>
                  <li>
                    <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                      Once
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                <h3 className="text-xl text-gray-100 font-bold mb-6">
                  Social Surge
                </h3>
                <p className="text-base text-gray-400 mb-10">
                  Surge ahead in social interactions—retweet, like, and comment
                  for $SUPIDO rewards.
                </p>
                <ul className="flex flex-wrap items-center gap-2 mb-8">
                  <li>
                    <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                      Tweet
                    </div>
                  </li>
                </ul>
                <ul className="flex flex-wrap items-center gap-2">
                  <li>
                    <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                      10 $SUPIDO
                    </div>
                  </li>
                  <li>
                    <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                      Timed
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <a className="popup-with-zoom-anim" href="#LikeReplyRetweet-form">
                <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                  <h3 className="text-xl text-gray-100 font-bold mb-6">
                    HashTag Hustle
                  </h3>
                  <p className="text-base text-gray-400 mb-10">
                    Hustle with targeted tweets using $SUPIDO hashtags for.
                  </p>
                  <ul className="flex flex-wrap items-center gap-2 mb-8">
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Like
                      </div>
                    </li>
                    <li>
                      <span className="text-base text-blue-800">-</span>
                    </li>
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Reply
                      </div>
                    </li>
                    <li>
                      <span className="text-base text-blue-800">-</span>
                    </li>
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Retweet
                      </div>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap items-center gap-2">
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        10 $SUPIDO
                      </div>
                    </li>
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        Infinite
                      </div>
                    </li>
                  </ul>
                </div>
              </a>
            </div>

            <div className="md:col-span-6 lg:col-span-4">
              <div className="relative">
                <div className="dashboard-disable pointer-events-none absolute top-0 left-0 z-50">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                    <div className="pointer signin-btn popup-with-zoom-anim inline-flex items-center gap-2 hover:bg-blue-800/20 border border-blue-800 rounded-full text-sm text-blue-800 px-5 py-2.5 transition">
                      Opens in 3 days <img src={LockIco} alt="" />
                    </div>
                  </div>
                </div>
                <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                  <h3 className="text-xl text-gray-100 font-bold mb-6">
                    Twitter Trailblazer
                  </h3>
                  <p className="text-base text-gray-400 mb-10">
                    Blaze a trail on Twitter by engaging with $SUPIDO content
                    and earn rewards.
                  </p>
                  <ul className="flex flex-wrap items-center gap-2 mb-8">
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Follow
                      </div>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap items-center gap-2">
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        10 $SUPIDO
                      </div>
                    </li>
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        Once
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <div className="relative">
                <div className="dashboard-disable pointer-events-none absolute top-0 left-0 z-50">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                    <div className="pointer signin-btn popup-with-zoom-anim inline-flex items-center gap-2 hover:bg-blue-800/20 border border-blue-800 rounded-full text-sm text-blue-800 px-5 py-2.5 transition">
                      Complete task to open
                      <img src={LockIco} alt="" />
                    </div>
                  </div>
                </div>
                <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                  <h3 className="text-xl text-gray-100 font-bold mb-6">
                    Twitter Trailblazer
                  </h3>
                  <p className="text-base text-gray-400 mb-10">
                    Blaze a trail on Twitter by engaging with $SUPIDO content
                    and earn rewards.
                  </p>
                  <ul className="flex flex-wrap items-center gap-2 mb-8">
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Follow
                      </div>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap items-center gap-2">
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        10 $SUPIDO
                      </div>
                    </li>
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        Once
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-4">
              <div className="relative">
                <div className="dashboard-disable comming absolute top-0 left-0 z-50">
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                    <Link
                      to="/create-quest"
                      className="ith-zoom-anim inline-flex items-center gap-2 hover:bg-blue-800/20 border border-blue-800 rounded-full text-sm text-blue-800 px-5 py-2.5 transition"
                    >
                      <img src={PlusIco} alt="" /> Create new quest
                    </Link>
                  </div>
                </div>
                <div className="dashboard-box border border-gray-500 hover:border-blue-800 transition p-4">
                  <h3 className="text-xl text-gray-100 font-bold mb-6">
                    Twitter Trailblazer
                  </h3>
                  <p className="text-base text-gray-400 mb-10">
                    Blaze a trail on Twitter by engaging with $SUPIDO content
                    and earn rewards.
                  </p>
                  <ul className="flex flex-wrap items-center gap-2 mb-8">
                    <li>
                      <div className="pointer popup-with-zoom-anim text-base text-blue-800">
                        Follow
                      </div>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap items-center gap-2">
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        10 $SUPIDO
                      </div>
                    </li>
                    <li>
                      <div className="pointer flex items-center gap-2 text-base text-gray-200 border border-gray-500 rounded-full py-1 px-4">
                        Once
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserQuest;
