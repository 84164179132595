import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackArrowIco from "../assets/images/backArrowIcon.svg";
import SolanaIco from "../assets/images/solanaIcon.svg";
import TokenomicsChart from "../assets/images/tokenomics-chart.png";
import ChartBg from "../assets/images/tokenomics-chart-bg.png";
import AllocationBg from "../assets/images/allocationCardBG.svg";
import { Link } from "react-router-dom";

function Tokenomics() {
  return (
    <div className="bg-[#070707]">
      {/* <!-- Header Area --> */}
      <Header />

      <div className="container mt-[26px] font-[Barlow]">
        <Link to="/" id="back-link">
          <div className="flex items-center gap-[12px] md:gap-[20px]">
            <img
              src={BackArrowIco}
              alt="back arrow"
              className="inline-block h-[32px] w-[32px] md:h-[50px] md:w-[50px]"
            />
            <h3 className="text-[#FCFCFC] font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[38px]">
              Tokenomics
            </h3>
          </div>
        </Link>
      </div>
      <section>
        <div className="container font-[Barlow] text-[#ABBBC2] font-[300] text-[14px] md:text-[20px] pt-[24px] md:pt-[32px] md:px-[100px]">
          <div>
            <p>
              <span className="font-[700]">$SUPIDO</span> utilizes the latest
              Token 2022 standard on{" "}
              <img src={SolanaIco} className="inline-block mb-[2px]" alt="" />
              <span className="font-[700] bg-gradient-to-r from-purple-700 via-purple-500 to-teal-400 text-transparent bg-clip-text me-[-8px]">
                Solana
              </span>
            </p>
            <p className="mt-[14px] md:mt-[40px]">
              <span className="font-[700]">$SUPIDO</span> is the first SPL token
              to auto-reward holders directly into their wallet. No need to
              claim rewards, you can monitor how much you’ve earned via our
              dapp.
            </p>
            <p className="mt-[16px]">
              <span className="font-[700] text-[#00C2FF]">$SUPIDO</span> is set
              to launch with a comprehensive tokenomics structure designed to
              balance the needs of the community, team and the wider ecosystem.
              Our total token supply is capped at
              <span className="font-[700] text-[#00C2FF]">500 million</span>,
              with a strategic allocation to ensure long-term sustainablility
              and growth. Here’s how the allocation breaks down:
            </p>
            <div
              className="mt-[24px] md:mt-[55px] pb-[55px]"
              style={{
                backgroundImage: `url(${ChartBg})`,
                backgroundSize: "cover",
              }}
            >
              <h5 className="text-[20px] md:text-[36px] text-[#FCFCFC] font-[900] md:font-[700]">
                SUPIDO Allocation:
              </h5>
              <div className="flex justify-between flex-col lg:flex-row gap-[20px] mt-[20px] md:mt-[55px] items-center">
                <div className="md:max-w-[70%]">
                  <img src={TokenomicsChart} alt="chart" />
                </div>
                <div className="text-white text-[16px] font-[300]">
                  <div
                    className="w-full bg-right-bottom bg-no-repeat border-l-[4px] p-[20px] tracking-tight border-[#00C2FF] rounded-[12px] bg-[#00C2FF1A]"
                    style={{
                      backgroundImage: `url(${AllocationBg})`,
                    }}
                  >
                    <h3 className="font-[700] text-[16px] md:text-[24px]">
                      3% Operation
                    </h3>
                    <p className="mt-[12px] text-[12px] md:text-[16px]">
                      To keep the platform up and running.
                    </p>
                  </div>
                  <div className="flex lg:flex-col mt-[18px] gap-[12px] lg:gap-[18px]">
                    <div
                      className="w-full bg-right-bottom bg-no-repeat border-l-[4px] p-[20px] tracking-tight border-[#00C2FF] rounded-[12px] bg-[#00C2FF1A]"
                      style={{
                        backgroundImage: `url(${AllocationBg})`,
                      }}
                    >
                      <h3 className="font-[700] text-[16px] md:text-[24px]">
                        6% Tax
                      </h3>
                      <p className="mt-[12px] text-[12px] md:text-[16px]">
                        Tax collections are auto swapped into buying
                        <span className="font-[700]">$SUPIDO</span> and
                        distributed amongst all holders.
                      </p>
                    </div>
                    <div
                      className="w-full bg-right-bottom bg-no-repeat border-l-[4px] p-[20px] tracking-tight border-[#00C2FF] rounded-[12px] bg-[#00C2FF1A]"
                      style={{
                        backgroundImage: `url(${AllocationBg})`,
                      }}
                    >
                      <h3 className="font-[700] text-[16px] md:text-[24px]">
                        3% Operation
                      </h3>
                      <p className="mt-[12px] text-[12px] md:text-[16px]">
                        Hold as many <span className="font-[700]">$SUPIDO</span>{" "}
                        as you can to get higher return on
                        <span className="font-[700]">$SUPIDO</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer Area --> */}
      <Footer />
    </div>
  );
}

export default Tokenomics;
