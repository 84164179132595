import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetWallet = () =>
  useQuery<any, Error>(
    ["getWallet"],
    async () => await client(`wallet/getWallet`)
  );

export const useGetAllBoosters = () =>
  useQuery<any, Error>(
    ["getAllBoosters"],
    async () => await client(`wallet/getAllBoosters`)
  );

export const useGetBoosterPurchaseWallet = () =>
  useQuery<any, Error>(
    ["getBoosterPurchaseWallet"],
    async () => await client(`wallet/getBoosterPurchaseWallet`)
  );

/*
  export const useCreateQuest = () =>
  useMutation<any, Error, any>(async (payload: any) => {
    const res = await client("quest/createQuest", {
      method: "POST", // Explicitly set the request method to "POST"
      data: payload,
    });
    return res;
  });

  */

export const usePurchaseBooster = () => {
  const queryClient: any = useQueryClient();
  return useMutation<
    any,
    Error,
    { boosterId: string; transactionHash: string }
  >(
    async (payload) => {
      const res = await client(
        `wallet/purchaseBooster?boosterId=${payload.boosterId}`,
        {
          method: "POST",
          data: { transactionHash: payload.transactionHash },
        }
      );
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserActiveBooster");
      },
    }
  );
};

export const useWithdrawReward = () => {
  return useMutation<any, Error>(async () => {
    const res = await client(`wallet/withdrawReward`, {
      method: "POST",
    });
    return res;
  });
};

export const useTopLeaderboard = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) =>
  useQuery<any, Error>(
    ["topLeaderboard", { limit, offset }],
    async () =>
      await client(`wallet/topLeaderboard?limit=${limit}&offset=${offset}`)
  );

export const useGetUserActiveBooster = () =>
  useQuery<any, Error>(
    ["getUserActiveBooster"],
    async () => await client(`auth/getUserActiveBooster`)
  );
