import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import FollowersIco from "../../assets/images/followers.svg";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useFollowUser } from "../../utils/quest.api";

const FollowModal = ({
  isCreateOpen,
  toggleModal,
  data,
  createTask,
  isPreview,
}: any) => {
  const followUserMutation = useFollowUser();
  const [following, setFollowing] = useState("");

  return (
    <Transition.Root show={isCreateOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center backdrop-filter backdrop-blur-[2px]"
        onClose={() => toggleModal("Follow")}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50  backdrop-blur-2xl" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative w-[96%] max-w-md ">
            <button
              className="absolute top-0 right-0 focus:outline-none w-[44px] h-[44px]"
              onClick={() => toggleModal("Follow")}
            >
              <p className="text-[24px] text-white opacity-[0.65]">x</p>
            </button>
            <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
              <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                {data?.title}
              </h1>
              {!isPreview && (
                <p className="text-sm text-[#A6A6A6] mb-6">
                  Let's trend your project together with SUPIDO
                </p>
              )}
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    await createTask.mutateAsync({
                      questId: data?.id,
                      linkToYourTweet: "",
                    });
                    toast.success("Task completed successfully!!!");
                    toggleModal("Follow");
                  } catch (error: any) {
                    toast.error(error?.message);
                  }
                }}
              >
                {/* <!-- Upload --> */}

                <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
                  {data?.handle && (
                    <div className="flex items-center gap-6">
                      <div className="w-16 h-16 flex items-center justify-center rounded-xl bg-[#00C2FF] text-white text-3xl font-bold">
                        {data?.handle?.[0]?.toUpperCase()}
                      </div>

                      <div>
                        <h4 className="text-xl text-[#F1F1F1] font-bold mb-1">
                          {data?.handle}
                        </h4>
                        <p className="text-base text-gray-200">
                          @{data?.handle}
                        </p>
                      </div>
                    </div>
                  )}
                  {!isPreview && (
                    <div
                      className="cursor-pointer inline-flex items-center gap-2 border border-[#F1F1F1] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] px-4 py-2 transition"
                      onClick={async () => {
                        try {
                          const res = await followUserMutation.mutateAsync(
                            data?.handle
                          );
                          setFollowing(res?.data?.following);
                          toast.success("Followed Successfully!");
                        } catch (error: any) {
                          toast.error(error?.message);
                        }
                      }}
                    >
                      <img src={FollowersIco} alt="followers" />
                      {following ? "Following" : " Follow"}
                    </div>
                  )}
                </div>

                {/* <!-- Feild --> */}
                <h1 className="text-xl text-[#F1F1F1] font-bold mb-3">
                  How to complete this task:
                </h1>
                <div className="text-[#A6A6A6] text-[14px] mt-2 mb-6">
                  <p>
                    1. Click on the follow button above to follow this account.
                  </p>
                  <p>2. Click on the button below to verify the task.</p>
                </div>
                {/* <!-- Feild --> */}
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    disabled={createTask?.isLoading}
                    onClick={() => toggleModal("Follow")}
                    className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-[#4D4D4D] hover:border-[#00C2FF] rounded-full text-sm text-[#D3D3D3] hover:text-[#00C2FF] px-5 py-4 transition"
                  >
                    Cancel
                  </button>
                  {!isPreview && (
                    <button
                      type="submit"
                      disabled={createTask?.isLoading}
                      className="inline-flex w-full items-center justify-center gap-2 bg-[#00C2FF] hover:border-[#00C2FF] border-[#A6A6A6] rounded-full text-sm text-[#FFFFFF] px-5 py-4 transition"
                    >
                      {createTask?.isLoading ? (
                        <ClipLoader color="#FFFFFF" size={20} />
                      ) : (
                        "Verify Task"
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default FollowModal;
