import React from "react";
import Logo from "../assets/images/Logo.png";
import ProfileImgIco from "../assets/images/profile-img2.jpg";
import TopProIco from "../assets/images/view-project.svg";
import MenuIco from "../assets/images/menu.svg";
import MessageIco from "../assets/images/message.svg";
import PlusIco from "../assets/images/plus.svg";
import NoAvailableIco from "../assets/images/no-available.png";
import CreateProject from "../components/CreateProject";
import { Link } from "react-router-dom";

function NoAvailable() {
  return (
    <div className="bg-gray-800">
      <CreateProject />
      {/* <!-- Header Area --> */}
      <header className="header-area py-5 md:py-10 z-50 relative">
        <div className="container">
          <div className="flex items-center justify-between gap-6">
            <Link to="/" className="inline-block">
              <img className="h-[28px] md:h-[50px]" src={Logo} alt="" />
            </Link>
            <nav className="header-navbar hidden md:block">
              <ul className="flex flex-col md:flex-row items-center justify-end gap-6">
                <li className="md:order-3">
                  <Link to="/dashboard">
                    <img
                      className="w-11 h-11 object-cover rounded-full"
                      src={ProfileImgIco}
                      alt=""
                    />
                  </Link>
                </li>
                <li className="md:order-1">
                  <a
                    className="popup-with-zoom-anim text-sm text-gray-100 hover:text-blue-800 transition"
                    href="#create-project"
                  >
                    Create project
                  </a>
                </li>
                <li className="md:order-2">
                  <Link
                    className="inline-flex items-center gap-2 border border-gray-50 hover:border-blue-800 rounded-full text-sm text-gray-50 px-5 py-2.5 transition"
                    to="/top-project"
                  >
                    <img src={TopProIco} alt="" /> View all projects
                  </Link>
                </li>
              </ul>
            </nav>
            <button className="header-toggler inline-flex md:hidden items-center gap-2 border border-gray-500 rounded-full px-5 py-2.5 transition">
              <img className="h-5" src={MenuIco} alt="" />
            </button>
          </div>
        </div>
      </header>

      {/* <!-- Dashboard Area --> */}
      <section className="dashboard-area py-10 md:pb-36">
        <div className="container">
          <div className="grid items-center md:grid-cols-12 gap-6 mb-10 md:mb-32">
            <div className="md:col-span-7 lg:col-span-8">
              <div className="flex gap-6 lg:ml-24">
                <div>
                  <h4 className="text-xl text-gray-50 font-bold mb-2">
                    CryptoPulse Rewards
                  </h4>
                  <p className="text-base text-gray-400">
                    Immerse in CryptoPulse, complete quests like "Moonlight
                    Maven" and earn $SUPIDO rewards for engaging in
                    time-specific tasks.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:col-span-5 lg:col-span-4">
              <div className="pointer inline-flex items-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-50 px-4 py-2 transition">
                <img src={MessageIco} alt="" /> Invite Friend
              </div>
            </div>
          </div>

          <div className="text-center">
            <img className="inline-block mb-10" src={NoAvailableIco} alt="" />
            <h4 className="text-xl text-gray-50 font-bold mb-4">
              No Quest Available
            </h4>
            <p className="text-base text-gray-400 mb-6">
              Currently, there are no quests available. Dive into projects and
              discover opportunities to unlock rewards.
            </p>
            <Link
              className="inline-flex items-center gap-2 hover:bg-blue-800/20 border border-blue-800 rounded-full text-sm text-blue-800 px-12 py-4 transition mb-8"
              to="/create-quest"
            >
              <img src={PlusIco} alt="" />
              Create new quest
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NoAvailable;
