import React, { useState } from "react";
import CloseIco from "../assets/images/close.svg";
import UpIco from "../assets/images/up.svg";
import DownIco from "../assets/images/down.svg";
import EmailIco from "../assets/images/email.svg";
import QuestionIco from "../assets/images/question.svg";
// import CalendarIco from "../assets/images/calendar.svg";
// import AngleDownIco from "../assets/images/angle-down.svg";
import { toast } from "react-toastify";
import { useCreateQuest } from "../utils/quest.api";
import { ClipLoader } from "react-spinners";
import Header from "../components/Header";
import FollowModal from "../components/modals/FollowModal";
import TweetModal from "../components/modals/TweetModal";
import LikeModal from "../components/modals/LikeModal";
import ReplyModal from "../components/modals/ReplyModal";
import RetweetModal from "../components/modals/RetweetModal";
import { useNavigate } from "react-router-dom";
import QuoteModal from "../components/modals/QuoteModal";

function CreateQuest() {
  // const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState("Follow");
  const [selectedType, setSelectedType] = useState("OneTime");
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const [modalName, setModalName] = useState("Follow");
  const [modalData, setModalData] = useState({});
  const [formData, setFormData] = useState({
    projectId: process.env.REACT_APP_PROJECT_ID,
    title: "FOLLOW ACCOUNT",
    action: "",
    type: "",
    handle: "",
    postUrl: "",
    words: [],
    defaultTweet: "",
    description: "",
    reward: 0,
    conditionTimestamp: "",
  });
  const [inputValue, setInputValue] = useState("");

  const createQuestMutation = useCreateQuest();

  const urlRegex = /(https?:\/\/[^\s]+)/;

  const handleAddWord = (e: any) => {
    e.preventDefault();
    if (inputValue.trim() !== "") {
      const trimmedValue = inputValue.trim();
      if (urlRegex.test(trimmedValue)) {
        setFormData((prevData: any) => ({
          ...prevData,
          words: [...prevData.words, trimmedValue],
        }));
      } else if (trimmedValue.startsWith("@") || trimmedValue.startsWith("#")) {
        setFormData((prevData: any) => ({
          ...prevData,
          words: [...prevData.words, trimmedValue],
        }));
      } else {
        toast.error(
          "Invalid word format. Words must start with '@', '#' or be a valid URL."
        );
        return;
      }
      setInputValue("");
    }
  };

  console.log(formData);

  const toggleModal = (modalName: any) => {
    setIsCreateOpen(!isCreateOpen);
    setModalName(modalName);
  };

  const handleRemoveWord = (wordToRemove: any) => {
    setFormData((prevData) => ({
      ...prevData,
      words: prevData.words.filter((word) => word !== wordToRemove),
    }));
  };

  return (
    <div className="bg-gray-800">
      {modalName === "Follow" && (
        <FollowModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}
      {/* <!-- Tweet Area --> */}
      {modalName === "Tweet" && (
        <TweetModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}
      {modalName === "Quote" && (
        <QuoteModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}
      {/* <!-- Like Tweet Area --> */}
      {modalName === "Like" && (
        <LikeModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}
      {/* <!-- Reply Tweet Area --> */}
      {modalName === "Reply" && (
        <ReplyModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}
      {/* <!-- Retweet Tweet Area --> */}

      {modalName === "Retweet" && (
        <RetweetModal
          isCreateOpen={isCreateOpen}
          toggleModal={toggleModal}
          data={modalData}
          isPreview={true}
          // createTask={createTaskMutation}
        />
      )}

      {/* <CreateProject isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      {/* <!-- Header Area --> */}
      {/* <header className="header-area py-5 md:py-10 z-50 relative">
        <div className="container">
          <div className="flex items-center justify-between gap-6">
            <Link to="/" className="inline-block">
              <img className="h-[28px] md:h-[50px]" src={Logo} alt="" />
            </Link>
            <nav className="header-navbar hidden md:block">
              <ul className="flex flex-col md:flex-row items-center justify-end gap-6">
                <li className="md:order-3">
                  <Link to="/dashboard">
                    <img
                      className="w-11 h-11 object-cover rounded-full"
                      src={userData?.profilePicUrl || ProfileIco}
                      alt=""
                    />
                  </Link>
                </li>
              
              </ul>
            </nav>
            <button className="header-toggler inline-flex md:hidden items-center gap-2 border border-gray-500 rounded-full px-5 py-2.5 transition">
              <img className="h-5" src={MenuIco} alt="" />
            </button>
          </div>
        </div>
      </header> */}
      <Header />

      {/* <!-- Dashboard Area --> */}
      <section className="dashboard-area py-10 pt-[110px] md:pb-36">
        <div className="container">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <ul className="flex items-center gap-2 mb-6">
              <li>
                <div className="cursor-pointer text-base text-gray-100 hover:text-blue-800 transition">
                  Projects
                </div>
              </li>
              <li>
                <span className="text-base text-gray-100">/</span>
              </li>
              <li>
                <div className="cursor-pointer text-base text-blue-800 transition">
                  Create New Quest
                </div>
              </li>
            </ul>
            <button
              // type="submit"
              disabled={createQuestMutation?.isLoading}
              onClick={(e) => {
                e.preventDefault();
                navigate("/delete-quest");
              }}
              className=" flex items-center justify-center self-end gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-12 py-4 transition mb-4"
            >
              {createQuestMutation?.isLoading ? (
                <ClipLoader color="#36d7b7" size={20} />
              ) : (
                "Delete Published Tasks"
              )}
            </button>
          </div>
          <div className="grid md:grid-cols-12 gap-6">
            <div className="md:col-span-7 lg:col-span-8">
              <form action="#">
                <div className="form-widget border border-gray-600 p-6 mb-6">
                  <h4 className="text-xl text-blue-800 mb-4">Task Title</h4>
                  <div className="form-field relative">
                    <input
                      value={formData?.title}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, title: e.target.value })
                      // }
                      id="bid-1"
                      readOnly
                      placeholder="Task Name"
                      className="relative cursor-pointer bg-transparent border border-gray-300 rounded-xl text-base text-gray-200 placeholder:text-gray-300 w-full focus:outline-0 py-3 px-5 z-2"
                    />
                  </div>
                </div>

                <div className="form-widget border border-gray-600 px-6 py-4 mb-6">
                  <h4 className="text-xl text-blue-800 mb-4">Task Type</h4>
                  <ul className="flex flex-wrap items-center gap-2 py-4">
                    <li>
                      <label
                        className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                          selectedType === "OneTime" ? "bg-[#00C2FF]" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          // className="rounded-3xl"
                          checked={selectedType === "OneTime"}
                          onChange={() => {
                            setSelectedType("OneTime");
                            setSelectedAction("Follow");
                            setModalName("Follow");
                            setFormData({
                              ...formData,
                              title: "FOLLOW ACCOUNT",
                            });
                          }}
                        />
                        <span>One Time</span>
                      </label>
                    </li>
                    <li>
                      <label
                        className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                          selectedType === "Infinite" ? "bg-[#00C2FF]" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedType === "Infinite"}
                          onChange={() => {
                            setSelectedType("Infinite");
                            setSelectedAction("Reply");
                            setModalName("Reply");
                            setFormData({
                              ...formData,
                              title: "RAIDING COMMENTS",
                            });
                          }}
                        />
                        <span>Infinite</span>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="form-widget border border-gray-600 px-6 py-4 mb-6">
                  <h4 className="text-xl text-blue-800 mb-4">Actions</h4>
                  <ul className="flex flex-wrap items-center gap-2 py-4">
                    {selectedType === "OneTime" && (
                      <li>
                        <label
                          className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                            selectedAction === "Follow" ? "bg-[#00C2FF]" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            // className="rounded-3xl"
                            checked={selectedAction === "Follow"}
                            onChange={() => {
                              setSelectedAction("Follow");
                              setModalName("Follow");
                              setFormData({
                                ...formData,
                                title: "FOLLOW ACCOUNT",
                              });
                            }}
                          />
                          <span>Follow</span>
                        </label>
                      </li>
                    )}
                    {selectedType === "OneTime" && (
                      <li>
                        <label
                          className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                            selectedAction === "Like" ? "bg-[#00C2FF]" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedAction === "Like"}
                            onChange={() => {
                              setSelectedAction("Like");
                              setModalName("Like");
                              setFormData({ ...formData, title: "LIKE TWEET" });
                            }}
                          />
                          <span>Like</span>
                        </label>
                      </li>
                    )}
                    {/* {selectedType === "Infinite" && ( */}
                    <li>
                      <label
                        className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                          selectedAction === "Reply" ? "bg-[#00C2FF]" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedAction === "Reply"}
                          onChange={() => {
                            setSelectedAction("Reply");
                            setModalName("Reply");
                            setFormData({
                              ...formData,
                              title: "RAIDING COMMENTS",
                            });
                          }}
                        />
                        <span>Reply</span>
                      </label>
                    </li>
                    {/* )} */}
                    {selectedType === "OneTime" && (
                      <li>
                        <label
                          className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                            selectedAction === "Retweet" ? "bg-[#00C2FF]" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedAction === "Retweet"}
                            onChange={() => {
                              setSelectedAction("Retweet");
                              setModalName("Retweet");
                              setFormData({ ...formData, title: "RETWEET" });
                            }}
                          />
                          <span>Retweet</span>
                        </label>
                      </li>
                    )}
                    {selectedType === "Infinite" && (
                      <li>
                        <label
                          className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                            selectedAction === "Tweet" ? "bg-[#00C2FF]" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedAction === "Tweet"}
                            onChange={() => {
                              setSelectedAction("Tweet");
                              setModalName("Tweet");
                              setFormData({
                                ...formData,
                                title: "POST TWEET",
                              });
                            }}
                          />
                          <span>Tweet</span>
                        </label>
                      </li>
                    )}
                    {selectedType === "OneTime" && (
                      <li>
                        <label
                          className={`form-checkbox flex items-center text-sm text-gray-100 rounded-3xl ${
                            selectedAction === "Quote" ? "bg-[#00C2FF]" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedAction === "Quote"}
                            onChange={() => {
                              setSelectedAction("Quote");
                              setModalName("Quote");
                              setFormData({
                                ...formData,
                                title: "QUOTE TWEET",
                              });
                            }}
                          />
                          <span>Quote</span>
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                {/* <!-- Form Widget Area --> */}
                {selectedAction === "Follow" && (
                  <div className="form-widget follow-target tweet-target border border-gray-600 px-6 py-4 mb-6">
                    <h4 className="text-xl text-blue-800 mb-2">
                      Twitter Handle
                    </h4>
                    <div className="form-field flex items-center gap-4 border border-gray-300 rounded-xl px-5 mb-4">
                      <span className="h-6 border-r border-gray-500 pr-3 py-2 m-auto z-1">
                        <img className="block -mt-1" src={EmailIco} alt="" />
                      </span>
                      <input
                        value={formData?.handle}
                        onChange={(e) =>
                          setFormData({ ...formData, handle: e.target.value })
                        }
                        id="bid-1"
                        placeholder="turbo-buzz"
                        className="relative cursor-pointer bg-transparent text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 py-3 z-2"
                      />
                    </div>
                    <p className="text-xs text-gray-400">
                      Enter the user name of twitter account to follow.
                    </p>
                  </div>
                )}
                {/* <!-- Form Widget Area --> */}
                {(selectedAction === "Like" ||
                  selectedAction === "Reply" ||
                  selectedAction === "Quote" ||
                  selectedAction === "Retweet") && (
                  <div className="form-widget like-target reply-target retweet-target border border-gray-600 px-6 py-4 mb-6">
                    <h4 className="text-xl text-blue-800 mb-2">Twitter URL</h4>
                    <div className="form-field flex items-center gap-4 border border-gray-300 rounded-xl px-5 mb-4">
                      <span className="h-6 border-r border-gray-500 pr-3 py-2 m-auto z-1">
                        <img className="block -mt-1" src={EmailIco} alt="" />
                      </span>
                      <input
                        value={formData?.postUrl}
                        onChange={(e) =>
                          setFormData({ ...formData, postUrl: e.target.value })
                        }
                        id="bid-1"
                        placeholder="www.twitter.com/user-3452"
                        className="relative cursor-pointer bg-transparent text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 py-3 z-2"
                      />
                    </div>
                    <p className="text-xs text-gray-400">
                      Post to like, reply to & retweet
                    </p>
                  </div>
                )}
                {/* <!-- Form Widget Area --> */}
                {(selectedAction === "Tweet" || selectedAction === "Quote") && (
                  <div className="form-widget tweet-target border border-gray-600 px-6 py-4 mb-6">
                    <h4 className="text-xl text-blue-800 mb-2">
                      Words to include
                    </h4>
                    <p className="text-xs text-gray-400 mb-4">
                      Words they must include in their tweet start with @ for
                      mention, # hash for tag or put a URL to another tweet for
                      quote tweet.
                    </p>
                    <ul className="flex flex-wrap items-center gap-2 mb-6">
                      {formData.words.map((word, index) => (
                        <li key={index}>
                          <div className="cursor-pointer text-base text-gray-200 flex items-center gap-2">
                            {word}{" "}
                            <img
                              src={CloseIco}
                              alt="close icon"
                              onClick={() => handleRemoveWord(word)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="form-field flex items-center gap-4">
                      <input
                        id="bid-1"
                        placeholder="Add word"
                        className="relative bg-transparent border border-gray-300 rounded-xl text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 px-5 py-3 z-2"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <button
                        className="inline-flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-8 py-3 transition"
                        onClick={handleAddWord}
                        type="submit"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}
                {/* <!-- Form Widget Area --> */}
                {selectedAction === "Tweet" && (
                  <div className="form-widget tweet-target  border border-gray-600 px-6 py-4 mb-6">
                    <h4 className="text-xl text-blue-800 mb-2">
                      Default Tweet
                    </h4>
                    <div className="form-field relative mb-2">
                      <textarea
                        id="bid-1"
                        placeholder="I just love test"
                        rows={4}
                        value={formData?.defaultTweet}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            defaultTweet: e.target.value,
                          })
                        }
                        className="bg-transparent border border-gray-300 rounded-xl text-base text-gray-200 placeholder:text-gray-300 w-full focus:outline-0 py-3 px-5"
                      ></textarea>
                    </div>
                    <p className="text-xs text-gray-400">
                      Reply that we will prefill for the users
                    </p>
                  </div>
                )}
                {/* <!-- Form Widget Area --> */}
                {/* {selectedAction === "Reply" && (
                  <div className="form-widget reply-target border border-gray-600 px-6 py-4 mb-6">
                    <h4 className="text-xl text-blue-800 mb-2">
                      Default Reply
                    </h4>
                    <div className="form-field relative mb-2">
                      <textarea
                        id="bid-1"
                        placeholder="type here..."
                        value={formData?.defaultReply}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            defaultReply: e.target.value,
                          })
                        }
                        rows={4}
                        className="bg-transparent border border-gray-300 rounded-xl text-base text-gray-200 placeholder:text-gray-300 w-full focus:outline-0 py-3 px-5"
                      ></textarea>
                    </div>
                    <p className="text-xs text-gray-400">
                      Example Tweet we will prefill for the users.
                    </p>
                  </div>
                )} */}
                {/* <!-- Form Widget Area --> */}
              </form>
            </div>
            <div className="md:col-span-5 lg:col-span-4">
              <form action="">
                <div className="form-widget border border-gray-600 p-6 mb-6">
                  <h4 className="text-xl text-blue-800 mb-2">Reward</h4>
                  <p className="text-base text-gray-100 mb-4">Credits</p>
                  <div className="form-field relative mb-4">
                    <input
                      type="number"
                      className="relative cursor-pointer bg-transparent border border-gray-300 rounded-xl text-base text-gray-100 w-full focus:outline-0 py-3 px-5 pr-10 z-2"
                      id="noOfNumber"
                      min={0}
                      value={formData?.reward}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData({
                          ...formData,
                          reward: parseInt(e.target.value), // Convert the input value to an integer
                        })
                      }
                    />

                    <div className="h-8 absolute top-0 bottom-0 right-4 m-auto">
                      <button
                        type="button"
                        id="adds"
                        className="block"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            reward: formData?.reward + 1,
                          })
                        }
                      >
                        <img src={UpIco} alt="" />
                      </button>
                      <button
                        type="button"
                        id="subs"
                        className="block"
                        onClick={() => {
                          if (formData?.reward > 0) {
                            setFormData({
                              ...formData,
                              reward: formData?.reward - 1,
                            });
                          }
                        }}
                      >
                        <img src={DownIco} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={QuestionIco} alt="" />
                    <p className="text-xs text-gray-400">
                      Learn more here about how the levels are calculated.
                    </p>
                  </div>
                </div>
                {/* <div className="form-widget border border-gray-600 p-6 mb-6">
                  <h4 className="text-xl text-blue-800 mb-2">Condition</h4>
                  <p className="text-base text-gray-400 mb-4">
                    Quest will only be claimable for members fulfilling the
                    condition.
                  </p>
                  <div className="flex items-center gap-4 mb-4">
                    <p className="text-base text-gray-100">Date</p>
                    <p className="text-base text-gray-400 flex items-center gap-2">
                      After <img src={AngleDownIco} alt="" />
                    </p>
                  </div>
                  <div className="form-field relative">
                    <input
                      id="bid-1"
                      type="datetime-local"
                      placeholder="10-02-2024, 10:53 PM"
                      value={formData?.conditionTimestamp}
                      min={new Date().toISOString().slice(0, 16)}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          conditionTimestamp: e.target.value,
                        })
                      }
                      className="relative cursor-pointer bg-transparent border border-gray-300 rounded-xl text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 py-3 px-5 pr-10 z-2"
                    />
                    <span className="h-6 absolute top-0 bottom-0 right-4 border-l border-gray-500 pl-3 py-2 m-auto z-1">
                      <img className="block -mt-1" src={CalendarIco} alt="" />
                    </span>
                  </div>
                </div> */}
                <div className="flex gap-1 md:gap-2">
                  <button
                    // type="submit"
                    disabled={createQuestMutation?.isLoading}
                    onClick={async (e) => {
                      e.preventDefault();
                      if (formData?.title === "" || formData?.reward <= 0) {
                        toast.error(
                          "Title cannot be empty and Reward must be greater than 0"
                        );
                        return;
                      }

                      setModalData(formData);
                      toggleModal(modalName);
                    }}
                    className="w-full flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-12 py-4 transition mb-8"
                  >
                    {createQuestMutation?.isLoading ? (
                      <ClipLoader color="#36d7b7" size={20} />
                    ) : (
                      "Preview"
                    )}
                  </button>
                  <button
                    // type="submit"
                    disabled={createQuestMutation?.isLoading}
                    onClick={async (e) => {
                      e.preventDefault();
                      try {
                        if (formData?.projectId === "") {
                          toast.error("Please select a project");
                          return;
                        }
                        if (formData?.title === "" || formData?.reward <= 0) {
                          toast.error(
                            "Title cannot be empty and Reward must be greater than 0"
                          );
                          return;
                        }

                        const payload = {
                          ...formData,
                          type: selectedType,
                          action: selectedAction,
                        };
                        console.log(payload);
                        await createQuestMutation.mutateAsync(payload);
                        toast.success("Quest created successfully!!!");
                        setFormData({
                          projectId: process.env.REACT_APP_PROJECT_ID,
                          title: "",
                          action: "",
                          type: "",
                          handle: "",
                          postUrl: "",
                          words: [],
                          defaultTweet: "",
                          description: "",
                          reward: 0,
                          conditionTimestamp: "",
                        });
                        setSelectedType("OneTime");
                        setSelectedAction("Follow");
                      } catch (error: any) {
                        toast.error(error?.message);
                      }
                    }}
                    className="w-full flex items-center justify-center gap-2 border border-gray-500 hover:border-blue-800 rounded-full text-sm text-gray-400 hover:text-blue-800 px-12 py-4 transition mb-8"
                  >
                    {createQuestMutation?.isLoading ? (
                      <ClipLoader color="#36d7b7" size={20} />
                    ) : (
                      "Publish"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateQuest;
