import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import { SignupDTO } from "../models/signup/signup.dto";

export const useSignupWithTwitter = () =>
  useMutation<any, Error, SignupDTO>(async (data) => {
    data.redirectUrl = `${window.location.origin}/twitter/callback`;
    const res = await client("auth/signupWithTwitter", {
      method: "POST", // Explicitly set the request method to "POST"
      data,
    });
    return res;
  });

export const useSignupWithReferralCode = () =>
  useMutation<any, Error, SignupDTO>(async (data) => {
    data.redirectUrl = `${window.location.origin}/twitter/callback`;
    const res = await client("auth/signupWithReferralCode", {
      method: "POST", // Explicitly set the request method to "POST"
      data,
    });
    return res;
  });

export const useVerifyTwitter = () =>
  useMutation<any, Error, { state: string; code: string }>({
    mutationFn: async ({ state, code }) => {
      const redirectUrl = `${window.location.origin}/twitter/callback`;
      const res = await client("auth/verifyTwitter", {
        method: "POST", // Explicitly set the request method to "POST"
        data: { state, code, redirectUrl },
      });
      return res;
    },
  });

export const useSignupTelegram = () =>
  useMutation<
    any,
    Error,
    { id: string; first_name: string; photo_url?: string; username?: string }
  >({
    mutationFn: async ({ id, photo_url, first_name, username }) => {
      // const redirectUrl = `${window.location.origin}/telegram/callback`;
      const res = await client("auth/signupTelegram", {
        method: "POST", // Explicitly set the request method to "POST"
        data: { id, first_name, username },
      });
      console.log(res);
      return res;
    },
  });

export const useVerifySignature = () => {
  const queryClient: any = useQueryClient();

  return useMutation<any, Error, { signature: string; address: string }>({
    mutationFn: async ({ signature, address }) => {
      const res = await client("auth/verifyWalletAddress", {
        method: "POST",
        data: { signature, address },
      });
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("topLeaderboard");
    },
  });
};

export const useGetLoggedInUser = () =>
  useQuery<any, Error>(
    ["getLoggedInUser"],
    async () => await client(`auth/getLoggedInUser`)
  );
