import React, { useEffect, useState } from "react";
import DashboardHeader from "../components/DashboardHeader";

// import UserIcoW from "../assets/images/userIconWhite.svg";
// import HeartIcoW from "../assets/images/heartIconWhite.svg";
// import EyeIcoW from "../assets/images/eyeIconWhite.svg";
// import ChatIcoW from "../assets/images/chatIconWhite.svg";
// import RepeatIcoW from "../assets/images/repeatIconWhite.svg";
// import ClipboardIcoW from "../assets/images/clipboardIconWhite.svg";
import TrophyIco from "../assets/images/trophyIcon.svg";
import GreyTrophyIco from "../assets/images/grey-trophy.png";
import CopyIco from "../assets/images/copyIcon.svg";
import OrangeTrophyIco from "../assets/images/orange-trophy.png";

import {
  useGetAllBoosters,
  useGetBoosterPurchaseWallet,
  useGetUserActiveBooster,
  useGetWallet,
  usePurchaseBooster,
  useTopLeaderboard,
} from "../utils/wallet.api";
import { useGetLoggedInUser } from "../utils/auth.api";
import PointCard from "../components/PointCard";
import { useSpring, animated } from "react-spring";
import Carousel from "react-spring-3d-carousel";

import { useWallet } from "@solana/wallet-adapter-react";
import { FadeLoader } from "react-spinners";
import { useGetQuestByProject, useGetUserTasks } from "../utils/quest.api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useConnection } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as web3 from "@solana/web3.js";
import WalletConnectModal from "../components/modals/WalletConnectModal";
import DbStat from "../assets/images/db-stat-bg.png";
import ActiveBoosterCard from "../components/ActiveBoosterCard";

function Dashboard() {
  const projectId = process.env.REACT_APP_PROJECT_ID || "";

  const navigate = useNavigate();

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [limit, setLimit] = useState(15);

  // const [slides, setSlides] = useState([]);
  // const [tasksToShow] = useState(15);
  const [activeSlide, setActiveSlide] = useState(0);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  // const [walletConnected, setWalletConnected] = useState(false);

  const dataWallet = useWallet();
  const {
    publicKey,
    sendTransaction,
    connected: walletConnected,
  } = useWallet();
  const { connection } = useConnection();

  const { mutateAsync: purchaseBooster } = usePurchaseBooster();
  const { data } = useGetWallet();
  const { data: boosterData } = useGetAllBoosters();
  const { data: userData, isLoading: userLoading } = useGetLoggedInUser();
  const { data: boosterPurchaseWallet } = useGetBoosterPurchaseWallet();
  const {
    data: leaderboardData,
    isLoading: leaderBoardLoading,
    refetch: refetchLeaderboard,
  } = useTopLeaderboard({
    limit,
    offset: 0,
  });

  const { data: pendingTasks } = useGetQuestByProject({
    projectId: projectId,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  });
  const { data: completedTasks } = useGetUserTasks({
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  });
  const { data: userActiveBooster } = useGetUserActiveBooster();

  const isTransactionPrapagated = async (sig: web3.TransactionSignature) => {
    try {
      const transaction: any = await connection.getParsedTransaction(sig);

      if (
        transaction?.transaction?.message?.instructions?.[2]?.parsed?.type ===
        "transfer"
      ) {
        return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const sendSol = async (amount: number, boosterId: string) => {
    if (boosterPurchaseWallet?.walletAddress) {
      try {
        setLoading(true);
        const transaction = new web3.Transaction();
        const recipientPubKey = new web3.PublicKey(
          boosterPurchaseWallet?.walletAddress
        );
        if (!publicKey) {
          setLoading(false);
          return;
        }
        const sendSolInstruction = await web3.SystemProgram.transfer({
          fromPubkey: publicKey || "",
          toPubkey: recipientPubKey,
          lamports: LAMPORTS_PER_SOL * amount,
        });

        await transaction.add(sendSolInstruction);
        const sig = await sendTransaction(transaction, connection);

        do {
          await new Promise((r) => setTimeout(r, 1000));
        } while (!(await isTransactionPrapagated(sig)));

        const payload = {
          boosterId: boosterId,
          transactionHash: sig,
        };

        const res = await purchaseBooster(payload);
        console.log(res);
        toast.success("Booster purchased successfully");
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        toast.error(error?.message);
      }
    } else {
      toast.error("Booster purchase wallet not found. Please try again!");
    }
  };

  const handleSlideClick = (index: any) => {
    setActiveSlide(index);
  };
  const handleButtonClick = (price: number, boosterId: string, idx: number) => {
    sendSol(price, boosterId);
    setLoadingIndex(idx);
  };

  let xDown: any = null;
  let yDown: any = null;

  const config = {
    mass: 5,
    tension: 350,
    friction: 40,
  };

  // React Spring useSpring hook to control carousel rotation
  const [,] = useSpring(() => ({
    from: { rotationX: 0, rotationY: 0, scale: 1 },
    to: async (next: any) => {
      await next({ rotationY: 180, rotationX: 180, scale: 1.5 });
      await next({ rotationY: 360, rotationX: 360, scale: 1 });
    },
    config: config,
  }));

  // const getTaskIco = (action: string) => {
  //   switch (action) {
  //     case "Like":
  //       return HeartIcoW;
  //     case "Follow":
  //       return UserIcoW;
  //     case "Tweet":
  //       return ClipboardIcoW;
  //     case "Retweet":
  //       return RepeatIcoW;
  //     case "Reply":
  //       return ChatIcoW;
  //     default:
  //       return "";
  //   }
  // };

  const getTouches = (evt: any) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchStart = (evt: any) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt: any) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setActiveSlide(activeSlide + 1);
      } else {
        /* right swipe */
        setActiveSlide(activeSlide - 1);
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  const handleLoadMore = () => {
    setLimit(30);
  };

  // useEffect(() => {
  //   if (tasksToShow !== 15) {
  //     refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tasksToShow]);

  useEffect(() => {
    if (limit !== 15) {
      refetchLeaderboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  useEffect(() => {
    if (
      !userLoading &&
      (!userData?.id || !userData?.telegramId || !walletConnected)
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.id && userLoading]);

  return (
    <div className="bg-[#070707]">
      {/* <!-- Connect Wallet Area --> */}
      <WalletConnectModal
        isCreateOpen={isCreateOpen}
        setIsCreateOpen={setIsCreateOpen}
        dataWallet={dataWallet}
        setWalletConnected={setIsCreateOpen}
      />

      {/* <!-- Header Area --> */}
      <DashboardHeader
        setIDashboardHeadersCreateOpen={setIsCreateOpen}
        data={data}
        userData={userData}
        walletConnected={walletConnected}
        setWalletConnected={() => {}}
        isLoading={userLoading}
        walletData={dataWallet}
        isCreateOpen={isCreateOpen}
      />

      {/* <!-- Dashboard Area --> */}
      <section className="dashboard-area md:pt-[16px] pt-[4px] pb-[32px] md:pb-36 font-[Barlow]">
        <div className="max-w-[1338px] mx-auto px-[16px] md:px-[24px] pt-0">
          <div className=" lg:py-[124px] lg:px-[53px] font-[Barlow] font-[700] text-[#FFFFFFD9] db-main-card">
            <div className="db-tasks-card pb-[32px] pt-[32px]">
              <div className=" pb-[32px] pt-[32px] px-[16px]">
                <h1 className="font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[34px] md:tracking-[0.6px]">
                  My Tasks
                </h1>
                <div className="flex flex-col md:flex-row gap-[8px] md:gap-[16px] mb-[20px] md:mb-[28px] mt-[20px] md:mt-[28px]">
                  <div
                    className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1 cursor-pointer"
                    style={{
                      backgroundImage: `url(${DbStat})`,
                      backgroundSize: "100% 100%",
                    }}
                    onClick={() => navigate("/quests")}
                  >
                    <h1 className="text-[48px]">
                      {
                        pendingTasks?.questDocumentsReturn?.filter(
                          (task: any) => task && task?.isCompleted === false
                        )?.length
                      }
                    </h1>
                    <p className="text-[14px] text-[#ABBBC2] font-[400]">
                      Pending Tasks
                    </p>
                  </div>
                  <div
                    className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1 cursor-pointer"
                    style={{
                      backgroundImage: `url(${DbStat})`,
                      backgroundSize: "100% 100%",
                    }}
                    onClick={() => navigate("/quests", { state: true })}
                  >
                    <h1 className="text-[48px]">{completedTasks?.length}</h1>
                    <p className="text-[14px] text-[#ABBBC2] font-[400]">
                      Completed Tasks
                    </p>
                  </div>
                </div>
                {/* {tasksLoading ? (
                  <div className="flex justify-center mt-5">
                    <FadeLoader
                      color="#00C2FF"
                      loading={tasksLoading}
                      height={15}
                      width={5}
                    />
                  </div>
                ) : userTasks?.length > 0 ? (
                  <div className="tasks-connected">
                    <div className="flex flex-col gap-[16px] md:gap-[32px] my-[16px] md:my-[32px] uppercase">
                      {userTasks
                        ?.filter((dat: any) => dat?.isCompleted === false)
                        ?.map((task: any, idx: number) => (
                          <div
                            key={idx}
                            className="task-bg text-[10px] md:text-[20px] lg:text-[28px] flex w-full justify-between px-[12px] lg:px-[32px] py-[16px] md:py-[24px] gap-[20px]"
                          >
                            <div className="flex gap-[4px] md:gap-[12px] items-center">
                              <div className="bg-[#212121] p-[2px] md:p-[6px] rounded-[2px] md:rounded">
                                <img
                                  className="min-w-[12px] w-[12px] md:w-[32px] md:min-w-[32px]"
                                  src={getTaskIco(task?.action)}
                                  alt=""
                                />
                              </div>
                              <p className="font-[600] lg:font-[700]">
                                {task?.title}
                              </p>
                            </div>
                            <div className="flex gap-[22px] lg:gap-[64px] items-center whitespace-nowrap">
                              <p>{task?.reward} POINTS</p>
                              <div
                                className="cursor-pointer db-tag py-[6px] md:py-[13px] px-[10px] md:px-[32px] text-[#ABBBC2] text-[10px] md:text-[16px] md:tracking-[0.96px]"
                                onClick={() => navigate("/quests")}
                              >
                                Go to Tasks
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {userTasks?.length > 14 && (
                      <div
                        className="cursor-pointer db-tag mx-auto text-[#ABBBC2] text-[10px] md:text-[14px] font-[400] w-[111px] h-[38px] flex justify-center items-center"
                        onClick={() => {
                          setTasksToShow(tasksToShow + 10);
                        }}
                      >
                        Load More
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col items-center tasks-no-connect">
                    <img
                      className="w-[369px] max-w-[90%] my-[37px]"
                      src={DBNoWalletIco}
                      alt=""
                    />
                    <h1 className="text-[20px]">No Tasks Found</h1>

                    {walletConnected ? (
                      <></>
                    ) : (
                      <p className="text-[#ABBBC2] font-[400] mt-[8px] text-center">
                        Connect your wallet account to view tasks to earn
                        points.
                      </p>
                    )}
                    {walletConnected ? (
                      <></>
                    ) : (
                      <a
                        className="popup-with-zoom-anim"
                        href="#connect-wallet"
                      >
                        <button
                          onClick={() => setIsCreateOpen(true)}
                          className="connect-wallet-btn w-[170px] h-[46px] text-[#FFFFFFE5] font-[500] text-[16px] mt-[37px]"
                        >
                          Connect Wallet
                        </button>
                      </a>
                    )}
                  </div>
                )} */}
              </div>
              {walletConnected && (
                <div className="pb-[32px] pt-[32px] px-[16px]">
                  <h1 className="font-[900] md:font-[700] text-[24px] md:text-[50px] md:leading-[normal] md:tracking-[0.6px]">
                    Points Booster
                  </h1>
                  <p className="text-[#ABBBC2] font-light mt-4 text-[18px]">
                    The strategic use of these NFTs adds a layer of complexity
                    and strategy to games on Supido. Players need to decide when
                    and how to use their NFT boosters for maximum effect,
                    whether to push for a win, safeguard their position, or
                    disrupt their competitors. This makes the gaming experience
                    on Supido more engaging, competitive, and rewarding.
                  </p>
                  <h1 className="font-[900] md:font-[700] text-[20px] md:text-[36px] md:leading-[normal] md:tracking-[0.6px] mt-4 md:mt-8">
                    Your Active Boosters
                  </h1>
                  <div className="flex items-start gap-4 mb-8 mt-4">
                    <div className="flex justify-around w-full align-middle gap-4 md:gap-[4%] md:mt-[32px] md:mx-[5%] flex-col md:flex-row  ">
                      {userActiveBooster &&
                        userActiveBooster?.map((item: any, index: any) => (
                          <ActiveBoosterCard
                            key={index}
                            type={item?.type}
                            qty={item?.quantity}
                          />
                        ))}
                    </div>
                  </div>
                  <h1 className="font-[900] md:font-[700] text-[20px] md:text-[36px] md:leading-[normal] md:tracking-[0.6px]">
                    Purchase Boosters
                  </h1>
                  <div className="flex items-start gap-4 ">
                    <div className="flex justify-around w-full align-middle gap-6 md:mt-[32px] md:mx-[5%]  h-[460px] md:h-[550px] lg:hidden">
                      <animated.div
                        style={{
                          width: "80%",
                          height: "100%",
                        }}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                      >
                        <Carousel
                          slides={
                            boosterData
                              ? boosterData?.map((item: any, index: any) => ({
                                  key: index + 1,
                                  content: (
                                    <PointCard
                                      key={item.id}
                                      name={item.name}
                                      price={item.price}
                                      type={item.type}
                                      time={item.timeLimit}
                                      isLoading={
                                        loading && index === loadingIndex
                                      }
                                      onClick={() => handleSlideClick(index)}
                                      btnClick={() => {
                                        console.log("sd");
                                        handleButtonClick(
                                          item?.price,
                                          item?.id,
                                          index
                                        );
                                      }}
                                    />
                                  ),
                                }))
                              : []
                          }
                          goToSlide={activeSlide}
                          offsetRadius={2}
                          animationConfig={config}
                          showNavigation={false}
                        />
                      </animated.div>
                    </div>
                    <div className="justify-around w-full align-middle gap-[4%] md:mt-[32px] md:mx-[5%]  h-[460px] md:h-[550px] hidden lg:flex">
                      {boosterData?.map((slide: any, idx: number) => {
                        return (
                          <PointCard
                            key={idx}
                            name={slide.name}
                            price={slide.price}
                            type={slide.type}
                            time={slide.timeLimit}
                            isLoading={loading && idx === loadingIndex}
                            onClick={() => handleSlideClick(idx)}
                            btnClick={() => {
                              console.log("sd");
                              handleButtonClick(slide?.price, slide?.id, idx);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {walletConnected && (
                <div className="flex flex-col md:flex-row items-start justify-center md:justify-start font-Inter text-[18px] md:mx-[19%]">
                  <p className="text-[#00C2FF] font-bold text-center w-full md:w-fit">
                    Note:{" "}
                  </p>
                  <p className="text-[#ABBBC2] font-light text-center">
                    A user can buy a maximum of 500 of these boosters, with a
                    limit on the total number of points they can accumulate.
                  </p>
                </div>
              )}
            </div>

            <div className="flex flex-col lg:flex-row gap-[64px] lg:gap-[24px] mt-[64px] lg:mt-[80px]">
              {/* <div className="">
                <h1 className="font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[34px] md:tracking-[0.6px]">
                  Invite Friends
                </h1>
                <div className="db-card w-full lg:w-[560px] mt-[16px] md:mt-[34px] px-[16px] md:px-[24px] pt-[16px] md:pt-[24px] pb-[72px] md:pb-[72px]">
                  <div className="flex flex-col md:flex-row gap-[8px] md:gap-[16px] mb-[20px] md:mb-[28px]">
                    <div
                      className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1"
                      style={{
                        backgroundImage: `url(${DbStat})`,
                        backgroundSize: "100% 100%",
                      }}
                    >
                      <h1 className="text-[48px]">
                        {userData?.totalPointsReferral?.toFixed(6)}
                      </h1>
                      <p className="text-[14px] text-[#ABBBC2] font-[400]">
                        Total Points
                      </p>
                    </div>
                    <div
                      className="rounded-[16px]  border-l-[6px] border-l-[#00C2FF] py-[18px] pl-[32px] flex-1"
                      style={{
                        backgroundImage: `url(${DbStat})`,
                        backgroundSize: "100% 100%",
                      }}
                    >
                      <h1 className="text-[48px]">
                        {userData?.totalReferralInvites}
                      </h1>
                      <p className="text-[14px] text-[#ABBBC2] font-[400]">
                        Total Invited
                      </p>
                    </div>
                  </div>
                  <p className="text-[16px] md:text-[20px] capitalize mb-[8px] md:mb-[12px]">
                    Invite friends for extra points
                  </p>
                  <p className="text-[14px] md:text-[16px] font-[400] mb-[16px]">
                    On inviting friends, you receive referral rewards in{" "}
                    <span className="text-[#00C2FF] font-[700]">$SUPIDO</span>{" "}
                    tokens from your friend’s earnings
                  </p>
                  <div className="border border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                    <img
                      className="w-[12px] md:w-[20px]"
                      src={GlobeIco}
                      alt=""
                    />
                    <p className="leading-[22px] text-[32px]">|</p>
                    <p className="text-[12px] md:text-[16px] truncate ...">
                      {`${window?.location?.origin}?invitation=${
                        userData?.myReferralCode || ""
                      }`}
                    </p>
                    <div
                      className="ml-auto cursor-pointer text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                      onClick={() => {
                        navigator?.clipboard
                          ?.writeText(
                            `${window?.location?.origin}?invitation=${
                              userData?.myReferralCode || ""
                            }`
                          )
                          .then(() => {
                            toast.success("Invitation link copied!");
                          });
                      }}
                    >
                      Copy
                    </div>
                  </div>
                  <div className="border my-[12px] border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                    <img className="w-[12px] md:w-[20px]" src={XIco} alt="" />
                    <p className="leading-[22px] text-[32px]">|</p>
                    <p className="text-[12px] md:text-[16px] truncate ...">
                      {`https://twitter.com/${userData?.screen_name}`}
                    </p>
                    <div
                      className="cursor-pointer ml-auto text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                      onClick={() => {
                        const url = `https://twitter.com/compose/post`;

                        window.open(url, "_blank");
                      }}
                    >
                      Share
                    </div>
                  </div>
                  <div className="border border-[#4D4D4D] rounded-[12px] py-[13px] px-[16px] flex gap-[8px] text-[#4D4D4D] items-center font-[400]">
                    <img
                      className="w-[12px] md:w-[20px]"
                      src={TelegramIco}
                      alt=""
                    />
                    <p className="leading-[22px] text-[32px]">|</p>
                    <p className="text-[12px] md:text-[16px] truncate ...">
                      {`https://telegram.org/user/${userData?.screen_name}`}
                    </p>
                    <div
                      className="cursor-pointer ml-auto text-[#FCFCFC] text-[12px] bg-[#212121] py-[6px] px-[10px] w-[47px] rounded-[5px] font-[500]"
                      onClick={() => {
                        const url = `https://telegram.org/user/${userData?.screen_name}`;
                        window.open(url, "_blank");
                      }}
                    >
                      Share
                    </div>
                  </div>
                </div>
               
              </div> */}
              <div className="overflow-x-auto w-full">
                <div className="w-full min-w-[320px]">
                  <div className="flex justify-between items-center gap-[30px]">
                    <h1 className="font-[900] md:font-[700] text-[24px] md:text-[48px] md:leading-[34px] md:tracking-[0.6px] whitespace-nowrap">
                      Top Leaderboard
                    </h1>
                    <div
                      onClick={handleLoadMore}
                      className="cursor-pointer db-tag text-[#ABBBC2] text-[10px] md:text-[14px] font-[400] w-[111px] h-[38px] flex justify-center items-center"
                    >
                      Load More
                    </div>
                  </div>
                  <div className="db-card w-full mt-[16px] md:mt-[34px] px-[16px] md:px-[24px] pt-[16px] md:pt-[24px] pb-[62px] md:pb-[72px]">
                    {leaderBoardLoading ? (
                      <div className="flex justify-center mt-5">
                        <FadeLoader
                          color="#00C2FF"
                          loading={leaderBoardLoading}
                          height={15}
                          width={5}
                        />
                      </div>
                    ) : (
                      <table className="table-auto w-full text-white font-[400] text-[14px] md:text-[16px] ">
                        <thead className="text-left font-[Barlow] text-[14px] md:text-[18px] font-[400] text-[#FFFFFF99]   ">
                          <tr className="font-[400] pr-[12px] mr-[-8px]">
                            <th className="font-[400] px-[2px] py-[2px] w-[35px] md:w-[44px] justify-center flex">
                              Rank
                            </th>

                            <th className="font-[400] px-[2px] py-[2px] w-[92px] md:w-[162px] truncate">
                              User Address
                            </th>
                            <th className="font-[400] px-[2px] py-[2px] w-[72px] md:w-[94px]  truncate justify-center flex">
                              Credits
                            </th>
                            <th className="font-[400] px-[2px] py-[2px] w-[55px] md:w-[60px] justify-center flex">
                              Invited
                            </th>
                          </tr>
                        </thead>
                        {/* <div className="w-full overflow-y-auto border"> */}
                        <tbody
                          className="flex flex-col items-center justify-between w-full overflow-y-auto lb-tbody"
                          style={{ height: "100%", maxHeight: "426px" }}
                        >
                          {leaderboardData?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className={`my-[20px] ${
                                index > 3 && limit === 15 ? "hidden" : ""
                              }`}
                            >
                              <td className="px-[2px] py-[20px] font-[700] text-[16px] w-[35px] md:w-[44px] justify-center flex ">
                                {index === 0 && <img src={TrophyIco} alt="" />}
                                {index === 1 && (
                                  <img src={GreyTrophyIco} alt="" />
                                )}
                                {index === 2 && (
                                  <img src={OrangeTrophyIco} alt="" />
                                )}
                                {index >= 3 && index + 1}
                              </td>

                              <td className="px-[2px] py-[20px] w-[92px] md:w-[162px] truncate ">
                                <div className="flex gap-[8px] text-[#FFFFFFE5]">
                                  <img
                                    className="w-[16px] md:w-[24px] cursor-pointer"
                                    src={CopyIco}
                                    alt=""
                                    onClick={() => {
                                      navigator?.clipboard
                                        ?.writeText(`${item?.walletAddress}`)
                                        .then(() => {
                                          toast.success(
                                            "Address Copied to Clipboard!"
                                          );
                                        });
                                    }}
                                  />
                                  {item?.walletAddress
                                    ? `${item?.walletAddress?.slice(
                                        0,
                                        6
                                      )}...${item?.walletAddress?.slice(-6)}`
                                    : "******************"}
                                </div>
                              </td>
                              <td className="px-[2px] py-[20px] text-center w-[72px] md:w-[94px]  truncate justify-center flex">
                                {item?.totalPointsReferral?.toFixed(4) || 0} CR
                              </td>
                              <td className="px-[2px] py-[20px] text-center w-[55px] md:w-[60px] justify-center flex">
                                {item?.totalReferralInvites}
                              </td>
                            </tr>
                          ))}
                          {limit === 15 && (
                            <>
                              {leaderboardData?.length > 4 && (
                                <tr className="my-[20px]">
                                  <td className="py-[27px] font-[500] text-[20px] text-left w-[35px] md:w-[44px] justify-center flex">
                                    ...
                                  </td>
                                  <td className="px-[2px] py-[20px] w-[92px] md:w-[162px] truncate justify-center flex">
                                    ...
                                  </td>
                                  <td className="px-[2px] py-[20px] w-[72px] md:w-[94px]  truncate justify-center flex">
                                    ...
                                  </td>
                                  <td className="px-[2px]  py-[20px] w-[55px] md:w-[60px] justify-center flex">
                                    ...
                                  </td>
                                </tr>
                              )}
                              {leaderboardData?.length && (
                                <tr
                                  key={leaderboardData?.length}
                                  className={`my-[20px]`}
                                >
                                  <td className="px-[2px] py-[20px] font-[700] text-[16px] w-[35px] md:w-[44px] justify-center flex">
                                    {leaderboardData?.length}
                                  </td>

                                  <td className="px-[2px] py-[20px] w-[92px] md:w-[162px] truncate">
                                    <div className="flex gap-[8px] text-[#FFFFFFE5]">
                                      <img
                                        className="w-[16px] md:w-[24px] cursor-pointer"
                                        src={CopyIco}
                                        alt=""
                                        onClick={() => {
                                          navigator?.clipboard
                                            ?.writeText(
                                              `${
                                                leaderboardData[
                                                  leaderboardData?.length
                                                ]?.walletAddress
                                              }`
                                            )
                                            .then(() => {
                                              toast.success(
                                                "Address Copied to Clipboard!"
                                              );
                                            });
                                        }}
                                      />
                                      {leaderboardData[leaderboardData?.length]
                                        ?.walletAddress
                                        ? `${leaderboardData[
                                            leaderboardData.length
                                          ].walletAddress.slice(
                                            0,
                                            6
                                          )}...${leaderboardData[
                                            leaderboardData.length
                                          ].walletAddress.slice(-6)}`
                                        : "******************"}
                                    </div>
                                  </td>
                                  <td className="px-[2px] py-[20px] text-center w-[72px] md:w-[94px]  truncate justify-center flex">
                                    {leaderboardData[
                                      leaderboardData?.length
                                    ]?.totalPointsReferral?.toFixed(6) ||
                                      0}{" "}
                                    CR
                                  </td>
                                  <td className="px-[2px] py-[20px] text-center w-[55px] md:w-[60px] justify-center flex">
                                    {
                                      leaderboardData[leaderboardData?.length]
                                        ?.totalReferralInvites
                                    }
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                        {/* </div> */}
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
