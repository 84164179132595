import React, { Fragment } from "react";
import FormUploadIco from "../assets/images/form-upload.svg";
import FormProjectIco from "../assets/images/form-project.svg";
import FormMapIco from "../assets/images/form-map.svg";
import FormTwitterIco from "../assets/images/form-twitter.svg";
import FormYoutubeIco from "../assets/images/form-youtube.svg";
import FormFacebookIco from "../assets/images/form-inst.svg";
import FormCloseIco from "../assets/images/close.svg";
import FormProfileIco from "../assets/images/form-profile.svg";
import PlusWhiteIco from "../assets/images/plus-white.svg";
import Linkico from "../assets/images/link-icon.svg";
import Copyico from "../assets/images/copy-icon.svg";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
function CreateProject({ isOpen, setIsOpen }: any) {
  return (
    <div>
      {/* <!-- Create Project Area Step 1 --> */}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto z-50 flex align-top justify-center "
          onClose={() => setIsOpen(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-40  backdrop-blur-2xl" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative w-[96%] max-w-md ">
              <button
                className="absolute top-0 right-0 focus:outline-none w-[44px] h-[44px]"
                onClick={() => setIsOpen(false)}
              >
                <p className="text-[24px] text-white opacity-[0.65]">x</p>
              </button>
              <div className="modal-dialog zoom-anim-dialog bg-[#1A1A1A] p-6 rounded-2xl shadow-lg">
                <h1 className="text-xl text-gray-50 font-bold mb-6">
                  Create Project
                </h1>
                <p className="text-sm text-gray-400 mb-4">
                Let's trend your project together with SUPIDO
                </p>
                <form action="#">
                  {/* <!-- Upload --> */}
                  <div className="flex items-center mb-4">
                    <label htmlFor="dropzone-file" className="cursor-pointer">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center justify-center w-20 h-20 border border-gray-500 rounded-xl">
                          <img src={FormUploadIco} alt="" />
                        </div>
                        <p className="text-sm text-gray-400">Upload Logo</p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  {/* <!-- Feild --> */}
                  <label
                    className="block text-sm text-gray-400 mb-2"
                    htmlFor="project"
                  >
                    Name
                  </label>
                  <div className="flex items-center gap-4 border border-gray-500 rounded-xl py-3 px-5 mb-5">
                    <span className="text-xs text-gray-400">
                      <img src={FormProjectIco} alt="" />
                    </span>
                    <input
                      id="project"
                      type="text"
                      placeholder="Project Name"
                      className="bg-transparent text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0"
                    />
                  </div>
                  {/* <!-- Feild --> */}
                  <label
                    className="block text-sm text-gray-400 mb-2"
                    htmlFor="type"
                  >
                    Description
                  </label>
                  <textarea
                    id="type"
                    rows={3}
                    placeholder="Type here..."
                    className="bg-transparent border border-gray-500 rounded-xl text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0 py-3 px-5 mb-5"
                  ></textarea>
                  {/* <!-- Feild --> */}
                  <label
                    className="block text-sm text-gray-400 mb-2"
                    htmlFor="website"
                  >
                    Website link
                  </label>
                  <div className="flex items-center gap-4 border border-gray-500 rounded-xl py-3 px-5 mb-5">
                    <span className="text-xs text-gray-400">
                      <img src={FormMapIco} alt="" />
                    </span>
                    <input
                      id="website"
                      type="text"
                      placeholder="www.dummylink.com"
                      className="bg-transparent text-base text-gray-300 placeholder:text-gray-300 w-full focus:outline-0"
                    />
                  </div>
                  {/* <!-- Feild --> */}
                  <label
                    className="block text-sm text-gray-400 mb-2"
                    htmlFor="social-1"
                  >
                    Social Links
                  </label>
                  <div className="flex items-center gap-4 border border-gray-500 rounded-xl py-3 px-5 mb-4">
                    <span className="text-xs text-gray-400">
                      <img src={FormTwitterIco} alt="" />
                    </span>
                    <input
                      id="soical-1"
                      type="text"
                      placeholder="www.twitter.com/xyz"
                      className="bg-transparent text-base text-gray-200 placeholder:text-gray-200 w-full focus:outline-0"
                    />
                  </div>
                  {/* <!-- Feild --> */}
                  <div className="flex items-center gap-4 border border-gray-500 rounded-xl py-3 px-5 mb-4">
                    <span className="text-xs text-gray-400">
                      <img src={FormYoutubeIco} alt="" />
                    </span>
                    <input
                      type="text"
                      placeholder="www.youtube.com/xyz"
                      className="bg-transparent text-base text-gray-200 placeholder:text-gray-200 w-full focus:outline-0"
                    />
                  </div>
                  {/* <!-- Feild --> */}
                  <div className="flex items-center gap-4 border border-gray-500 rounded-xl py-3 px-5 mb-5">
                    <span className="text-xs text-gray-400">
                      <img src={FormFacebookIco} alt="" />
                    </span>
                    <input
                      type="text"
                      placeholder="www.facebookc.com/xyz"
                      className="bg-transparent text-base text-gray-200 placeholder:text-gray-200 w-full focus:outline-0"
                    />
                  </div>
                  {/* <!-- Feild --> */}
                  <div className="flex items-center gap-4">
                    <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-5 py-4 transition">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      // href="#create-project2"
                      className="popup-with-zoom-anim inline-flex w-full items-center justify-center gap-2 bg-gray-800 border border-gray-400 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-2 py-4 transition"
                    >
                      Create Project
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      {/* <!-- Create Project Area Step 2 --> */}
      <div
        id="create-project2"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-6">Create Project</h1>
        <p className="text-sm text-gray-400 mb-2">
          Optimise the visibility of your community on SUPIDO
        </p>
        <h5 className="text-base text-gray-50 mb-4">Add Moderators</h5>
        <form action="#">
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-4 w-full border border-gray-500 rounded-xl py-3 px-5">
              <span className="text-xs text-gray-400">
                <img src={FormProfileIco} alt="" />
              </span>
              <input
                type="text"
                placeholder="@username"
                className="bg-transparent text-base text-gray-500 placeholder:text-gray-500 w-full focus:outline-0"
              />
              <p className="text-xs text-gray-500">1</p>
            </div>
            <button className="flex items-center gap-2 text-base text-gray-50">
              Remove <img src={FormCloseIco} alt="" />
            </button>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-4 w-full border border-gray-500 rounded-xl py-3 px-5">
              <span className="text-xs text-gray-400">
                <img src={FormProfileIco} alt="" />
              </span>
              <input
                type="text"
                placeholder="@username"
                className="bg-transparent text-base text-gray-500 placeholder:text-gray-500 w-full focus:outline-0"
              />
              <p className="text-xs text-gray-500">1</p>
            </div>
            <button className="flex items-center gap-2 text-base text-gray-50">
              Remove <img src={FormCloseIco} alt="" />
            </button>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-4 w-full border border-gray-500 rounded-xl py-3 px-5">
              <span className="text-xs text-gray-400">
                <img src={FormProfileIco} alt="" />
              </span>
              <input
                type="text"
                placeholder="@username"
                className="bg-transparent text-base text-gray-500 placeholder:text-gray-500 w-full focus:outline-0"
              />
              <p className="text-xs text-gray-500">1</p>
            </div>
            <button className="flex items-center gap-2 text-base text-gray-50">
              Remove <img src={FormCloseIco} alt="" />
            </button>
          </div>
          <div className="pointer inline-flex items-center gap-2 bg-gray-800 border border-gray-500 hover:border-[#00C2FF] rounded-full text-sm text-gray-50 px-5 py-2.5 transition">
            <img src={PlusWhiteIco} alt="" /> Add new moderator
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4 mt-12">
            <button className="popup-modal-dismiss inline-flex w-full items-center justify-center gap-2 border border-gray-500 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-5 py-4 transition">
              Cancel
            </button>
            <button
              // href="#create-project3"
              className="popup-with-zoom-anim inline-flex w-full items-center justify-center gap-2 bg-gray-800 border border-gray-400 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-2 py-4 transition"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      {/* <!-- Create Project Area /Step 3 --> */}
      <div
        id="create-project3"
        className="madal-dialog zoom-anim-dialog mfp-hide"
      >
        <h1 className="text-xl text-gray-50 font-bold mb-6">Create Project</h1>
        <p className="text-sm text-gray-400 mb-2">
          Optimise the visibility of your community on SUPIDO
        </p>
        <h5 className="text-base text-gray-50 mb-4">Project Link</h5>
        <form action="#">
          {/* <!-- Feild --> */}
          <div className="flex items-center justify-between gap-2 mb-4">
            <div className="flex items-center gap-4 w-full border-b border-gray-600 py-2">
              <span className="text-xs text-gray-400">
                <img src={Linkico} alt="" />
              </span>
              <p className="text-base text-gray-200">turbo-buzz.com/user7384</p>
            </div>
            <button className="flex items-center gap-2 text-base text-gray-50 py-2">
              Copy <img src={Copyico} alt="" />
            </button>
          </div>
          {/* <!-- Feild --> */}
          <div className="flex items-center gap-4 mt-8">
            <button
              // href="#create-project2"
              className="popup-with-zoom-anim inline-flex w-full items-center justify-center gap-2 border border-gray-400 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-5 py-4 transition"
            >
              Previous
            </button>
            <Link
              to="/dashboard-listing"
              className="inline-flex w-full items-center justify-center gap-2 bg-gray-800 border border-gray-400 hover:border-[#00C2FF] rounded-full text-sm text-gray-400 hover:text-[#00C2FF] px-2 py-4 transition"
            >
              List for Attention Space
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProject;
