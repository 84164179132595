import React, { useMemo } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Index";
import UserQuest from "./pages/UserQuest";
import TopProject from "./pages/TopProject";
import Tokenomics from "./pages/Tokenomics";
import Referral from "./pages/Referral";
import NoAvailable from "./pages/NoAvailable";
import Dashboard from "./pages/Dashboard";
import DashboardListing from "./pages/DashboardListing";
import CreateQuest from "./pages/CreateQuest";
// import AboutUs from "./pages/AboutUs";
import Quests from "./pages/Quests";
import Callback from "./pages/Callback";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  SolanaMobileWalletAdapter,
  createDefaultAddressSelector,
  createDefaultAuthorizationResultCache,
  createDefaultWalletNotFoundHandler,
} from "@solana-mobile/wallet-adapter-mobile";
import {
  CoinbaseWalletAdapter,
  PhantomWalletAdapter,
  TrustWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import TelegramCallback from "./pages/TelegramCallback";
import DeleteTask from "./components/DeleteTask";

function App() {
  const endpoint = web3.clusterApiUrl("devnet");
  const wallets = useMemo(
    () => [
      new SolanaMobileWalletAdapter({
        addressSelector: createDefaultAddressSelector(),
        appIdentity: {
          name: "Supido",
          uri: `${window.location.origin}`,
          icon: "relative/path/to/icon.png",
        },
        authorizationResultCache: createDefaultAuthorizationResultCache(),
        cluster: WalletAdapterNetwork.Devnet,
        onWalletNotFound: createDefaultWalletNotFoundHandler(),
      }),
      new CoinbaseWalletAdapter(),
      new PhantomWalletAdapter(),
      new TrustWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
    ],
    []
  );
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/about-us" element={<AboutUs />} /> */}
              <Route path="/create-quest" element={<CreateQuest />} />
              <Route path="/dashboard-listing" element={<DashboardListing />} />
              <Route path="/dashboard" element={<Dashboard />} />
              {/* <Route path="/how-it-works" element={<HowItWorks />} /> */}
              <Route path="/no-available" element={<NoAvailable />} />
              <Route path="/referrals" element={<Referral />} />
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/top-project" element={<TopProject />} />
              <Route path="/user-quest" element={<UserQuest />} />
              <Route path="/quests" element={<Quests />} />
              <Route path="/delete-quest" element={<DeleteTask />} />
              <Route path="/twitter/callback" element={<Callback />} />
              <Route path="/telegram/callback" element={<TelegramCallback />} />
            </Routes>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
