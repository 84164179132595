import React, { useState } from "react";
import { FadeLoader } from "react-spinners";
import UserIcoW from "../assets/images/userIconWhite.svg";
import HeartIcoW from "../assets/images/heartIconWhite.svg";
// import EyeIcoW from "../assets/images/eyeIconWhite.svg";
import ChatIcoW from "../assets/images/chatIconWhite.svg";
import RepeatIcoW from "../assets/images/repeatIconWhite.svg";
import ClipboardIcoW from "../assets/images/clipboardIconWhite.svg";
import { useGetQuestByProject } from "../utils/quest.api";
import Header from "./Header";
import DeleteModal from "./modals/DeleteModal";

const DeleteTask = () => {
  const getTaskIco = (action: string) => {
    switch (action) {
      case "Like":
        return HeartIcoW;
      case "Follow":
        return UserIcoW;
      case "Tweet":
        return ClipboardIcoW;
      case "Quote":
        return ClipboardIcoW;
      case "Retweet":
        return RepeatIcoW;
      case "Reply":
        return ChatIcoW;
      default:
        return "";
    }
  };

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const toggleModal = () => setIsCreateOpen(!isCreateOpen);

  const projectId = process.env.REACT_APP_PROJECT_ID || "";

  const { data: tasksData, isLoading: tasksLoading } = useGetQuestByProject({
    projectId,
    limit: Number.MAX_SAFE_INTEGER,
    offset: 0,
  });

  return (
    <div className="bg-[#070707] min-h-screen">
      <DeleteModal
        isCreateOpen={isCreateOpen}
        toggleModal={toggleModal}
        questId={taskId}
      />
      <Header />
      {/* <!-- Quests Area --> */}
      <section className="dashboard-area py-[32px] font-[Barlow] font-[300] text-[14px] pt-[102px]">
        <div className="container">
          <h1 className="text-xl md:text-4xl text-[#F1F1F1] font-bold mb-3">
            Tasks
          </h1>
          {tasksLoading ? (
            <div className="flex justify-center mt-5">
              <FadeLoader
                color="#00C2FF"
                loading={tasksLoading}
                height={15}
                width={5}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-[16px] md:gap-[32px] my-[16px] md:my-[32px] uppercase">
              {tasksData?.questDocumentsReturn?.map(
                (task: any, idx: number) => (
                  <div
                    key={idx}
                    className="task-bg text-[10px] md:text-[16px] lg:text-[20px] flex w-full justify-between px-[12px] lg:px-[32px] py-[14px] md:py-[18px] gap-[20px]"
                  >
                    <div className="flex gap-[4px] md:gap-[12px] items-center">
                      <div className="bg-[#212121] p-[2px] md:p-[6px] rounded-[2px] md:rounded">
                        <img
                          className="min-w-[12px] w-[12px] md:w-[32px] md:min-w-[32px]"
                          src={getTaskIco(task?.action)}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="font-[600] lg:font-[700]">
                          {task?.title}
                        </p>
                        <p className="font-[600] lg:font-[700] capitalize text-gray-400">
                          {task?.type}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-[22px] lg:gap-[64px] items-center whitespace-nowrap">
                      <p>{task?.reward} POINTS</p>
                      <div
                        className="db-tag py-[6px] md:py-[13px] px-[10px] md:px-[32px] text-[#ABBBC2] text-[10px] md:text-[16px] md:tracking-[0.96px] cursor-pointer"
                        onClick={() => {
                          setTaskId(task?.id);
                          toggleModal();
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DeleteTask;
