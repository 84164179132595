import React from "react";
import cardBg from "../assets/images/dashboardCards/cards-bg.png";
import silverCardBg from "../assets/images/dashboardCards/silver-card-bg.png";
import goldCardBg from "../assets/images/dashboardCards/gold-card-bg.png";
import supedoBox from "../assets/images/dashboardCards/supedo-card.png";
import supedoText from "../assets/images/dashboardCards/supedo-text.png";
import bronzeBg from "../assets/images/dashboardCards/bronze-bg.png";
import silverBg from "../assets/images/dashboardCards/silver-bg.png";
import goldBg from "../assets/images/dashboardCards/gold-bg.png";
import copyBronze from "../assets/images/dashboardCards/copy-img-bronze.png";
import copySilver from "../assets/images/dashboardCards/copy-img-silver.png";
import copyGold from "../assets/images/dashboardCards/copy-img-gold.png";
import bronzeRec from "../assets/images/dashboardCards/bronze-rectangle.png";
import silverRec from "../assets/images/dashboardCards/silver-rectangle.png";
import goldRec from "../assets/images/dashboardCards/gold-rectangle.png";
import bronzeBtnBg from "../assets/images/dashboardCards/bronze-btn-bg.png";
import silverBtnBg from "../assets/images/dashboardCards/silver-btn-bg.png";
import goldBtnBg from "../assets/images/dashboardCards/gold-btn-bg.png";
import bronzeLine from "../assets/images/dashboardCards/bronze-line.png";
import silverLine from "../assets/images/dashboardCards/silver-line.png";
import goldLine from "../assets/images/dashboardCards/gold-line.png";
import { ClipLoader } from "react-spinners";

const PointCard = ({
  name,
  price,
  type,
  onClick,
  btnClick,
  isLoading,
  time,
}: any) => {
  let bgImg, cardBgImg, copyImg, recImg, btnImg, lineImg, text;
  // textC,
  // pColor,
  // btnTextColor;

  switch (type) {
    case "Silver":
      bgImg = silverBg;
      cardBgImg = silverCardBg;
      copyImg = copySilver;
      recImg = silverRec;
      btnImg = silverBtnBg;
      lineImg = silverLine;
      text = "3x";
      // textC = "#CCD6DD";
      // pColor = "#FFF2CF";
      // btnTextColor = "#312b2b";
      break;
    case "Gold":
      bgImg = goldBg;
      cardBgImg = goldCardBg;
      copyImg = copyGold;
      recImg = goldRec;
      btnImg = goldBtnBg;
      lineImg = goldLine;
      text = "4x";
      // textC = "#FEC83A";
      // pColor = "#FFF2CF";
      // btnTextColor = "#FFF";
      break;
    default:
      bgImg = bronzeBg;
      copyImg = copyBronze;
      cardBgImg = cardBg;
      recImg = bronzeRec;
      btnImg = bronzeBtnBg;
      lineImg = bronzeLine;
      text = "2x";
    // textC = "#FF8A3B";
    // pColor = "#FFDDC6";
    // btnTextColor = "#FFE6D5";
  }
  return (
    <div className="flex  flex-col gap-6 w-[fit-content]" onClick={onClick}>
      <div
        style={{
          backgroundImage: `url(${cardBgImg})`,
          backgroundSize: "100% 100%",
        }}
        className={` pt-[27px] pl-[15px] pb-[14px] pr-[14px] flex h-[350px] md:h-[460px] w-[230px] md:w-[302px] lg:w-[275px]`}
      >
        <div
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundSize: "100% 100%",
          }}
          className={`h-[312px] md:h-[417px] w-[203px] md:w-[272px]`}
        >
          <div className="flex justify-center items-center w-fit ">
            {/* <p className="absolute z-10 text-[#070707] right-1 text-[14px] md:text-[22px] font-extrabold font-[Inter]  w-full h-[150%] md:h-full text-center ">
              {time}
              {Number(time) < 10 ? "D" : "d"}
            </p> */}
            <img
              src={copyImg}
              alt="copy-icon"
              className="ml-[-8px] mt-[-10px] relative w-[40px] md:w-[60px]"
            />
          </div>
          <div className="relative top-[5%] text-center font-barlow mx-[4%]">
            <h2
              className={` text-[68px] md:text-[91px] font-extrabold`}
              style={{
                color:
                  type === "Gold"
                    ? "#FEC83A"
                    : type === "Bronze"
                    ? "#FF8A3B"
                    : "#CCD6DD",
              }}
            >
              {text}
            </h2>
            <p
              className={` text-[14px] md:text-[18px] font-light`}
              style={{
                color:
                  type === "Gold"
                    ? "#FFF2CF"
                    : type === "Bronze"
                    ? "#FFDDC6"
                    : "#CCD6DD",
              }}
            >
              The points will be boosted on every task for{" "}
              <span className=" font-bold">{time} days</span>{" "}
            </p>
          </div>
          <div
            className={`relative  text-[28px] md:text-[36px] font-black leading-[32px] font-barlow mx-[10%] top-[20%] italic`}
          >
            <h2
              className="p-0 m-0 max-w-[200px]"
              style={{
                color:
                  type === "Gold"
                    ? "#FEC83A"
                    : type === "Bronze"
                    ? "#FF8A3B"
                    : "#CCD6DD",
              }}
            >
              {name}
            </h2>
            {/* <h2 className="p-0 m-0 ">Booster</h2>  */}
            <img src={lineImg} alt="." className="w-[96px] md:w-[130px]" />
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${supedoBox})`,
              backgroundSize: "100% 100%",
            }}
            className={`w-[41px] md:w-[54px] lg:w-[50px] h-[109px] md:h-[146px] ml-[-46px] md:ml-[-62px] lg:ml-[-58px] relative mt-[200px] md:mt-[262px] flex flex-col justify-center items-center `}
          >
            <img src={supedoText} alt="text" className="h-[100px]" />
          </div>

          <div
            className={`   relative mt-[-64px] md:mt-[-84px] mr-[-4px] h-[60px] md:h-[81px]`}
          >
            <img src={recImg} alt="rectangle" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div
          style={{
            backgroundImage: `url(${btnImg})`,
            backgroundSize: "100% 100%",
          }}
          onClick={btnClick}
          className={`cursor-pointer w-[130px] md:w-[175px] h-[34px] md:h-[46px] flex justify-center items-center`}
        >
          <p
            className={`text-[#070707] text-[14px] md:text-[20px] font-extrabold`}
          >
            {isLoading ? (
              <ClipLoader size={20} color="#36d7b7" />
            ) : (
              `Buy ${price} SOL`
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PointCard;
