import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useCreateQuest = () =>
  useMutation<any, Error, any>(async (payload: any) => {
    const res = await client("quest/createQuest", {
      method: "POST", // Explicitly set the request method to "POST"
      data: payload,
    });
    return res;
  });

export const useGetQuestByProject = ({
  projectId,
  limit,
  offset,
}: {
  projectId: string;
  limit: number;
  offset: number;
}) =>
  useQuery<any, Error>(
    ["getQuestByProject", { projectId, limit, offset }],
    async () =>
      await client(
        `quest/getQuestByProject?projectId=${projectId}&limit=${limit}&offset=${offset}`
      )
  );

export const useGetUserTasks = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) =>
  useQuery<any, Error>(
    ["getTasksOfUser", { limit, offset }],
    async () =>
      await client(`quest/getTasksOfUser?limit=${limit}&offset=${offset}`)
  );
export const useCreateTask = () => {
  const queryClient: any = useQueryClient();

  return useMutation<any, Error, { questId: string; linkToYourTweet?: string }>(
    async (payload) => {
      const res = await client(`quest/createTask`, {
        method: "POST",
        data: payload,
      });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getQuestByProject");
      },
    }
  );
};

export const useFollowUser = () =>
  useMutation<any, Error, { targetUserHandle: string }>(
    async (targetUserHandle) => {
      const res = await client(
        `quest/followUser?targetUserHandle=${targetUserHandle}`,
        {
          method: "POST",
          data: { targetUserHandle: targetUserHandle },
        }
      );
      return res;
    }
  );

export const useRetweet = () =>
  useMutation<any, Error, { tweetId: string }>(async (tweetId) => {
    const res = await client(`quest/retweet?tweetId=${tweetId}`, {
      method: "POST",
    });
    return res;
  });

export const useLikeTweet = () =>
  useMutation<any, Error, { tweetId: string }>(async (tweetId) => {
    const res = await client(`quest/likeTweet?tweetId=${tweetId}`, {
      method: "POST",
    });
    return res;
  });

export const useDeleteQuest = () => {
  const queryClient: any = useQueryClient();

  return useMutation<any, Error, { questId: string }>(
    async (questId) => {
      const res = await client(`quest/deleteQuest/${questId}`, {
        method: "POST",
      });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getQuestByProject");
      },
    }
  );
};
