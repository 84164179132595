import React, { useEffect } from "react";
import HeaderBg from "../assets/images/dasboard-header-bg.png";
import DashboardHeaderBG from "../assets/images/dasboard-header-card-bg-new.png";

import CopyIco from "../assets/images/copyIcon.svg";
import DashboardUserIco from "../assets/images/dashboard-user.svg";
import TIco from "../assets/images/trapezium.svg";
import LeftTico from "../assets/images/left-trapezium.png";
// import WithdrawTag from "../assets/images/withdraw-tag-bg-new.png";

import { ClipLoader } from "react-spinners";
// import bs58 from "bs58";
import { toast } from "react-toastify";
// import { useWithdrawReward } from "../utils/wallet.api";

import copyImg from "../assets/images/contentCopy.svg";
import Header from "./Header";

function DashboardHeader({
  setIsCreateOpen,
  userData,
  data,
  // setWalletConnected,
  isLoading,
  walletData,
  isCreateOpen,
}: any) {
  const { publicKey, signMessage, connected, disconnect } = walletData;
  // const { data: user } = useGetLoggedInUser();

  // const { mutateAsync: verifySignature } = useVerifySignature();
  // const withdrawRewardMutation = useWithdrawReward();

  useEffect(() => {
    if (connected && isCreateOpen) {
      if (signMessage) {
        // const encodedMessage = new TextEncoder().encode(
        //   "Hello, World from Supido!"
        // );
        try {
          // signMessage(encodedMessage)
          //   .then((res: any) => {
          //     console.log(bs58.encode(res));
          //     verifySignature({
          //       signature: bs58.encode(res),
          //       address: publicKey?.toString() || "",
          //     })
          //       .then((res) => {
          //         console.log(res);
          //         toast.success("Signature verified!");
          //         setIsCreateOpen(false);
          //         setWalletConnected(true);
          //       })
          //       .catch((err: any) => {
          //         console.log(err);
          //         disconnect();
          //         toast.error(err?.message);
          //         setIsCreateOpen(false);
          //       });
          //   })
          //   .catch((err: any) => {
          //     console.log(err);
          //     disconnect();
          //     toast.error(err?.message);
          //   });
        } catch (err: any) {
          console.log(err);
          disconnect();
          toast.error(err?.message);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <header
      className="header-area pt-[16px] md:pt-[10px] z-50 relative md:pb-[0px]"
      style={{
        backgroundImage: `url(${HeaderBg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="pl-[8px] pr-[24px] max-w-[1338px] mx-auto">
        <Header />

        <div className="text-center mt-[62px] md:mt-[122px] font-[Barlow] ">
          <h4 className="text-[20px] sm:text-[32px] text-gray-50 font-bold sm:mb-2">
            {isLoading ? (
              <ClipLoader size={20} color="#36d7b7" />
            ) : (
              userData?.name
            )}
          </h4>
          <p className="text-[12px] sm:text-[14px] sm:text-base text-gray-400 ">
            <img
              src={CopyIco}
              className="inline"
              alt=""
              onClick={() => {
                navigator.clipboard.writeText(publicKey?.toString() || "");
                toast.success("Copied to clipboard!");
              }}
            />{" "}
            Wallet:{" "}
            <span className="text-gray-200 ">
              {isLoading ? (
                <ClipLoader size={20} color="#36d7b7" />
              ) : connected ? (
                publicKey?.toString()
              ) : (
                "Connect Wallet"
              )}
            </span>
          </p>
          <img
            src={userData?.profilePicUrl || DashboardUserIco}
            className="w-[48px] md:w-[125px] mx-auto rounded-full mt-[12px] md:mt-[16px] mb-[16px] md:mb-[24px]"
            alt=""
          />
          <div
            className="mx-auto w-[317px] max-w-[100%] pt-[16px] bg-no-repeat [clip-path:polygon(8.5%_0,91.5%_0,100%_50%,91.5%_100%,8.5%_100%,0_50%)] h-[89px] md:w-[604px] md:h-[152px] bg-[#00C2FF0D]"
            style={{
              backgroundImage: `url(${DashboardHeaderBG})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div className="md:h-[111px] h-[58px] md:w-[441px] w-[246px] max-w-[100%] mx-auto flex text-center text-[10px] md:text-[16px] font-[Barlow] items-center justify-between text-[#ABBBC2] font-[500]">
              <div className="w-[69px] md:w-[102px]">
                <p className="md:leading-[20px]">
                  Total{" "}
                  <span className="text-[#00C2FF] font-[700]">Supido</span>{" "}
                  Claimed
                </p>
                <h3 className="text-[#FFFFFFE5] text-[16px] md:text-[30px] font-[700] md:leading-[42px] mt-[8px]">
                  {isLoading ? (
                    <ClipLoader size={20} color="#36d7b7" />
                  ) : (
                    data?.rewardWithdrawn
                  )}
                </h3>
              </div>
              <div className="bg-[#093f5e] w-[1px] h-[54px]"></div>
              <div className="w-[58px] md:w-[102px]">
                <h3 className="text-[#FFFFFFE5] text-[28px] mt-[-10px] md:text-[54px] font-[700] md:leading-[76px]">
                  {isLoading ? (
                    <ClipLoader size={20} color="#36d7b7" />
                  ) : (
                    data?.rewardWithdrawable
                  )}
                </h3>
                <p className="md:leading-[20px] mt-[-4px]">
                  Withdrawable
                  <br />
                  <span className="text-[#00C2FF] font-[700]">Supido</span>
                </p>
              </div>
              <div className="bg-[#093f5e] w-[1px] h-[54px]"></div>
              <div className="w-[58px] md:w-[102px]">
                <p className="md:leading-[20px]">Credits Earned</p>
                <h3 className="text-[#FFFFFFE5] text-[16px] md:text-[30px] font-[700] md:leading-[42px] mt-[8px]">
                  {isLoading ? (
                    <ClipLoader size={20} color="#36d7b7" />
                  ) : (
                    data?.rewardClaimed
                  )}
                </h3>
              </div>
            </div>
            {/* <!-- <div
          className="flex text-center text-[10px] md:text-[16px] font-[Barlow] items-center justify-between text-[#ABBBC2] font-[500] mx-[35px] md:mx-[81px] mb-[25px]"
        >
          <div className="w-[69px] md:w-[102px]">
            <p className="md:leading-[20px]">
              Total
              <span className="text-[#00C2FF] font-[700]">$SUPIDO</span> Claimed
            </p>
            <h3
              className="text-[#FFFFFFE5] text-[16px] md:text-[30px] font-[700] md:leading-[42px] mt-[4px]"
            >
              1,790
            </h3>
          </div>
          <div className="bg-[#093f5e] w-[1px] h-[54px]"></div>
          <div className="w-[58px] md:w-[102px]">
            <h3
              className="text-[#FFFFFFE5] text-[28px] md:text-[54px] font-[700] md:leading-[76px]"
            >
              849
            </h3>
            <p className="md:leading-[20px] mt-[-8px]">
              Withdrawable
              <span className="text-[#00C2FF] font-[700]">$SUPIDO</span>
            </p>
          </div>
          <div className="bg-[#093f5e] w-[1px] h-[54px]"></div>
          <div className="w-[58px] md:w-[102px]">
            <p className="md:leading-[20px]">Credits Earned</p>
            <h3
              className="text-[#FFFFFFE5] text-[16px] md:text-[30px] font-[700] md:leading-[42px] mt-[4px]"
            >
              12,000
            </h3>
          </div>
        </div> --> */}
          </div>
          {/* <div
            style={{
              backgroundImage: `url(${WithdrawTag})`,
              backgroundSize: "100% 100%",
            }}
            className="flex justify-center items-center w-[92px] h-[22px] md:w-[182px] md:h-[43px] mx-auto mt-[-10px] md:mt-[-22px] relative z-[0]"
          >
            <button
              className="popup-with-zoom-anim"
              // href="#connect-wallet"
              disabled={withdrawRewardMutation.isLoading}
              id="connect-wallet-btn"
              onClick={async () => {
                if (wallets && wallets.length === 0) {
                  window.open("https://phantom.app/download", "_blank");
                  return;
                }

                if (connected) {
                  try {
                    await withdrawRewardMutation.mutateAsync();
                    toast.success("Rewards Withdrawn successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                } else {
                  setIsCreateOpen(true);
                }
              }}
            >
              <h2 className="text-[#FFFFFF] font-[700] text-[12px] md:text-[22px] font-[Barlow] pointer">
                {connected ? (
                  // ? publicKey?.toString().slice(0, 3) +
                  //   "..." +
                  //   publicKey?.toString().slice(-3)
                  withdrawRewardMutation?.isLoading ? (
                    <ClipLoader size={20} color="#36d7b7" />
                  ) : (
                    "Withdraw"
                  )
                ) : (
                  "Connect Wallet"
                )}
              </h2>
            </button>
          </div> */}
        </div>
        <div className="sm:col-span-5 lg:col-span-4 hidden xl:flex justify-center lg:justify-end lg:mt-[-60px] ">
          <div
            className="inline-flex items-center justify-center w-full gap-2 px-4 py-2  transition cursor-pointer pointer sm:w-fit  border border-[#036e8f] bg-[#061a20] hover:border-[#00C2FF] rounded-full text-sm text-[#F1F1F1] "
            onClick={() => {
              navigator?.clipboard
                ?.writeText(
                  `${window?.location?.origin}?invitation=${
                    userData?.myReferralCode || ""
                  }`
                )
                .then(() => {
                  toast.success("Invitation link copied!");
                });
            }}
          >
            <img src={copyImg} width={"19px"} alt="message" /> Invite Friends
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-[13px]">
        <img
          src={LeftTico}
          className="w-[30%] h-[14px] md:h-auto sm:w-[32%] md:w-[30vw] lg:w-[30vw] xl:w-[420px] 2xl:w-[36vw]"
          alt="left"
        />
        <img
          src={TIco}
          className="w-[30%] h-[14px] md:h-auto sm:w-[32%] md:w-[30vw] lg:w-[30vw] xl:w-[420px] 2xl:w-[36vw]"
          alt="T icon"
        />
      </div>
    </header>
  );
}

export default DashboardHeader;
